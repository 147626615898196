// appeals-to-caesar queries

import {
  CaseConflictType,
  CaseType,
  DefaultV3Error,
  DefaultV3Response,
  ItemTagConnectionType,
  StaffType,
  TaskType,
  TeamType,
} from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { usePermissions } from '../../static/permissions';
import { queryKeys } from '../../static/queryKeys';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import {
  createCaseAttorneyFeeV3,
  createCaseStatusUpdateV3,
  createCaseStatusV3,
  createCaseTypeV3,
  createCaseV3,
  createDefaultAttorneyFeeV3,
  createPresetFilterV3,
  deleteCaseAttorneyFeeV3,
  deleteCaseStatusV3,
  deleteCaseTypeV3,
  deleteCaseV3,
  deleteDefaultAttorneyFeeV3,
  deletePresetFilterV3,
  getAllCaseStatusUpdatesV3,
  getCaseAttorneyFeesV3,
  getCaseStatusesV3,
  getCaseTypesV3,
  getCaseV3,
  getCasesV3,
  getDefaultAttorneyFeesV3,
  getPresetFiltersV3,
  getSelectedCasesV3,
  postLinkCasesV3,
  updateCaseAttorneyFeeV3,
  updateCaseStatusV3,
  updateCaseTypeV3,
  updateCaseV3,
  updateDefaultAttorneyFeeV3,
  updatePresetFilterV3,
} from '../requests/appeals-to-caesar';
import { getCaseAccessesV3, getCurrentUserV3 } from '../requests/hurin';
import {
  deleteCaseFromLinkV3,
  getCaseLinksV3,
  putTransferExistingCaseLinkV3,
} from '../requests/little-caesars';

export interface CaseCompleteType extends CaseType {
  umUimCoverage: string;
  thirdPartyCoverage: string;
  medicalTreatmentAmountBilled: number;
  tags: ItemTagConnectionType[];
  tasks: TaskType[];
  teams: TeamType[];
  assignees: StaffType[];
  expenses: number | null;
  conflicts: CaseConflictType[];
  mriCount: number | null;
  surgeryCount: number | null;
  injectionCount: number | null;
  referralSource?: string;
}

// ------------------ Case ------------------
// #region
export const useGetCase = (caseId?: Parameters<typeof getCaseV3>[0]) => {
  return useQuery<DefaultV3Response<CaseType>, AxiosError<DefaultV3Error>>({
    // This types the success and error functions for axios
    queryKey: [queryKeys.cases, caseId],
    queryFn: async () => getCaseV3(caseId ?? ''),
    enabled: !!caseId,
  });
};

export const useGetSelectedCases = (cases: Parameters<typeof getSelectedCasesV3>[0]) => {
  return useQuery({
    queryKey: [queryKeys.selectedCases, cases],
    queryFn: async () => {
      // this is a post request but we want to memoize results based on cases list so using in a useQuery
      return getSelectedCasesV3(cases);
    },
    enabled: cases?.length > 0,
  });
};

export const useGetCases = () => {
  const { isCocounsel } = usePermissions();
  return useQuery({
    queryKey: [queryKeys.cases, isCocounsel],
    queryFn: async () => {
      const currentUser = await getCurrentUserV3();
      if (isCocounsel) {
        const caseAccesses = await getCaseAccessesV3(currentUser?.data?.username ?? '').then(
          (res) => res.data.map((access) => access.caseId),
        );
        // if current user is cocounsel, only get cases assigned to them
        // NOT SURE if this is best spot to do this?
        return getSelectedCasesV3(caseAccesses);
      } else {
        return getCasesV3();
      }
    },
  });
};

export const useGetLinkedCases = (linkId?: string) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [queryKeys.linkedCases, linkId],
    queryFn: async () => getCaseLinksV3(linkId ?? ''),
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.status === 404) {
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: [queryKeys.linkedCases],
          });
          queryClient.invalidateQueries({
            queryKey: [queryKeys.cases],
          });
        }, 1000);
      } else {
        enqueueAPISnackbar({
          message: error.response?.data?.meta?.userMsg,
          key: 'get-case-error', // use this key for all case related get errors so user doesnt get spammed if service is down
          variant: 'error',
        });
      }
    },
    enabled: !!linkId,
  });
};

export const useCreateCase = () => {
  // TODO: Cut over to this route
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createCaseV3>[0]) => createCaseV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.cases],
      });
    },
  });
};

export const useUpdateCaseData = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateCaseV3>[0]) => updateCaseV3(data),
    onMutate: (data) => {
      // Update the cache optimistically
      queryClient.setQueryData(
        [queryKeys.cases, data.caseId],
        (oldData: DefaultV3Response<CaseCompleteType> | undefined) => {
          if (!oldData) return;

          return {
            meta: oldData.meta,
            data: { ...oldData.data, ...data },
          };
        },
      );

      // Return the previous data and the tempId for rollback
      return {
        previousData: queryClient.getQueryData([queryKeys.cases, data.caseId]),
      };
    },
    onSuccess: (result, variables) => {
      // Invalidate the cache so it will refetch the updated data
      queryClient.invalidateQueries([queryKeys.cases, variables.caseId]);
      queryClient.invalidateQueries([queryKeys.cases]);
    },
    onError: (error, variables, context) => {
      // Rollback the cache to the previous data if there's an error
      if (context?.previousData) {
        queryClient.setQueryData([queryKeys.cases, variables.caseId], context.previousData);
      }
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.cases, variables.caseId],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.cases],
      });
    },
  });
};

export const useDeleteCase = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (caseId: Parameters<typeof deleteCaseV3>[0]) => deleteCaseV3(caseId),
    onSuccess: (result, caseId) => {
      // Invalidate the cache so it will refetch the updated data
      queryClient.invalidateQueries([queryKeys.cases, caseId]);
    },
    onSettled: (data, error, caseId) => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.cases] });
    },
  });
};

export const useLinkCases = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof postLinkCasesV3>[0]) => postLinkCasesV3(data),
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'post-link-cases-error',
        variant: 'error',
      });
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.cases, variables.caseIdOne],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.cases, variables.caseIdTwo],
      });
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.linkedCases],
        });
      }, 2500); // give the update linked cases backend event time to run
    },
  });
};

export const useUnlinkCases = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ linkId, caseId }: Parameters<typeof deleteCaseFromLinkV3>[0]) =>
      deleteCaseFromLinkV3({ linkId, caseId }),
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'delete-link-cases-error',
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.cases], // TODO: Only invalidate the case that was unlinked
      });
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.linkedCases],
        });
      }, 2500); // give the update linked cases backend event time to run
    },
  });
};

export const useTransferCaseLinks = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof putTransferExistingCaseLinkV3>[0]) =>
      putTransferExistingCaseLinkV3(data),
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'transfer-link-cases-error',
        variant: 'error',
      });
    },
    onSuccess: (result) => {
      enqueueAPISnackbar({
        message: result?.meta?.userMsg,
        key: 'transfer-link-cases-success',
        variant: 'success',
      });
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.cases],
        });
        queryClient.invalidateQueries({
          queryKey: [queryKeys.linkedCases],
        });
      }, 2500); // give the update linked cases backend event time to run
    },
  });
};
// #endregion
// ------------------ Preset Filters ------------------
// #region
export const useGetPresetFilters = () => {
  return useQuery({
    queryKey: [queryKeys.presetFilters],
    queryFn: async () => getPresetFiltersV3(),
  });
};

export const useDeletePresetFilter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (presetFilterId: string) => deletePresetFilterV3(presetFilterId),
    onSuccess: (result) => {
      if (result?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: result?.meta?.userMsg,
          variant: 'success',
        });
      }
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      } else {
        enqueueAPISnackbar({
          message: 'Error deleting preset filter. Please try again later.',
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.presetFilters],
      });
    },
  });
};

export const useCreatePresetFilter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createPresetFilterV3>[0]) => createPresetFilterV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.presetFilters],
      });
    },
    onSuccess: (result) => {
      if (result?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: result?.meta?.userMsg,
          variant: 'success',
        });
      }
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      }
    },
  });
};

export const useUpdatePresetFilter = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updatePresetFilterV3>[0]) => updatePresetFilterV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.presetFilters],
      });
    },
    onSuccess: (result) => {
      if (result?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: result?.meta?.userMsg,
          variant: 'success',
        });
      }
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      } else {
        enqueueAPISnackbar({
          message: 'Error updating preset filter. Please try again later.',
          variant: 'error',
        });
      }
    },
  });
};

// #endregion
// ------------------ Case Status Update ------------------
// #region
/**
 * @description Get all case status updates for all cases available to the firm
 */
export const useGetCaseStatusUpdates = () => {
  return useQuery({
    queryKey: [queryKeys.caseStatusUpdates],
    queryFn: async () => getAllCaseStatusUpdatesV3(),
  });
};

export const useCreateCaseStatusUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createCaseStatusUpdateV3>[0]) =>
      createCaseStatusUpdateV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.cases],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseStatusUpdates],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
  });
};
// #endregion
// ------------------ Case Status ------------------
// #region
export const useCreateCaseStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createCaseStatusV3>[0]) => createCaseStatusV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseStatuses],
      });
    },
  });
};

const categories = {
  open: 1,
  refer: 2,
  close: 3,
  decline: 4,
  lead: 5,
};

/**
 *
 * @description Get all Case Statuses available to the case
 */
export const useGetCaseStatuses = (
  showEmptyStatuses?: boolean,
  ignoreCategorySorting?: boolean,
) => {
  return useQuery({
    queryKey: [queryKeys.caseStatuses, showEmptyStatuses, ignoreCategorySorting],
    queryFn: async () =>
      getCaseStatusesV3().then((res) => {
        const returnData = ignoreCategorySorting
          ? res
          : {
              ...res,
              data: res.data.sort((a, b) => {
                return categories[a.category || 'open'] - categories[b.category || 'open'];
              }),
            };
        if (showEmptyStatuses) return returnData;
        else {
          return {
            ...returnData,
            data: returnData.data.filter((status) => status.title),
          };
        }
      }),
  });
};

export const useUpdateCaseStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateCaseStatusV3>[0]) => updateCaseStatusV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseStatuses],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.cases],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
  });
};

export const useDeleteCaseStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (caseStatusId: string) => deleteCaseStatusV3(caseStatusId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseStatuses],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.cases],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
  });
};
// #endregion
// ------------------ Case Type ------------------
// #region
export const useCreateCaseType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createCaseTypeV3>[0]) => createCaseTypeV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseTypes],
      });
    },
  });
};

export const useGetCaseTypes = (showEmptyTypes?: boolean) => {
  return useQuery({
    queryKey: [queryKeys.caseTypes, showEmptyTypes],
    queryFn: async () =>
      getCaseTypesV3().then((res) => {
        if (showEmptyTypes) return res;
        else {
          return {
            ...res,
            data: res.data.filter((type) => type.title),
          };
        }
      }),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
};

export const useDeleteCaseType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (caseTypeId: string) => deleteCaseTypeV3(caseTypeId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseTypes],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.cases],
      });
    },
  });
};

export const useUpdateCaseType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateCaseTypeV3>[0]) => updateCaseTypeV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseTypes],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.cases],
      });
    },
  });
};
// #endregion
// ------------------ Default Attorney Fees ------------------
// #region
export const useGetDefaultAttorneyFees = () => {
  return useQuery({
    queryKey: [queryKeys.defaultAttorneyFees],
    queryFn: async () => getDefaultAttorneyFeesV3(),
  });
};

export const useCreateDefaultAttorneyFee = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => createDefaultAttorneyFeeV3(),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.defaultAttorneyFees],
      });
    },
  });
};

export const useUpdateDefaultAttorneyFee = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateDefaultAttorneyFeeV3>[0]) =>
      updateDefaultAttorneyFeeV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.defaultAttorneyFees],
      });
    },
  });
};

export const useDeleteDefaultAttorneyFee = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (defaultAttorneyFeeId: string) => deleteDefaultAttorneyFeeV3(defaultAttorneyFeeId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.defaultAttorneyFees],
      });
    },
  });
};

export const useGetAttorneyFeesForCase = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.attorneyFees, caseId],
    queryFn: async () => getCaseAttorneyFeesV3(caseId),
    enabled: !!caseId,
  });
};

export const useUpdateAttorneyFeeForCase = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateCaseAttorneyFeeV3>[0]) =>
      updateCaseAttorneyFeeV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.attorneyFees],
      });
    },
  });
};

export const useCreateAttorneyFeeForCase = (caseId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => createCaseAttorneyFeeV3(caseId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.attorneyFees],
      });
    },
  });
};

export const useDeleteAttorneyFeeForCase = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (attorneyFeeId: string) => deleteCaseAttorneyFeeV3(attorneyFeeId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.attorneyFees],
      });
    },
  });
};
// #endregion
