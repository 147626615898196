import {
  CaseCompleteType,
  cn,
  commaSafeSplit,
  displayContactName,
  formatAddress,
} from '@colosseum/data';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
  type DragEndEvent,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import {
  CaseConflictType,
  CaseContactConnectionRoleType,
  ContactAddressType,
  ContactEmailType,
  ContactNumberType,
  ContactType,
  ItemTagConnectionType,
  MedicalTreatmentBill,
  StaffType,
  TaskType,
  TeamType,
} from '@gladiate/types';
import { ArrowDownTrayIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { PopoverContent } from '@radix-ui/react-popover';
import { Updater } from '@tanstack/react-query';
import {
  Cell,
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  FilterFn,
  GroupingState,
  PaginationState,
  Row,
  SortingFn,
  SortingState,
  TableMeta,
  Table as TableType,
  VisibilityState,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { PlusCircle } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Data } from 'react-csv/lib/core';
import { useSearchParams } from 'react-router-dom';
import CardView from '../../CardView/CardView';
import GladiateLoader from '../../GladiateLoader/GladiateLoader';
import TimelineView from '../../TimelineView/TimelineView';
import { Button } from '../Button/Button';
import { ContextMenu, ContextMenuTrigger } from '../ContextMenu/ContextMenu';
import { DefaultCalendar } from '../DefaultCalendar/DefaultCalendar';
import { Popover, PopoverTrigger } from '../Popover/Popover';
import {
  DraggableTableCell,
  DraggableTableHead,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../Table/Table';
import { DataTablePagination } from './DataTablePagination';
import { DataTableToolbar } from './DataTableToolbar';
import { getRangeValue, onRangeSelect } from './table-filter-helpers';
import {
  formatDatesForFilterButton,
  getInitialOrderForTable,
  getInitialPageSizeForTable,
  getInitialSortForTable,
  getInitialVisibilityForTable,
} from './table-render-helpers';
// A TanStack fork of Kent C. Dodds' match-sorter library that provides ranking information
import { rankItem } from '@tanstack/match-sorter-utils';
import dayjs from 'dayjs';
import { difference, isNil, startCase } from 'lodash';

declare module '@tanstack/table-core' {
  interface SortingFns {
    dayjsDateTime: SortingFn<unknown>;
  }
}

export const dayjsDateTime = (rowA: any, rowB: any, columnId: any): number => {
  if (isNil(rowA?.getValue(columnId))) {
    return 1;
  }
  if (isNil(rowB?.getValue(columnId))) {
    return -1;
  }
  return dayjs(rowA?.getValue(columnId)).diff(dayjs(rowB?.getValue(columnId)));
};

interface SharedProps<TData, TValue> {
  autoResetExpanded?: boolean;
  activeCase?: TData;
  setActiveCaseId?: (arg0: string) => void;
  columns: ColumnDef<TData, TValue>[];
  customDateRangeId?: string;
  customRightButton?: React.ReactElement;
  disableReorder?: boolean;
  data: TData[];
  fetchingMore?: boolean;
  filters?: {
    id: string;
    options: { value: string; label: string; icon?: any }[];
  }[];
  filtersInParams?: boolean;
  grouping?: GroupingState;
  handleResetFilters?: () => void;
  hideFooter?: boolean;
  hideToolbar?: boolean;
  hideViewButton?: boolean;
  initialGroupingsExpanded?: boolean;
  initialSort?: { id: string; desc: boolean };
  initialVisibility?: VisibilityState;
  initialOrder?: ColumnOrderState;
  isLoading?: boolean;
  isError?: boolean;
  persistentVisibility?: VisibilityState;
  renderContextMenuContent?: (row: TData) => React.ReactElement;
  showSearchBar?: boolean;
  showCSVDownload?: boolean;
  tableName: string;
  viewType?: 'timeline' | 'card' | 'table' | 'review';
  defaultPageSize?: number;
  meta?: TableMeta<TData>;
  noResultsMessage?: string;
  ExpandedGroupButton?: React.FC<{ groupId: string }>;
}

type ConditionalProps =
  | {
      isViewOnly: true;
      handleRowClick?: never;
    }
  | {
      handleRowClick: (arg0: Row<any>) => void;
      isViewOnly?: false;
    };

export type DataTableProps<TData, TValue> = SharedProps<TData, TValue> & ConditionalProps;

/**
 * This function takes a URLSearchParams object and converts it to a tanstack-table filters object
 */
const convertParamsToFilters = (params: URLSearchParams): ColumnFiltersState => {
  return Object.keys(Object.fromEntries([...params])).map((key) => {
    const values = commaSafeSplit(params?.get(key));
    const cleanedValues = values?.map((value) => (value === '' ? undefined : value)); // replace array values that are empty strings with undefined
    return {
      id: key,
      value: cleanedValues,
    };
  });
};

/**
 * This function takes tanstack-table filters and converts them to a URLSearchParams object
 */
const convertFiltersToParams = (filters: ColumnFiltersState) => {
  if (!filters) {
    return [];
  }

  return Object.fromEntries(
    filters.map((filter) => {
      const filterVal = filter?.value as string[];

      if (typeof filterVal === 'string') {
        return [filter.id, filterVal];
      }

      if (!filterVal || filterVal?.length === 0) {
        return [filter.id, ''];
      } else {
        return [filter.id, filterVal?.join(',')];
      }
    }),
  );
};

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item

  let itemRank;
  const cellValue = row.getValue(columnId);
  switch (columnId) {
    case 'Assignees':
      itemRank = rankItem(
        (cellValue as StaffType[]).map((assignee) => assignee.displayName).join(', ') ?? [],
        value,
      );
      break;
    case 'Phone Number':
    case 'Number':
      if (typeof cellValue === 'string') {
        itemRank = rankItem(cellValue, value);
      } else {
        const numbers = (cellValue as ContactNumberType[])

          ?.map((number) => {
            return number.number?.replace('+', '');
          })
          .filter((number) => number);
        itemRank = rankItem(numbers ?? '', value);
      }
      break;
    case 'Email':
      if (typeof cellValue === 'string') {
        itemRank = rankItem(cellValue, value);
      } else {
        const emails = (cellValue as ContactEmailType[])?.map((email) => email.emailAddress);
        itemRank = rankItem(emails ?? '', value);
      }
      break;
    case 'Address':
      itemRank = rankItem(
        (cellValue as ContactAddressType[]).map((address) => formatAddress(address)) ?? '',
        value,
      );
      break;
    case 'Roles':
      itemRank = rankItem(
        (cellValue as CaseContactConnectionRoleType[])?.map((role) => startCase(role.roleOnCase)) ??
          '',
        value,
      );
      break;
    case 'Teams':
      itemRank = rankItem((cellValue as TeamType[]).map((team) => team.title) ?? '', value);
      break;
    case 'Tags':
      itemRank = rankItem(
        (cellValue as ItemTagConnectionType[]).map((tag) => startCase(tag?.tagAttributes?.title)) ??
          '',
        value,
      );
      break;
    case 'Serving Discovery':
      itemRank = rankItem(
        (cellValue as ContactType[]).map((contact) => displayContactName(contact)) ?? '',
        value,
      );
      break;
    case 'Types of Discovery':
      itemRank = rankItem((cellValue as string[])?.map((type) => startCase(type)) ?? '', value);
      break;
    case 'Medical Treatment Type':
      itemRank = rankItem((cellValue as string) ?? '', value);
      break;
    default:
      itemRank = rankItem(cellValue ?? '', value);
      break;
  }

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

/**
 * @component
 * @param meta Tanstack's recommended way of passing arbitrary data into the table and can be accessed via `props.table.options.meta` in the column objects
 * @param persistentVisibility - This prop should be used to make sure any column with enableHiding on always has that column visible
 */
export function DataTable<TData, TValue>({
  activeCase,
  autoResetExpanded,
  columns,
  customDateRangeId,
  customRightButton,
  data,
  defaultPageSize = 10,
  disableReorder,
  fetchingMore,
  filters,
  filtersInParams,
  grouping,
  handleResetFilters,
  handleRowClick,
  hideFooter,
  hideToolbar,
  hideViewButton,
  initialGroupingsExpanded,
  initialSort,
  initialVisibility,
  isError,
  isLoading,
  isViewOnly,
  meta,
  noResultsMessage = 'No results.',
  persistentVisibility,
  renderContextMenuContent,
  setActiveCaseId,
  showCSVDownload,
  showSearchBar,
  tableName,
  viewType,
  ExpandedGroupButton,
}: DataTableProps<TData, TValue> & ConditionalProps) {
  const [rowSelection, setRowSelection] = useState({});
  const initialPageSizeFromLocalStorage = getInitialPageSizeForTable(tableName);
  const initialOrderFromLocalStorage = getInitialOrderForTable(tableName);
  const initialSortFromLocalStorage = getInitialSortForTable(tableName);
  const initialVisibilityFromLocalStorage = getInitialVisibilityForTable(
    tableName,
    initialVisibility,
  );

  /**
   * persistent visibility should be used to make sure any column with enableHiding
   * on always has that column visible
   * */
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    initialVisibilityFromLocalStorage
      ? { ...initialVisibilityFromLocalStorage, ...persistentVisibility }
      : {},
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: initialPageSizeFromLocalStorage || defaultPageSize,
  });

  const tableCols = (columns as { id: string }[]).map((column) => column.id);
  const [columnOrder, setColumnOrder] = useState<string[]>(
    initialOrderFromLocalStorage
      ? [
          ...initialOrderFromLocalStorage,
          ...difference(tableCols, initialOrderFromLocalStorage),
          // This difference makes sure that if we add new table cols,
          // we grab them and put them into the order - if we don't do this, new
          // cols aren't re-orderable
        ]
      : () => tableCols,
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [sorting, setSorting] = useState<SortingState>(
    initialSortFromLocalStorage.length > 0
      ? initialSortFromLocalStorage
      : initialSort
      ? [initialSort]
      : [],
  );

  const handleFiltersChange = (updater: Updater<ColumnFiltersState, ColumnFiltersState>) => {
    if (filtersInParams) {
      const filtersAfterUpdate = functionalUpdate(updater, columnFilters); // get filters after update
      const params = convertFiltersToParams(filtersAfterUpdate);
      setSearchParams(params, { replace: true });
      setColumnFilters(filtersAfterUpdate); // For some reason, not doing this freezes the page on searching
    } else {
      setColumnFilters(updater);
    }
  };

  const handleColumnVisibility = (updater: Updater<VisibilityState, VisibilityState>) => {
    const columnsAfterUpdate = functionalUpdate(updater, columnVisibility);
    if (tableName) {
      window.localStorage.setItem(
        `${tableName}ColumnVisibility`,
        JSON.stringify(columnsAfterUpdate),
      );
    }
    setColumnVisibility(updater);
  };

  const handleColumnOrder = (updater: Updater<ColumnOrderState, ColumnOrderState>) => {
    const columnsAfterUpdate = functionalUpdate(updater, columnOrder);
    if (tableName) {
      window.localStorage.setItem(`${tableName}ColumnOrder`, JSON.stringify(columnsAfterUpdate));
    }
    setColumnOrder(updater);
  };

  const handleColumnSort = (updater: Updater<SortingState, SortingState>) => {
    const columnsAfterUpdate = functionalUpdate(updater, sorting);
    if (tableName) {
      window.localStorage.setItem(`${tableName}ColumnSort`, JSON.stringify(columnsAfterUpdate));
    }
    setSorting(updater);
  };

  const handlePaginationChange = (updater: Updater<PaginationState, PaginationState>) => {
    const columnsAfterUpdate = functionalUpdate(updater, pagination);
    if (tableName) {
      window.localStorage.setItem(
        `${tableName}PageSize`,
        JSON.stringify(columnsAfterUpdate.pageSize),
      );
    }
    setPagination(updater);
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      grouping: grouping ?? [],
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination,
      columnOrder,
    },
    meta,
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    sortingFns: {
      dayjsDateTime: dayjsDateTime,
    },
    enableRowSelection: true,
    autoResetExpanded: autoResetExpanded,
    autoResetPageIndex: viewType !== 'review',
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: handleColumnOrder,
    onSortingChange: handleColumnSort,
    onColumnFiltersChange: handleFiltersChange,
    onColumnVisibilityChange: handleColumnVisibility,
    getColumnCanGlobalFilter: () => true, // This is crude, but null values in first row breaks global filtering https://github.com/TanStack/table/issues/4919
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getGroupedRowModel: grouping ? getGroupedRowModel() : undefined,
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onPaginationChange: handlePaginationChange,
    globalFilterFn: 'fuzzy' as any,
  });

  const cases = table.getRowModel().rows.map((row) => row.original) as CaseCompleteType[];

  // This can be moved out and passed in as a prop in future if it gets too messy
  const prepCellValueForCSV = (cell: Cell<TData, unknown>) => {
    let cellValue = cell.getValue();
    switch (cell.column.id) {
      case 'Assignees': {
        const assignees = cellValue as StaffType[];
        cellValue = assignees.map((assignee) => assignee.username).join(', ');
        break;
      }
      case 'Teams': {
        const teams = cellValue as TeamType[];
        cellValue = teams.map((team) => team.title).join(', ');
        break;
      }
      case 'Tags': {
        const tags = cellValue as ItemTagConnectionType[];
        cellValue = tags.map((tag) => tag?.tagAttributes?.title).join(', ');
        break;
      }
      case 'Uncompleted Tasks': {
        const tasks = cellValue as TaskType[];
        cellValue = tasks.map((task) => task.title).join(', ');
        break;
      }
      case 'Conflicts': {
        const conflicts = cellValue as CaseConflictType[];
        cellValue = conflicts.length;
        break;
      }
      case 'Treatments': {
        const treatments = cellValue as MedicalTreatmentBill[];
        cellValue = treatments.length;
        break;
      }
    }
    return cellValue;
  };

  const prepareCSVData = (table: TableType<TData>) => {
    return table.getPrePaginationRowModel().rows.map((row) => {
      // get all data after filtering and sorting
      return row.getVisibleCells().reduce((rowValue, cell) => {
        return { ...rowValue, [cell.column.id]: prepCellValueForCSV(cell) };
      }, []) as TData;
    });
  };

  // reorder columns after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      handleColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = columnOrder.indexOf(over.id as string);
        return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
      });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  useEffect(() => {
    const filters = convertParamsToFilters(searchParams);
    setColumnFilters(filters);
  }, [searchParams]);

  useEffect(() => {
    table.toggleAllRowsExpanded(initialGroupingsExpanded);
  }, [table, initialGroupingsExpanded]);

  return (
    // NOTE: This provider creates div elements, so don't nest inside of <table> elements
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div className="space-y-4">
        {!hideToolbar && (
          <DataTableToolbar
            filters={filters}
            showSearchBar={showSearchBar}
            table={table}
            handleResetFilters={handleResetFilters}
            customRightButton={customRightButton}
            customButtons={
              customDateRangeId ? (
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline" size="sm" className="h-8 border-dashed">
                      <PlusCircle className="w-4 h-4 mr-2" />
                      {customDateRangeId}
                      {formatDatesForFilterButton(table, customDateRangeId)}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="z-50 w-full p-4 bg-white" align="center">
                    <div className="flex">
                      <DefaultCalendar
                        mode="single"
                        selected={getRangeValue(0, customDateRangeId, table)}
                        onSelect={(date) => onRangeSelect(0, customDateRangeId, table, date)}
                        initialFocus
                      />
                      <DefaultCalendar
                        mode="single"
                        selected={getRangeValue(1, customDateRangeId, table)}
                        onSelect={(date) => onRangeSelect(1, customDateRangeId, table, date)}
                        initialFocus
                      />
                    </div>
                  </PopoverContent>
                </Popover>
              ) : undefined
            }
            csvButton={
              showCSVDownload ? (
                table.getPrePaginationRowModel().rows.length > 0 ? (
                  <CSVLink
                    data={prepareCSVData(table) as Data}
                    filename={`${tableName || 'data'}.csv`}
                    className="relative flex items-center h-8 px-2 mx-2 text-sm border border-gray-200 rounded-md hover:bg-gray-100"
                  >
                    <ArrowDownTrayIcon className="w-6 h-6 pr-2" />
                    Download CSV
                  </CSVLink>
                ) : (
                  <Button
                    disabled
                    className="relative flex items-center h-8 px-2 mx-2 text-sm border border-gray-200 rounded-md hover:bg-gray-100"
                  >
                    <ArrowDownTrayIcon className="w-6 h-6 pr-2" /> Download CSV
                  </Button>
                )
              ) : undefined
            }
            hideViewButton={hideViewButton}
          />
        )}
        {(!viewType || ['table', 'review'].includes(viewType)) && (
          <div className="border rounded-md">
            <Table>
              {isError ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-[500px] text-center content-center"
                    >
                      Something went wrong. Please try again later.
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell className="h-[500px] text-center content-center">
                      <GladiateLoader />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return header.column.getIsGrouped() || disableReorder ? (
                            <TableHead key={header.id}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())}
                            </TableHead>
                          ) : (
                            <SortableContext
                              key={header.id}
                              items={columnOrder}
                              strategy={horizontalListSortingStrategy}
                            >
                              <DraggableTableHead key={header.id} header={header} />
                            </SortableContext>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableHeader>
                  <TableBody className="size-full">
                    {table.getRowModel().rows?.length ? (
                      table.getRowModel().rows.map((row) => (
                        <ContextMenu key={row.id}>
                          <ContextMenuTrigger asChild disabled={!renderContextMenuContent}>
                            <TableRow
                              dontStripeRow={!!grouping}
                              className={cn(
                                !isViewOnly && !row.getIsGrouped() && 'cursor-pointer',

                                (row.original as CaseCompleteType)?.caseId ===
                                  (activeCase as CaseCompleteType)?.caseId &&
                                  viewType === 'review' &&
                                  'bg-light-blue even:bg-light-blue hover:bg-light-blue',
                              )}
                              data-state={row.getIsSelected() && 'selected'}
                              onClick={() => {
                                if (viewType === 'review' && setActiveCaseId) {
                                  setActiveCaseId((row.original as CaseCompleteType)?.caseId);
                                } else if (!isViewOnly) {
                                  handleRowClick(row);
                                }
                              }}
                            >
                              {row.getVisibleCells().map((cell) =>
                                cell.column.getIsGrouped() ||
                                cell.getIsAggregated() ||
                                disableReorder ? (
                                  <SortableContext
                                    key={cell.id}
                                    items={columnOrder}
                                    strategy={horizontalListSortingStrategy}
                                  >
                                    <TableCell
                                      key={cell.id}
                                      className={cn(
                                        cell.getIsGrouped() && 'bg-gray-100',
                                        cell.getIsAggregated() && 'bg-gray-100',
                                      )}
                                    >
                                      {cell.getIsGrouped() ? (
                                        // If it's a grouped cell, add an expander and row count
                                        <div className="flex w-full items-center gap-x-2">
                                          <Button
                                            size="unset"
                                            className={cn(
                                              'flex items-center gap-x-1 whitespace-nowrap hover:border-gray-200 border border-transparent pr-1 transition-all',
                                              row.getCanExpand()
                                                ? 'cursor-pointer'
                                                : 'cursor-default',
                                            )}
                                            {...{
                                              onClick: row.getToggleExpandedHandler(),
                                            }}
                                          >
                                            {row.getIsExpanded() ? (
                                              <ChevronDownIcon className="w-4 h-4" />
                                            ) : (
                                              <ChevronRightIcon className="w-4 h-4" />
                                            )}{' '}
                                            {flexRender(
                                              cell.column.columnDef.cell,
                                              cell.getContext(),
                                            )}{' '}
                                            ({row.subRows.length})
                                          </Button>

                                          {ExpandedGroupButton && row.getIsExpanded() && (
                                            <ExpandedGroupButton
                                              groupId={row.groupingValue as string}
                                            />
                                          )}
                                        </div>
                                      ) : cell.getIsAggregated() ? (
                                        // If the cell is aggregated, use the Aggregated
                                        // renderer for cell
                                        flexRender(
                                          cell.column.columnDef.aggregatedCell ??
                                            cell.column.columnDef.cell,
                                          cell.getContext(),
                                        )
                                      ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                        // Otherwise, just render the regular cell
                                        flexRender(cell.column.columnDef.cell, cell.getContext())
                                      )}
                                    </TableCell>
                                  </SortableContext>
                                ) : (
                                  <SortableContext
                                    key={cell.id}
                                    items={columnOrder}
                                    strategy={horizontalListSortingStrategy}
                                  >
                                    <DraggableTableCell key={cell.id} cell={cell} />
                                  </SortableContext>
                                ),
                              )}
                            </TableRow>
                          </ContextMenuTrigger>
                          {renderContextMenuContent && renderContextMenuContent(row.original)}
                        </ContextMenu>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={columns.length} className="h-24 text-center">
                          {noResultsMessage}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </>
              )}
            </Table>
          </div>
        )}
        {viewType === 'card' && <CardView cases={cases} />}
        {viewType === 'timeline' && <TimelineView cases={cases} />}
        {!hideFooter && <DataTablePagination table={table} fetchingMore={fetchingMore} />}
      </div>
    </DndContext>
  );
}
