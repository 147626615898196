'use client';

import CalendarFormInput from '../CalendarFormInput/CalendarFormInput';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import TextFormInput from '../TextFormInput/TextFormInput';
import { PolicyFormFieldProps } from './PolicyForm';

export const LifeInsurancePolicyFormFields: React.FC<PolicyFormFieldProps> = ({
  handleUpdatePolicy,
  form,
  policy,
}) => {
  return (
    <>
      <div className="grid col-span-2 grid-cols-subgrid">
        <TextFormInput
          title="Policy Number"
          {...form.register(`policyNumber`)}
          handleOnBlur={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('policyNumber', target.value);
          }}
        />
        <TextFormInput
          title="Claim Number"
          {...form.register(`claimNumber`)}
          handleOnBlur={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('claimNumber', target.value);
          }}
        />
      </div>

      <div className="grid col-span-2 grid-cols-subgrid">
        <CalendarFormInput
          {...form.register(`coverageStartDate`)}
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('coverageStartDate', target.value);
          }}
          title="Coverage Start Date"
          resourceTypeObj={{
            type: 'policy',
            id: policy?.policyId,
          }}
        />

        <CalendarFormInput
          {...form.register(`contestabilityEndDate`)}
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('contestabilityEndDate', target.value);
          }}
          title="Contestability End Date"
          resourceTypeObj={{
            type: 'policy',
            id: policy?.policyId,
          }}
        />
      </div>

      <div className="grid col-span-2 grid-cols-subgrid">
        <CurrencyInput
          {...form.register(`deathBenefit`)}
          formHandleBlur={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('deathBenefit', target.value);
          }}
          title="Death Benefit"
        />

        <TextFormInput
          title="Accidental Death Benefit Rider"
          {...form.register(`accidentalDeathBenefitRider`)}
          handleOnBlur={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('accidentalDeathBenefitRider', target.value);
          }}
        />
      </div>
    </>
  );
};
