import { ScrollToTop } from '@colosseum/shared-ui';
import 'animate.css';
import { Amplify } from 'aws-amplify';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { App } from './app/app';
import awsconfig from './aws-exports';
import LoginPage from './features/auth/LoginPage';
import PrivateRoute from './features/auth/PrivateRoute/PrivateRoute';
import { AutomationsPage } from './features/automations/AutomationsPage';
import CampaignStepSelect from './features/campaign/CampaignStepSelect/CampaignStepSelect';
import CampaignsPage from './features/campaigns/CampaignsPage/CampaignsPage';
import CasePage from './features/case/CasePage';
import CasesPage from './features/cases/CasesPage';
import ContactPage from './features/contact/ContactPage';
import ContactsPage from './features/contacts/ContactsPage';
import ErrorPage from './features/error/ErrorPage';
import HomePage from './features/home/HomePage';
import InsightsPage from './features/insights/InsightsPage/InsightsPage';
import LeadsPage from './features/leads/LeadsPage/LeadsPage';
import ProfilePage from './features/profile/ProfilePage';
import SettingsPage from './features/settings/SettingsPage';
import SubscriptionsPage from './features/subscriptions/SubscriptionsPage/SubscriptionsPage';
import TemplateForm from './features/templates/TemplateForm/TemplateForm';
import TemplatesPage from './features/templates/TemplatesPage';
import ProtectedRoute from './features/auth/ProtectedRoute/ProtectedRoute';
import { usePathParts } from '@colosseum/data';
import { Redirect } from './features/auth/Redirect/Redirect';
// import * as Sentry from '@sentry/react';

Amplify.configure(awsconfig);

// Sentry.init({
//   dsn: 'https://236e6a78a02e9ea4ab6f839adf6457e0@o4505960106033152.ingest.sentry.io/4505960124448768',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//     }),
//     new Sentry.Replay(),
//   ],
//   environment: `${process.env.NODE_ENV} - api:${API_STAGE}`,
//   // Performance Monitoring
//   tracesSampleRate: API_STAGE === 'prod' ? 0.4 : 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: ['sandbox101', 'stage'].includes(API_STAGE)
//     ? 1.0
//     : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(document.getElementById('root')!);

if (!root) {
  throw new Error('Unable to find root element');
}

root.render(
  <StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="login" element={<LoginPage />} />

          <Route
            path="surveys"
            element={<Redirect redirectURL={`https://gladiate.typeform.com/to/`} />}
          />

          <Route path="/" element={<PrivateRoute />}>
            <Route
              path="*"
              element={
                <ErrorPage
                  code={404}
                  errorTitle={'An Error Ocurred'}
                  errorMessage={'Please check the URL in the address bar and try again.'}
                />
              }
            />

            <Route path="/" element={<Navigate to="/cases" />} />
            <Route
              path="home"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="insights"
              element={
                <ProtectedRoute>
                  <InsightsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="campaigns"
              element={
                <ProtectedRoute>
                  <CampaignsPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="campaigns/:campaignId/:campaignType/:campaignStep"
              element={
                <ProtectedRoute>
                  <CampaignStepSelect />
                </ProtectedRoute>
              }
            />
            <Route
              path="cases"
              element={
                <ProtectedRoute>
                  <CasesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="cases/:caseId"
              element={
                <ProtectedRoute>
                  <CasePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="templates"
              element={
                <ProtectedRoute>
                  <TemplatesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="templates/:templateId"
              element={
                <ProtectedRoute>
                  <TemplateForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="contacts"
              element={
                <ProtectedRoute>
                  <ContactsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="contacts/:contactId"
              element={
                <ProtectedRoute>
                  <ContactPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="settings"
              element={
                <ProtectedRoute>
                  <SettingsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="automations"
              element={
                <ProtectedRoute>
                  <AutomationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="subscriptions"
              element={
                <ProtectedRoute>
                  <SubscriptionsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="profile"
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }
            >
              <Route
                path=":integration?"
                element={
                  <ProtectedRoute>
                    <SubscriptionsPage />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="leads"
              element={
                <ProtectedRoute>
                  <LeadsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="leads/:caseId"
              element={
                <ProtectedRoute>
                  <LeadsPage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </StrictMode>,
);
