'use client';

import {
  CoverageTypeOptions,
  FULL_STATE_NAMES,
  NoticeStatusOptions,
  StateCoverageTypeOptions,
  US_STATES,
} from '@colosseum/data';
import SelectFormInput from '../SelectFormInput/SelectFormInput';
import { PolicyFormFieldProps } from './PolicyForm';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import CalendarFormInput from '../CalendarFormInput/CalendarFormInput';

export const HealthInsurancePolicyFormFields: React.FC<PolicyFormFieldProps> = ({
  handleUpdatePolicy,
  form,
  policy,
}) => {
  return (
    <>
      <div className="grid col-span-2 grid-cols-subgrid">
        <SelectFormInput
          title="Notice status"
          listItems={NoticeStatusOptions.map((option) => ({
            [option.label]: option.value,
          })).reduce((acc, curr) => ({ ...acc, ...curr }), {})}
          listItemsIsObject
          defaultValue={policy?.noticeStatus}
          placeholderText="Select a Notice Status"
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('noticeStatus', target.value);
          }}
          {...form.register(`noticeStatus`)}
        />
      </div>
      <div className="grid col-span-2 grid-cols-subgrid">
        <SelectFormInput
          title="Coverage Type"
          listItems={CoverageTypeOptions.map((option) => ({
            [option.label]: option.value,
          })).reduce((acc, curr) => ({ ...acc, ...curr }), {})}
          listItemsIsObject
          defaultValue={policy?.coverageType}
          placeholderText="Select a Coverage Type"
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('coverageType', target.value);
          }}
          {...form.register(`coverageType`)}
        />
        {StateCoverageTypeOptions.includes(policy?.coverageType ?? '') && (
          <SelectFormInput
            title="State"
            listItems={US_STATES.map((state, i) => ({ [FULL_STATE_NAMES[i]]: state })).reduce(
              (acc, curr) => ({ ...acc, ...curr }),
              {},
            )}
            listItemsIsObject
            defaultValue={policy?.state}
            placeholderText="Select a State"
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdatePolicy('state', target.value);
            }}
            {...form.register(`state`)}
          />
        )}
      </div>

      <div className="grid col-span-2 grid-cols-subgrid">
        <CalendarFormInput
          {...form.register(`coverageStartDate`)}
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('coverageStartDate', target.value);
          }}
          title="Coverage Start Date"
          resourceTypeObj={{
            type: 'policy',
            id: policy?.policyId,
          }}
        />

        <CalendarFormInput
          {...form.register(`coverageEndDate`)}
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('coverageEndDate', target.value);
          }}
          title="Coverage End Date"
          resourceTypeObj={{
            type: 'policy',
            id: policy?.policyId,
          }}
        />
      </div>

      <div className="grid col-span-2 grid-cols-subgrid">
        <CurrencyInput
          {...form.register(`deductibleAmount`)}
          formHandleBlur={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('deductibleAmount', target.value);
          }}
          title="Deductible"
        />

        <CurrencyInput
          {...form.register(`outOfPocketMax`)}
          formHandleBlur={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('outOfPocketMax', target.value);
          }}
          title="Out of Pocket Max"
        />
      </div>
    </>
  );
};
