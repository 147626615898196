import {
  DefaultV3Response,
  NylasAccountInfo,
  NylasCalendar,
  NylasCreateCalendarPayload,
  NylasEvent,
  NylasEventPayload,
} from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

const basePheidippidesRoute = `/pheidippides/${API_STAGE}/v3`;

export const getEventAndRemindersV3 = (eventId: string) =>
  axiosInstance
    .get<DefaultV3Response<NylasEvent>>(`${basePheidippidesRoute}/events/${eventId}`)
    .then((res) => res.data);

export const getUserCalendarsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<NylasCalendar[]>>(`${basePheidippidesRoute}/calendars`)
    .then((res) => res.data);

export const getAccountAuthUrlV3 = () =>
  axiosInstance
    .get<
      DefaultV3Response<{
        authUrl: string;
      }>
    >(`${basePheidippidesRoute}/accounts/auth`)
    .then((res) => res.data);

export const getUserNylasAccountV3 = () =>
  axiosInstance
    .get<DefaultV3Response<NylasAccountInfo>>(`${basePheidippidesRoute}/accounts/account`)
    .then((res) => res.data);

export const getPrimaryCalendarV3 = () =>
  axiosInstance
    .get<DefaultV3Response<NylasCalendar>>(`${basePheidippidesRoute}/calendars/primary`)
    .then((res) => res.data);

export const connectNylasAccountV3 = (authCode: string) =>
  axiosInstance
    .post<DefaultV3Response<Partial<NylasAccountInfo>>>(`${basePheidippidesRoute}/accounts`, {
      authCode,
    })
    .then((res) => res.data);

export const createCalendarV3 = (data: NylasCreateCalendarPayload) =>
  axiosInstance
    .post<DefaultV3Response<NylasCalendar>>(`${basePheidippidesRoute}/calendars`, data)
    .then((res) => res.data);

export const getCaseEventsForCalendarV3 = ({
  calendarId,
  dateRange,
  caseId,
  primaryCalendar,
}: {
  calendarId: string;
  dateRange: {
    startDate: string;
    endDate: string;
  };
  caseId?: string;
  primaryCalendar?: boolean;
}) =>
  axiosInstance
    .post<DefaultV3Response<NylasEvent[]>>(`${basePheidippidesRoute}/events/views/${caseId}`, {
      calendarId,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      primaryCalendar: primaryCalendar ?? false,
    })
    .then((res) => res.data);

export const createCalendarEventV3 = (calendarId: string, data: Partial<NylasEventPayload>) =>
  axiosInstance
    .post<DefaultV3Response<NylasEventPayload>>(
      `${basePheidippidesRoute}/calendars/${calendarId}/events`,
      data,
    )
    .then((res) => res.data);

export const updateCalendarEventV3 = (
  calendarId: string,
  eventId: string,
  data: Partial<NylasEventPayload>,
) =>
  axiosInstance
    .patch<DefaultV3Response<NylasEventPayload>>(
      `${basePheidippidesRoute}/calendars/${calendarId}/events/${eventId}`,
      data,
    )
    .then((res) => res.data);

export const deleteCalendarEventV3 = (calendarId: string, eventId: string) =>
  axiosInstance
    .delete<DefaultV3Response<NylasEventPayload>>(
      `${basePheidippidesRoute}/calendars/${calendarId}/events/${eventId}`,
    )
    .then((res) => res.data);
