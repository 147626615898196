import { ExpenseType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useUpdateExpense } from '../../../lib/react-query/expense';
import { zodCurrencyType } from '../zodValidationTypes';
import { enqueueAPISnackbar } from '../../snackbars';

const formSchema = z.object({
  categoryId: z.string().optional(),
  utbmsCodeId: z.string().optional(),
  clientBillable: z.string().optional(),
  dateIncurred: z.string().optional(),
  description: z.string().optional(),
  incurredBy: z.array(z.string()).optional(),
  paymentCompleted: z.string().optional(),
  unitCount: z.coerce.number().nonnegative().nullish().optional(),
  unitName: z.string().optional(),
  unitPrice: zodCurrencyType,
  caseContactConnectionId: z.string().optional(),
});

interface useExpenseFormProps {
  expense?: ExpenseType;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTyping: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useExpenseForm = (props: useExpenseFormProps) => {
  const { expense, setShowModal, setTyping } = props;
  const updateExpense = useUpdateExpense();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: expense,
  });
  const handleUpdateExpense = (
    name: keyof z.infer<typeof formSchema>,
    value?: string | number | string[],
  ) => {
    if (!expense) {
      enqueueAPISnackbar({
        message: 'No expense found.',
        variant: 'error',
        key: 'expense-not-found-error',
      });
      setShowModal(false);
      return;
    }

    if (form.getFieldState(name).isDirty) {
      updateExpense
        .mutateAsync({
          caseExpenseId: expense.caseExpenseId,
          [name]: value,
        })
        .then(() => {
          setTyping(false);
          form.resetField(name, { defaultValue: value });
        });
    }
  };

  return { form, handleUpdateExpense };
};
