import { CaseCompleteType, useGetCases } from '../lib/react-query/case';
import { useGetFirmCaseConflicts } from '../lib/react-query/case-contact-connection';
import { useGetFirmExpenses } from '../lib/react-query/expense';
import { useGetFirmUsers } from '../lib/react-query/firm';
import { useGetFirmMedicalTreatments } from '../lib/react-query/medical-treatment';
import { useGetFirmPolicies } from '../lib/react-query/policies';
import { useGetReferralSources } from '../lib/react-query/referral-sources';
import { useGetFirmStaff } from '../lib/react-query/staff';
import { useGetAllItemTagConnections } from '../lib/react-query/tag';
import { useGetTasks } from '../lib/react-query/task';
import { useGetTeams } from '../lib/react-query/team';
import { useGetFirmUserWithDisplayNameFromUsername } from './useGetFirmUserWithDisplayNameFromUsername';

export const useGetCasesComplete = () => {
  const firmUsersQuery = useGetFirmUsers();
  const firmUsersData = firmUsersQuery.data?.data;
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();

  const casesRes = useGetCases();
  const cases = casesRes.data?.data || [];
  const completeCaseData: CaseCompleteType[] = [];
  const { data: casePoliciesData } = useGetFirmPolicies();
  const casePolicies = casePoliciesData?.data || [];
  const { data: medicalTreatmentsData } = useGetFirmMedicalTreatments();
  const medicalTreatments = medicalTreatmentsData?.data || [];
  const { data: itemTagConnectionsData } = useGetAllItemTagConnections();
  const tags = itemTagConnectionsData?.data || [];
  const { data: tasksData } = useGetTasks(undefined);
  const tasks = tasksData?.data || [];
  const { data: staffData } = useGetFirmStaff();
  const staff = staffData?.data || [];
  const { data: expensesData } = useGetFirmExpenses();
  const expenses = expensesData?.data || [];
  const { data: conflictsData } = useGetFirmCaseConflicts();
  const conflicts = conflictsData?.data || [];
  const { data: referralSourcesData } = useGetReferralSources();
  const { data: teamsData } = useGetTeams();
  const teams = teamsData?.data || [];
  const referralSources = referralSourcesData?.data || [];
  cases.forEach((caseData, i) => {
    completeCaseData[i] = {
      ...cases[i],
      umUimCoverage: '',
      thirdPartyCoverage: '',
      medicalTreatmentAmountBilled: 0,
      policies: [],
      medicalTreatments: [],
      tags: [],
      tasks: [],
      assignees: [],
      expenses: null,
      conflicts: [],
      teams: [],
      mriCount: null,
      surgeryCount: null,
      injectionCount: null,
    };
    let umUimPerAccident = 0;
    let umUimPerPerson = 0;
    let bodilyInjuryLiabilityPerAccident = 0;
    let bodilyInjuryLiabilityPerPerson = 0;
    casePolicies?.forEach((policy) => {
      // link policies to cases
      if (policy.policyId?.split('-')[0] === caseData.caseId) {
        completeCaseData[i].policies?.push(policy);
        if (
          policy.umUimCoverageAmountPerAccident &&
          policy.umUimCoverageAmountPerAccident > umUimPerAccident
        ) {
          umUimPerAccident = policy.umUimCoverageAmountPerAccident;
        }
        if (
          policy.umUimCoverageAmountPerPerson &&
          policy.umUimCoverageAmountPerPerson > umUimPerPerson
        ) {
          umUimPerPerson = policy.umUimCoverageAmountPerPerson;
        }
        if (policy.bodilyInjuryLiabilityPerAccident) {
          bodilyInjuryLiabilityPerAccident += policy.bodilyInjuryLiabilityPerAccident;
        }
        if (policy.bodilyInjuryLiabilityPerPerson) {
          bodilyInjuryLiabilityPerPerson += policy.bodilyInjuryLiabilityPerPerson;
        }
      }
    });
    completeCaseData[i].umUimCoverage = `${umUimPerPerson || '-'} / ${umUimPerAccident || '-'}`;
    completeCaseData[i].thirdPartyCoverage = `${bodilyInjuryLiabilityPerPerson || '-'} / ${
      bodilyInjuryLiabilityPerAccident || '-'
    }`;
    let amountBilled = 0;
    medicalTreatments.forEach((treatment) => {
      // link medical treatments to cases
      if (treatment.medicalTreatmentId?.split('-')[0] === caseData.caseId) {
        if (treatment.amountBilled && typeof treatment.amountBilled === 'number') {
          amountBilled += treatment.amountBilled;
        }
        if (treatment.mriPerformed === '1' && typeof treatment.mriCount === 'number') {
          typeof completeCaseData[i].mriCount === typeof null
            ? (completeCaseData[i].mriCount = treatment.mriCount)
            : (completeCaseData[i].mriCount =
                Number(completeCaseData[i].mriCount) + treatment.mriCount);
        }
        if (treatment.surgeryPerformed === '1' && typeof treatment.surgeryCount === 'number') {
          typeof completeCaseData[i].surgeryCount === typeof null
            ? (completeCaseData[i].surgeryCount = treatment.surgeryCount)
            : (completeCaseData[i].surgeryCount =
                Number(completeCaseData[i].surgeryCount) + treatment.surgeryCount);
        }
        if (treatment.injectionPerformed === '1' && typeof treatment.injectionCount === 'number') {
          typeof completeCaseData[i].injectionCount === typeof null
            ? (completeCaseData[i].injectionCount = treatment.injectionCount)
            : (completeCaseData[i].injectionCount =
                Number(completeCaseData[i].injectionCount) + treatment.injectionCount);
        }
        completeCaseData[i].medicalTreatments?.push(treatment);
      }
    });
    completeCaseData[i].medicalTreatmentAmountBilled = amountBilled;
    // TAGS
    tags?.forEach((tag) => {
      if (tag?.itemTagConnectionId.split('-')[0] === caseData.caseId && tag.type === 'case') {
        completeCaseData[i].tags?.push(tag);
      }
    });
    // TASKS
    tasks?.forEach((task) => {
      if (task?.taskId?.split('-')[0] === caseData.caseId) {
        completeCaseData[i].tasks?.push(task);
      }
    });
    // STAFF
    staff?.forEach((staffMember) => {
      if (staffMember?.staffId?.split('-')[0] === caseData.caseId) {
        completeCaseData[i].assignees?.push({
          ...staffMember,
          displayName:
            getFirmUserWithDisplayNameFromUsername(staffMember?.username ?? '')?.displayName ?? '-',
        });
      }
    });
    // TEAM
    teams.forEach((team) => {
      if (team.teamId === caseData.teamId) {
        completeCaseData[i].teams?.push(team);
      }
    });
    // EXPENSES
    expenses?.forEach((expense) => {
      const totalCost = (expense?.unitCount ?? 1) * (expense?.unitPrice ?? 0);
      if (
        expense?.caseExpenseId?.split('-')[0] === caseData.caseId &&
        totalCost &&
        expense?.waive !== '1'
      ) {
        typeof completeCaseData[i].expenses === typeof null
          ? (completeCaseData[i].expenses = totalCost)
          : (completeCaseData[i].expenses = Number(completeCaseData[i].expenses) + totalCost);
      }
    });
    // CONFLICTS
    conflicts?.forEach((conflict) => {
      if (conflict?.clientCaseId?.split('-')[0] === caseData.caseId) {
        completeCaseData[i].conflicts?.push(conflict);
      }
    });
    // REFERRAL SOURCE
    completeCaseData[i].referralSource = referralSources?.find(
      (referralSource) => referralSource.referralSourceId === caseData.referralSourceId,
    )?.title;
  });
  return {
    ...casesRes,
    data: {
      data: completeCaseData,
    },
  };
};
