/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  cn,
  getNameToDisplayFromCognito,
  useCreateTaskComment,
  useDeleteTask,
  useDeleteTaskAssignee,
  useDeleteTaskAssigner,
  useGetFirmUsers,
  useUpdateTask,
} from '@colosseum/data';
import {
  DefaultV3Response,
  TaskAssigneeType,
  TaskAssignerType,
  TaskType,
  taskStatusOptions,
} from '@gladiate/types';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import utc from 'dayjs/plugin/utc';
import { startCase } from 'lodash';
import { CalendarIcon } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import ActionConfirmModal from '../ActionConfirmModal/ActionConfirmModal';
import AssigneeChipGroup from '../AssigneeChipGroup/AssigneeChipGroup';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import FirmUserLookup from '../FirmUserLookup/FirmUserLookup';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import ResourceTaskComment from '../ResourceTaskComment/ResourceTaskComment';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import EditableTextArea from '../inputs/EditableTextArea/EditableTextArea';
import { Button } from '../shadcn/Button/Button';
import { CalendarPicker } from '../shadcn/CalendarPicker/CalendarPicker';
import { Form } from '../shadcn/Form/Form';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../shadcn/Select/Select';
dayjs.extend(calendar);
dayjs.extend(utc);

/* eslint-disable-next-line */
export interface ResourceTaskProps {
  task: TaskType;
  handleCreateAssignee: (
    assignee: string,
    taskId: string,
  ) => Promise<DefaultV3Response<TaskAssigneeType>>;
  handleCreateAssigner: (
    assigner: string,
    taskId: string,
  ) => Promise<DefaultV3Response<TaskAssignerType>>;
  showTypeChip?: boolean;
  isLead?: boolean;
  isComment?: boolean;
}

const statusCircleOptions = {
  toDo: (
    <div data-cy="task-status-to-do" className="w-4 h-4 bg-gray-300 rounded-full min-w-4">
      &nbsp;
    </div>
  ),
  inProgress: (
    <div data-cy="task-status-in-progress" className="w-4 h-4 bg-yellow-300 rounded-full min-w-4">
      &nbsp;
    </div>
  ),
  completed: (
    <div data-cy="task-status-completed" className="w-4 h-4 bg-green-300 rounded-full min-w-4">
      &nbsp;
    </div>
  ),
  willNotComplete: (
    <div
      data-cy="task-status-will-not-complete"
      className="w-4 h-4 bg-red-300 rounded-full min-w-4"
    >
      &nbsp;
    </div>
  ),
};

const formSchema = z.object({
  message: z.string().nonempty(),
});

export function ResourceTask(props: ResourceTaskProps) {
  const { task, handleCreateAssignee, handleCreateAssigner, isLead, isComment } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [expanded, setExpanded] = useState(isComment ? true : false);
  const [activeCommentId, setActiveCommentId] = useState('');
  const [dueDatePopoverOpen, setDueDatePopoverOpen] = useState(false);

  const deleteTask = useDeleteTask(task.taskId);
  const updateResourceTask = useUpdateTask();
  const deleteTaskAssignee = useDeleteTaskAssignee();
  const deleteTaskAssigner = useDeleteTaskAssigner();
  const createTaskComment = useCreateTaskComment();

  const commentForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: '',
    },
  });

  const firmUsersQuery = useGetFirmUsers();
  const firmUsersData = firmUsersQuery.data?.data;
  const assigneesMapped =
    firmUsersData
      ?.filter((firmUser) =>
        task?.assignees
          ?.map((assignees) => assignees?.userAssigned)
          .includes(firmUser.Username ?? ''),
      )
      ?.map((item) => {
        if (!item?.name) {
          return item?.Username ?? '';
        }

        return getNameToDisplayFromCognito(item) ?? '-';
      }) ?? [];

  const isDeletingLoading = deleteTask.isLoading;

  const handleDeleteTask = () => {
    deleteTask.mutateAsync().then((res) => {
      if (res.data.taskId) {
        enqueueSnackbar('Task successfully deleted', {
          variant: 'success',
        });
      }
    });
  };

  const handleDeleteAssignee = (taskAssigneeId?: string) => {
    return deleteTaskAssignee
      .mutateAsync({
        taskAssigneeId: taskAssigneeId ?? '',
      })
      .then((res) => {
        if (res.data.taskAssigneeId) {
          enqueueSnackbar('Assignee successfully deleted', {
            variant: 'success',
          });
        }
      });
  };

  const handleDeleteAssigner = (taskAssignerId?: string) => {
    return deleteTaskAssigner
      .mutateAsync({
        taskAssignerId: taskAssignerId ?? '',
      })
      .then((res) => {
        if (res.data.taskAssignerId) {
          enqueueSnackbar('Assigner successfully deleted', {
            variant: 'success',
          });
        }
      });
  };

  const handleCreateTaskComment = (taskId: string, message: string) => {
    return createTaskComment
      .mutateAsync({
        taskId,
        data: {
          message,
        },
      })
      .then((res) => {
        if (res?.data) {
          commentForm.reset();
          setActiveCommentId('');
          setShowComments(true);
        }
      });
  };

  const renderDueDate = (showEmptyState?: boolean) => {
    if (showEmptyState && !task.dueDate) {
      return <div className="text-sm text-gray-500">No due date</div>;
    }
    const formattedDueDate =
      dayjs(task.dueDate).year() === dayjs().year()
        ? dayjs(`${task?.dueDate ?? ''}`).format('MMM D')
        : dayjs(`${task?.dueDate ?? ''}`).format("MMM D ('YY)");

    const daysFromStartToDue = dayjs(task.dueDate).diff(task.dateCreated, 'd');
    const daysUntilDue = dayjs(task.dueDate).diff(dayjs(), 'd');
    // check if 75% of time has passed
    let isDueSoon = false;
    if (daysFromStartToDue && daysUntilDue) {
      if (daysUntilDue / daysFromStartToDue <= 0.25) {
        isDueSoon = true;
      }
    }

    return (
      task.dueDate && (
        <div className="text-sm text-gray-500">
          <span className="text-sm text-black">Due: </span>
          <span
            className={cn(
              (daysUntilDue === 0 || isDueSoon) && 'text-yellow-500',
              daysUntilDue < 0 && 'text-red-500',
              [taskStatusOptions.completed, taskStatusOptions.willNotComplete].includes(
                task.status,
              ) && 'text-gray-500',
            )}
          >
            {formattedDueDate}
          </span>
        </div>
      )
    );
  };

  return (
    <div>
      {expanded ? (
        <div className="flex flex-col justify-between py-6 gap-y-2 grow">
          <div className="flex cursor-pointer" onClick={() => setExpanded(false)}>
            <Breadcrumbs resourceType={task.type} resourceId={task.taskId} isLead={isLead} />
            <div className="flex-grow" />
            <ChevronUpIcon className="w-4" />
          </div>
          <div className="flex items-center gap-3">
            <Select
              onValueChange={(value) => {
                updateResourceTask.mutate({
                  taskId: task.taskId ?? '',
                  data: {
                    status: value as taskStatusOptions,
                  },
                });
              }}
            >
              <SelectTrigger className="w-auto h-8">
                <SelectValue>
                  <div className="flex text-nowrap gap-x-1">
                    {statusCircleOptions[task.status ?? taskStatusOptions.toDo]}
                    {startCase(task.status ?? taskStatusOptions.toDo)}
                  </div>
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Status</SelectLabel>
                  <SelectItem key={taskStatusOptions.toDo} value={taskStatusOptions.toDo}>
                    <div className="flex gap-x-2">
                      {statusCircleOptions[taskStatusOptions.toDo]}
                      <div>To do</div>
                    </div>
                  </SelectItem>
                  <SelectItem
                    key={taskStatusOptions.inProgress}
                    value={taskStatusOptions.inProgress}
                  >
                    <div className="flex gap-x-2">
                      {statusCircleOptions[taskStatusOptions.inProgress]}
                      <div>In Progress</div>
                    </div>
                  </SelectItem>
                  <SelectItem key={taskStatusOptions.completed} value={taskStatusOptions.completed}>
                    <div className="flex gap-x-2">
                      {statusCircleOptions[taskStatusOptions.completed]}
                      <div>Completed</div>
                    </div>
                  </SelectItem>
                  <SelectItem
                    key={taskStatusOptions.willNotComplete}
                    value={taskStatusOptions.willNotComplete}
                  >
                    <div className="flex gap-x-2">
                      {statusCircleOptions[taskStatusOptions.willNotComplete]}
                      <div>Will Not Complete</div>
                    </div>
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>

            <EditableTextArea
              placeholder={'Task title'}
              defaultValue={task.title}
              onBlur={(e) =>
                updateResourceTask.mutate({
                  taskId: task.taskId ?? '',
                  data: {
                    title: e.target.value ?? '',
                  },
                })
              }
              id={`${task.taskId}-title` ?? 'title'}
              showOutlines
              dataCy="task-title-textarea"
            />
            <div className="flex">
              <div className="flex items-center mr-2 text-sm text-gray-500 w-15">
                {renderDueDate(true)}
              </div>
              <Popover
                modal
                open={dueDatePopoverOpen}
                onOpenChange={(e) => setDueDatePopoverOpen(e)}
              >
                <PopoverTrigger asChild>
                  <Button className="h-10 px-2 py-0 min-w-10" variant="ghost">
                    <CalendarIcon className="w-4 h-4 hover:text-atlantic-blue" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="z-50 w-auto p-0 bg-white" align="start">
                  <CalendarPicker
                    mode="single"
                    // @ts-ignore
                    selected={dayjs(task.dueDate).toDate() ?? ''}
                    onSelect={(e) => {
                      updateResourceTask
                        .mutateAsync({
                          taskId: task.taskId ?? '',
                          data: {
                            dueDate: dayjs(e).format('YYYY-MM-DD'),
                          },
                        })
                        .then((res) => {
                          if (res.data.taskId) {
                            enqueueSnackbar('Due date successfully updated', {
                              variant: 'success',
                            });
                          }
                        });
                      setDueDatePopoverOpen(false);
                    }}
                    initialFocus
                    fixedWeeks
                  />
                </PopoverContent>
              </Popover>
            </div>
            <Button
              data-cy="delete-task-button"
              onClick={() => setShowDeleteModal(true)}
              variant="ghost"
              size="sm"
            >
              {isDeletingLoading ? (
                <GladiateLoader height={20} />
              ) : (
                <TrashIcon className="w-4 h-4 text-red-500" />
              )}
            </Button>
            <ActionConfirmModal
              open={showDeleteModal}
              setOpen={setShowDeleteModal}
              actionFunction={handleDeleteTask}
              actionFunctionIsMutation={true}
              title={'Delete Task'}
            />
          </div>
          <EditableTextArea
            placeholder={'Task description'}
            defaultValue={task.description}
            onBlur={(e) => {
              updateResourceTask.mutate({
                taskId: task.taskId ?? '',
                data: {
                  description: e.target.value ?? '',
                },
              });
            }}
            id={`${task.taskId}-description`}
            showOutlines
            dataCy="task-description-textarea"
          />
          <div className="flex flex-wrap items-center gap-2">
            <div className="mr-2 text-sm text-gray-500 w-15">{'Assignees: '}</div>
            <FirmUserLookup
              key="assigneeId"
              selectedUsers={
                task?.assignees.map((assignee) => ({
                  username: assignee.userAssigned,
                  assigneeId: assignee.taskAssigneeId,
                })) ?? []
              }
              handleAdd={(user: string) => {
                return handleCreateAssignee(user, task.taskId ?? '');
              }}
              handleRemove={(user) => {
                return handleDeleteAssignee(user.assigneeId);
              }}
              allowMultiple
              tagClassName="mt-0"
              buttonClassName="mt-0"
              buttonLoaderClassName="mt-0"
            />
          </div>
          <div className="flex flex-wrap items-center gap-2">
            <div className="mr-2 text-sm text-gray-500 w-15">{'Assigners: '}</div>
            <FirmUserLookup
              key="assigneeId"
              selectedUsers={
                task?.assigners.map((assignee) => ({
                  username: assignee.userAssigned,
                  assigneeId: assignee.taskAssignerId,
                })) ?? []
              }
              handleAdd={(user: string) => {
                return handleCreateAssigner(user, task.taskId ?? '');
              }}
              handleRemove={(user) => {
                return handleDeleteAssigner(user.assigneeId);
              }}
              allowMultiple
              tagClassName="mt-0"
              buttonClassName="mt-0"
              buttonLoaderClassName="mt-0"
            />
          </div>
          <div className="flex mb-2 text-sm gap-x-2" style={{ lineHeight: '40px' }}>
            {!isComment && (
              <Button
                className="p-0 text-blue-600"
                onClick={() =>
                  setActiveCommentId(activeCommentId === task.taskId ? '' : task.taskId ?? '')
                }
              >
                Comment
              </Button>
            )}
          </div>
          <div className="pl-12">
            <Form {...commentForm}>
              <form
                onSubmit={commentForm.handleSubmit((e: { message?: string }) =>
                  handleCreateTaskComment(task.taskId ?? '', e.message ?? ''),
                )}
              >
                {!isComment && activeCommentId === task.taskId && (
                  <div className="flex flex-col mt-4">
                    <TextFormInput
                      title="Comment"
                      type="textarea"
                      {...commentForm.register('message')}
                    />
                    <div className="ml-auto">
                      <Button
                        className="mt-2 min-w-8 mr-2 h-[36px] border border-gray-300 rounded-lg"
                        onClick={() => setActiveCommentId('')}
                        type="button"
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={createTaskComment.isLoading}
                        type="submit"
                        className="float-right mt-2 p-2 focus:outline-none rounded-lg w-[50px] h-9 hover:text-light-blue bg-light-blue hover:bg-atlantic-blue text-atlantic-blue cursor-pointer"
                      >
                        {createTaskComment.isLoading ? (
                          <GladiateLoader height={20} />
                        ) : (
                          <div>Post</div>
                        )}
                      </Button>
                    </div>
                  </div>
                )}
              </form>
            </Form>
            {task?.comments && task?.comments.length > 0 && (
              <Button
                className="text-sm text-gray-500"
                onClick={() => setShowComments(!showComments)}
              >
                —{showComments ? 'Hide' : `View ${task?.comments?.length}`} comments
              </Button>
            )}
          </div>
          {showComments && !isComment && (
            <div className="flex flex-col pl-8">
              {task?.comments?.map((comment) => (
                <ResourceTaskComment comment={comment} key={comment.taskCommentId} />
              ))}
            </div>
          )}
        </div>
      ) : (
        <div onClick={() => setExpanded(true)} className="py-6 cursor-pointer">
          <div className="flex">
            <Breadcrumbs resourceType={task.type} resourceId={task.taskId} isLead={isLead} />
            <div className="flex-grow" />
            <ChevronDownIcon className="w-4" />
          </div>
          <div className="flex items-center pt-2 gap-x-2" data-cy="task-expand-button">
            {statusCircleOptions[task.status ?? taskStatusOptions.toDo]}
            <div className="flex items-baseline gap-x-2 max-w-[55%]">
              <div className="text-nowrap" data-cy="task-title-list-view">
                {task.title}
              </div>
              {task.description && <ChevronRightIcon className="w-2" />}
              <div
                className="overflow-hidden text-sm text-gray-500 text-ellipsis whitespace-nowrap"
                data-cy="task-description-list-view"
              >
                {task.description}
              </div>
            </div>
            <div className="flex grow" />
            <div className="flex items-center">
              <div className="px-2">
                <AssigneeChipGroup readonly assignees={assigneesMapped} small />
              </div>
            </div>
            {renderDueDate()}
          </div>
        </div>
      )}
    </div>
  );
}

export default ResourceTask;
