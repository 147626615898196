import {
  findFolderPathFromRoot,
  getFirmId,
  getFullFileId,
  useCreateFileItemForCase,
  useDeleteFileForCase,
  useGenerateDocumentWithData,
  useMoveFileForCase,
  useRenameFileForCase,
  useUploadFileToCaseRepo,
} from '@colosseum/data';
import { FileResourceType, TemplateType, TemplateVariables } from '@gladiate/types';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CreateButton from '../CreateButton/CreateButton';
import { DocGenerationForm } from '../DocGenerationForm/DocGenerationForm';
import FilePreview from '../FilePreview/FilePreview';
import { SendDocForm } from '../SendDocForm/SendDocForm';
import { Slideover } from '../Slideover/Slideover';
import { FileExplorerContext } from './FileExplorerProvider';
import { FileGrid } from './FileGrid';
import { FileTreeNode } from './FileTreeNode';
import { Toolbar } from './Toolbar';

export interface FileExplorerRootProps {
  caseId: string;
  folderSelectDisabled?: boolean;
  onSelectedFilesChange?: (files: FileResourceType[]) => void;
  listViewOnly?: boolean;
  collapsibleHidden?: boolean;
  buttonsState?: {
    addContent: boolean;
    createFolder: boolean;
    rename: boolean;
    move: boolean;
    download: boolean;
    delete: boolean;
    generateDocument: boolean;
    sendForSignature: boolean;
  };
  singleSelectMode?: boolean;
  targetFolderId?: string;
}

const firmId = getFirmId();

export const FileExplorerRoot: FC<FileExplorerRootProps> = (props) => {
  const {
    caseId,
    buttonsState,
    collapsibleHidden,
    listViewOnly,
    onSelectedFilesChange,
    folderSelectDisabled,
    singleSelectMode,
  } = props;

  const rootFolderId = `${firmId}-${caseId}`;

  const {
    isFilePreviewModalOpen,
    setIsFilePreviewModalOpen,
    filePreviewItem,
    rootFolder,
    selectedFiles,
    isRootFolderDataLoading,
    isRootFolderDataFetching,
    currentFolder,
    setCurrentFolderId,
    deletingFiles,
    setFilePreviewItem,
  } = useContext(FileExplorerContext);

  // #region State
  const [hasNavigatedToTargetFolder, setHasNavigatedToTargetFolder] = useState(false);
  const [docGenPayload, setDocGenPayload] = useState<TemplateVariables>({});
  const [docGenReady, setDocGenReady] = useState(false);
  const [sendForSignError, setSendForSignError] = useState(false);
  const [sendForSignLoading, setSendForSignLoading] = useState(false);
  const [tempFileForGenToSign, setTempFileForGenToSign] = useState<FileResourceType | undefined>(); //this needs rework but not now
  const [isFileTreeCollapsed, setIsFileTreeCollapsed] = useState(false);

  const [navigationHistory, setNavigationHistory] = useState<FileResourceType[]>([]);
  const [isListView, setIsListView] = useState(false);
  const [showDocGenerationFormSlideover, setShowDocGenerationFormSlideover] = useState(false);
  const [showSignDocFormSlideover, setShowSignDocFormSlideover] = useState(false);
  const [selectedInsurancePolicy, setSelectedInsurancePolicy] = useState('');
  const [docGenLoading, setDocGenLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({} as TemplateType);
  // #endregion

  // Call the function passed down from the parent component
  // whenever the selectedFiles state changes
  useEffect(() => {
    if (onSelectedFilesChange) {
      onSelectedFilesChange(selectedFiles);
    }
  }, [selectedFiles, onSelectedFilesChange]);

  //sign doc form ref this is old copy paste code
  const signDocFormRef = useRef<any>();

  const generateDocumentMutation = useGenerateDocumentWithData();

  const fullId =
    currentFolder?.parentResourceId &&
    currentFolder?.resourceId &&
    `${currentFolder?.parentResourceId}-${currentFolder?.resourceId}`;

  const currentDirectoryId = fullId ?? rootFolderId;

  const folderItems = currentFolder ? currentFolder : rootFolder;

  const uploadFile = useUploadFileToCaseRepo(caseId);
  const deleteFile = useDeleteFileForCase(caseId);
  const createFileItem = useCreateFileItemForCase(caseId);
  const renameFile = useRenameFileForCase(caseId);
  const moveFile = useMoveFileForCase(caseId);

  // Add a function to toggle the view mode
  const toggleViewMode = () => {
    setIsListView(!isListView);
  };

  const handleFolderClick = (item: FileResourceType) => {
    setCurrentFolderId(item?.resourceId);
    const pathToCurrentFolder = (
      findFolderPathFromRoot({ children: [rootFolder ?? {}] }, item?.resourceId) ?? []
    ).slice(1, -1);
    setNavigationHistory(pathToCurrentFolder);
  };

  const handleGoBack = () => {
    const updatedHistory = [...navigationHistory];
    updatedHistory.pop();
    setNavigationHistory(updatedHistory);
    const parentFolder = updatedHistory[updatedHistory.length - 1] || null;
    setCurrentFolderId(parentFolder?.resourceId);
  };

  async function generateDocument(fileId: string, templateTitle: string, outputFormat: string) {
    setDocGenLoading(true);

    const result = await generateDocumentMutation.mutateAsync({
      fileId,
      outputFormat,
      ...docGenPayload,
    });

    if (result && result.data?.status === 'SUCCESS' && result.data?.genDocKey) {
      createFileItem
        .mutateAsync({
          name: `${templateTitle}.${outputFormat}`,
          currentDirectory: currentDirectoryId,
          resourceType: 'FILE',
          s3ObjKey: result.data.genDocKey,
        })
        .then((res) => {
          setTempFileForGenToSign(res?.data?.data);

          if (selectedTemplate.sign) {
            setShowDocGenerationFormSlideover(false);
            setShowSignDocFormSlideover(true);
          } else {
            setShowSignDocFormSlideover(false);
            enqueueSnackbar('Document generated successfully', {
              variant: 'success',
            });
          }
        });

      setDocGenLoading(false);
    } else {
      enqueueSnackbar('Error generating document please try again', {
        variant: 'error',
      });

      setDocGenLoading(false);
    }
  }

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        uploadFile.mutate({
          rawFile: file,
          currentDirectory: currentDirectoryId,
        });
      });
    },
    [uploadFile, caseId, currentDirectoryId],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  const singularSelectedFile = folderItems?.children?.find(
    (item) => item.resourceId === Array.from(selectedFiles)[0]?.resourceId,
  );

  useEffect(() => {
    if (!isFilePreviewModalOpen) {
      setFilePreviewItem(undefined);
    }
  }, [isFilePreviewModalOpen]);

  return (
    <>
      <div>
        <div
          {...getRootProps()}
          className={`flex flex-col w-full  rounded-xl h-[500px] ${
            isDragActive ? 'bg-green-200' : ''
          }`}
        >
          <input {...getInputProps()} />
          <div className="flex flex-col w-full  border border-gray-300 rounded-xl h-[500px]">
            <Toolbar
              onGoBack={handleGoBack}
              onViewModeToggle={toggleViewMode}
              isListView={isListView}
              setNavigationHistory={setNavigationHistory}
              navigationHistory={navigationHistory}
              caseId={caseId}
              currentDirectoryId={fullId ?? rootFolderId}
              setShowDocGenerationFormSlideover={setShowDocGenerationFormSlideover}
              setShowSignDocFormSlideover={setShowSignDocFormSlideover}
              uploadFileMutation={uploadFile}
              deleteFileMutation={deleteFile}
              createFileItemMutation={createFileItem}
              renameFileItemMutation={renameFile}
              moveFileItemMutation={moveFile}
              singularSelectedFile={singularSelectedFile}
              buttonsState={buttonsState}
              listViewOnly={listViewOnly}
            />

            <div className="relative flex h-full overflow-hidden">
              {!collapsibleHidden && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {!isFileTreeCollapsed ? (
                    <>
                      <div className="w-1/4 p-4 overflow-y-auto text-sm border-r border-gray-300 ">
                        {rootFolder &&
                          rootFolder?.children?.map((item: FileResourceType) => {
                            return (
                              <FileTreeNode
                                key={getFullFileId(item)}
                                item={item}
                                level={0}
                                onFolderClick={handleFolderClick}
                              />
                            );
                          })}
                      </div>
                      <button
                        className="absolute bottom-0 flex items-center justify-center w-8 h-8 text-blue-500 bg-white border-t border-r border-gray-300 rounded-tr-lg rounded-bl-xl hover:bg-gray-100 focus:outline-none"
                        onClick={() => setIsFileTreeCollapsed(true)}
                      >
                        <ChevronDoubleLeftIcon className="w-5 h-5 " />
                      </button>
                    </>
                  ) : (
                    <button
                      className="absolute bottom-0 z-20 flex items-center justify-center w-8 h-8 text-blue-500 bg-white border-t border-r border-gray-300 rounded-tr-lg rounded-bl-xl hover:bg-gray-100 focus:outline-none"
                      onClick={() => setIsFileTreeCollapsed(false)}
                    >
                      <ChevronDoubleRightIcon className="w-5 h-5" />
                    </button>
                  )}
                </>
              )}

              <div className="w-full p-2 pb-6 overflow-auto">
                <FilePreview
                  caseId={caseId}
                  isOpen={isFilePreviewModalOpen}
                  setOpen={setIsFilePreviewModalOpen}
                  filePreviewItem={filePreviewItem}
                  showItemsNav
                />
                <FileGrid
                  items={folderItems?.children ?? []}
                  isLoading={isRootFolderDataLoading}
                  onFolderClick={handleFolderClick}
                  isListView={listViewOnly ?? isListView}
                  currentDirectoryId={fullId ?? rootFolderId}
                  newItemLoading={
                    createFileItem.isLoading ||
                    isRootFolderDataFetching ||
                    uploadFile.isLoading ||
                    docGenLoading
                  }
                  moveFileMutation={moveFile}
                  folderSelectDisabled={folderSelectDisabled}
                  singleSelectMode={singleSelectMode}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Slideover
        open={showDocGenerationFormSlideover}
        setOpen={setShowDocGenerationFormSlideover}
        title="Document Generation"
        description="Generate a document from a template."
        typing={false}
        displayDeleteButton={false}
        bottomButtons={
          <div className="flex justify-between w-full px-4 py-4 border-t border-t-gray-200">
            <CreateButton
              title="Generate PDF"
              loading={docGenLoading}
              disabled={!docGenReady}
              onClick={() => {
                generateDocument(
                  selectedTemplate.documentId ? selectedTemplate.documentId : '',
                  selectedTemplate.title ? selectedTemplate.title : '',
                  'pdf',
                );
                setShowDocGenerationFormSlideover(false);
              }}
            />
            <CreateButton
              title="Generate Docx"
              loading={docGenLoading}
              disabled={!docGenReady}
              onClick={() => {
                generateDocument(
                  selectedTemplate.documentId ? selectedTemplate.documentId : '',
                  selectedTemplate.title ? selectedTemplate.title : '',
                  'docx',
                );
                setShowDocGenerationFormSlideover(false);
              }}
            />
          </div>
        }
      >
        <DocGenerationForm
          caseId={props.caseId}
          setSelectedTemplate={setSelectedTemplate}
          setSelectedInsurancePolicy={setSelectedInsurancePolicy}
          setDocGenPayload={setDocGenPayload}
          setDocGenReady={setDocGenReady}
        />
      </Slideover>
      <Slideover
        open={showSignDocFormSlideover}
        setOpen={setShowSignDocFormSlideover}
        title="Send Document For Signing"
        description="Fill out information below to send document for signing."
        typing={false}
        displayDeleteButton={false}
        bottomButtons={
          !sendForSignLoading && (
            <div className="flex justify-end w-full px-4 py-4 ">
              <CreateButton
                onClick={() => {
                  signDocFormRef.current.dispatchEvent(
                    new Event('submit', { cancelable: true, bubbles: true }),
                  );
                }}
                title="Send for signature"
                loading={false}
              />
            </div>
          )
        }
      >
        <SendDocForm
          s3File={tempFileForGenToSign === undefined ? singularSelectedFile : tempFileForGenToSign}
          setSelectedTemplate={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
          setSignDocFormSlideover={setShowSignDocFormSlideover}
          formRef={signDocFormRef}
          selectedInsurancePolicy={selectedInsurancePolicy}
          caseId={caseId}
          loading={sendForSignLoading}
          setLoading={setSendForSignLoading}
          setError={setSendForSignError}
        />
      </Slideover>
    </>
  );
};
