import {
  displayContactName,
  formatEmails,
  formatPhoneNumbers,
  prettifyDateString,
} from '@colosseum/data';
import {
  ContactItemViewer,
  dateRangeFilterFn,
  renderCell,
  renderHeader,
  renderNumberCellWithHover,
  stringIncludesFilterFn,
} from '@colosseum/shared-ui';
import { ContactEmailType, ContactViewModelType, ItemTagConnectionType } from '@gladiate/types';
import { createColumnHelper } from '@tanstack/react-table';
import { intersection, startCase } from 'lodash';

const columnHelper = createColumnHelper<ContactViewModelType>();

export const columns = [
  columnHelper.accessor((row) => displayContactName(row), {
    id: 'Name',
    sortUndefined: 1,
    enableHiding: false,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  }),
  columnHelper.accessor((row) => row?.contactNumbers, {
    id: 'Number',
    sortUndefined: 1,
    header: renderHeader,
    filterFn: (row, _id, value: string) => {
      const numbers = row.original.contactNumbers.map((number) => number.number);
      return intersection(numbers, value).length > 0;
    },
    cell: (props) => {
      const totalPhoneNumbers = props.getValue();
      const { primaryPhoneNumber, additionalPhoneNumbers } = formatPhoneNumbers(totalPhoneNumbers);
      return (
        <ContactItemViewer
          primaryValue={primaryPhoneNumber}
          additionalValues={additionalPhoneNumbers}
        />
      );
    },
  }),
  columnHelper.accessor((row) => row?.contactEmails, {
    id: 'Email',
    sortingFn: (a, b, columnId) => {
      const [emailA, emailB] = [
        a.getValue<ContactEmailType[]>(columnId)?.[0]?.emailAddress ?? 1,
        b.getValue<ContactEmailType[]>(columnId)?.[0]?.emailAddress ?? -1,
      ];
      return emailA.toLocaleString().localeCompare(emailB.toLocaleString());
    },
    sortUndefined: 1,
    filterFn: (row, _id, value: string) => {
      const emails = row.original.contactEmails.map((email) => email.emailAddress);
      return intersection(emails, value).length > 0;
    },
    header: renderHeader,
    cell: (props) => {
      const totalEmails = props.getValue();
      const { primaryEmail, additionalEmails } = formatEmails(totalEmails);
      return (
        <ContactItemViewer
          primaryValue={primaryEmail}
          additionalValues={additionalEmails?.map((email) => (email?.emailAddress ?? '') as string)}
        />
      );
    },
  }),
  columnHelper.accessor((row) => row?.contactAddresses?.[0]?.streetAddress, {
    id: 'Street Address',
    sortUndefined: 1,
    header: renderHeader,
    filterFn: stringIncludesFilterFn,
    cell: (props) => renderCell({ props }),
  }),
  columnHelper.accessor((row) => row?.contactAddresses?.[0]?.city, {
    id: 'City',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  }),
  columnHelper.accessor((row) => row.contactAddresses?.[0]?.county, {
    id: 'County',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  }),
  columnHelper.accessor((row) => row.contactAddresses?.[0]?.state, {
    id: 'State',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  }),
  columnHelper.accessor((row) => row.contactAddresses?.[0]?.zipCode, {
    id: 'Zip Code',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  }),
  columnHelper.accessor((row) => prettifyDateString(row.dateCreated), {
    id: 'Date Created',
    sortUndefined: -1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    filterFn: dateRangeFilterFn<ContactViewModelType>,
    cell: (props) => renderCell({ props }),
  }),
  columnHelper.accessor((row) => row.contactType, {
    id: 'Contact Type',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: startCase }),
  }),
  columnHelper.accessor((row) => row.referralSource, {
    id: 'Referral Source',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  }),
  columnHelper.accessor((row) => row.contactTags, {
    id: 'Tags',
    sortUndefined: 1,
    filterFn: (row, id, value: string) => {
      const tagList = row.getValue<ItemTagConnectionType[]>(id) ?? [];
      const tagTitles = tagList.map((tag) => tag?.tagAttributes?.title).join(', ');
      return tagTitles.includes(value);
    },
    header: renderHeader,
    cell: (props) => {
      const tagList = props.getValue<ItemTagConnectionType[]>() ?? [];

      const tagTitles = tagList.map((tag) => tag?.tagAttributes?.title).join(', ');

      return renderNumberCellWithHover({
        value: `${tagList.length || '-'}`,
        tooltipText: tagTitles,
      });
    },
  }),
  columnHelper.accessor(
    (row) =>
      row.languages
        ?.map((language) => {
          const splitLang = language
            .split(/(?=[A-Z])/)
            .map((word, index) => (index === 0 ? word : ` ${word}`))
            .join('');

          return splitLang.charAt(0).toUpperCase() + splitLang.slice(1);
        })
        .join(', ') ?? '-',
    {
      id: 'Languages',
      sortUndefined: 1,
      header: renderHeader,
      cell: (props) => renderCell({ props }),
      filterFn: stringIncludesFilterFn,
    },
  ),
];
