import {
  tanstackTableNames,
  useCreateMedicalTreatmentType,
  useDeleteMedicalTreatmentType,
  useGetMedicalTreatmentTypes,
  useUpdateMedicalTreatmentType,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  Form,
  Slideover,
  TextFormInput,
  getInitialVisibilityForTable,
} from '@colosseum/shared-ui';
import { MedicalTreatmentTypeType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Row } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { columns } from './MedicalTreatmentTypesTableColumns.tsx';

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
});

export function MedicalTreatmentTypes() {
  const {
    data: medicalTreatmentTypesData,
    isLoading: isMedicalTreatmentTypesLoading,
    isError: isMedicalTreatmentTypesError,
  } = useGetMedicalTreatmentTypes();
  const createMedicalTreatmentType = useCreateMedicalTreatmentType();
  const updateMedicalTreatmentType = useUpdateMedicalTreatmentType();
  const deleteMedicalTreatmentType = useDeleteMedicalTreatmentType();
  const medicalTreatmentTypes = medicalTreatmentTypesData?.data;
  const typing = createMedicalTreatmentType.isLoading || updateMedicalTreatmentType.isLoading;
  const [openMedicalTreatmentType, setOpenMedicalTreatmentType] = useState<
    MedicalTreatmentTypeType | undefined
  >();
  const [open, setOpen] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: {
      title: openMedicalTreatmentType?.title || '',
      description: openMedicalTreatmentType?.description || '',
    },
  });

  const handleCreate = () => {
    createMedicalTreatmentType.mutateAsync({}).then((res) => {
      setOpenMedicalTreatmentType(res.data);
      setOpen(true);
    });
  };

  const handleUpdate = (key: string, value: string) => {
    updateMedicalTreatmentType.mutate({
      medicalTreatmentTypeId: openMedicalTreatmentType?.medicalTreatmentTypeId ?? '',
      [key]: value,
    });
  };

  useEffect(() => {
    if (!open) {
      form.reset();
      setOpenMedicalTreatmentType(undefined);
    }
  }, [open, form]);

  return (
    <>
      <Slideover
        displayDeleteButton={true}
        open={open}
        setOpen={setOpen}
        deleteFunction={() => {
          if (openMedicalTreatmentType?.medicalTreatmentTypeId)
            deleteMedicalTreatmentType
              .mutateAsync(openMedicalTreatmentType?.medicalTreatmentTypeId)
              .then(() => {
                setOpenMedicalTreatmentType(undefined);
                setOpen(false);
              });
        }}
        title="Medical Treatment Types"
        description="Update your Medical Treatment type information here."
        typing={typing}
      >
        <Form {...form}>
          <form>
            <div className="flex flex-col mb-2 gap-x-3 gap-y-5">
              <TextFormInput
                {...form.register(`title`)}
                title="Title"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('title', target.value);
                }}
              />
              <TextFormInput
                {...form.register(`description`)}
                title="Description"
                type="textarea"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('description', target.value);
                }}
              />
            </div>
          </form>
        </Form>
      </Slideover>
      <div className="" data-settings-submenu-item="Medical Treatment Types">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 id="medical-treatment-types" className="text-xl font-semibold text-gray-900">
              Medical Treatment Types
            </h1>
            <p className="mt-2 text-sm text-gray-700">{`Your firm's medical treatment types.`}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              title={'Create new type'}
              loading={createMedicalTreatmentType.isLoading}
              onClick={handleCreate}
            />
          </div>
        </div>
        <DataTable
          data={medicalTreatmentTypes || []}
          initialSort={{
            id: 'Title',
            desc: false,
          }}
          columns={columns}
          isLoading={isMedicalTreatmentTypesLoading}
          isError={isMedicalTreatmentTypesError}
          tableName={tanstackTableNames.medicalTreatmentTypes}
          handleRowClick={(item: Row<MedicalTreatmentTypeType>) => {
            setOpenMedicalTreatmentType(item.original);
            setOpen(true);
          }}
          initialVisibility={getInitialVisibilityForTable(
            tanstackTableNames.medicalTreatmentTypes,
            {},
          )}
          hideViewButton
        />
      </div>
    </>
  );
}

export default MedicalTreatmentTypes;
