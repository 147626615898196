import { FileResourceType } from '@gladiate/types';
import { FC } from 'react';
import { FileExplorerProvider } from './FileExplorerProvider';
import { FileExplorerRoot } from './FileExplorerRoot';

export interface FileExplorerProps {
  caseId: string;
  folderSelectDisabled?: boolean;
  onSelectedFilesChange?: (files: FileResourceType[]) => void;
  listViewOnly?: boolean;
  collapsibleHidden?: boolean;
  buttonsState?: {
    addContent: boolean;
    createFolder: boolean;
    rename: boolean;
    move: boolean;
    download: boolean;
    delete: boolean;
    generateDocument: boolean;
    sendForSignature: boolean;
  };
  singleSelectMode?: boolean;
  targetFolderId?: string;
}

export const FileExplorer: FC<FileExplorerProps> = (props) => (
  <FileExplorerProvider caseId={props.caseId}>
    <FileExplorerRoot {...props} />
  </FileExplorerProvider>
);
