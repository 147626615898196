import { displayContactName, formatPrice } from '@colosseum/data';
import { renderCell, renderHeader } from '@colosseum/shared-ui';
import { ContactType, PolicyType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';

export const policyColumns: ColumnDef<
  (PolicyType & { insurer: ContactType | undefined }) | undefined
>[] = [
  {
    accessorFn: (row) => {
      return displayContactName(row?.insurer);
    },
    id: 'Insurer',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      return row?.policyNumber;
    },
    id: 'Policy Number',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      return row?.claimNumber;
    },
    id: 'Claim Number',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      return row?.type;
    },
    id: 'Type',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      const perPerson =
        typeof row?.bodilyInjuryLiabilityPerPerson === 'number'
          ? formatPrice(row?.bodilyInjuryLiabilityPerPerson)
          : '-';
      const perAccident =
        typeof row?.bodilyInjuryLiabilityPerAccident === 'number'
          ? formatPrice(row?.bodilyInjuryLiabilityPerAccident)
          : '-';
      return `${perPerson} / ${perAccident}`;
    },
    id: 'Bodily Injury',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      const perPerson =
        typeof row?.liabilityCoveragePerPerson === 'number'
          ? formatPrice(row?.liabilityCoveragePerPerson)
          : '-';
      const perAccident =
        typeof row?.liabilityCoveragePerAccident === 'number'
          ? formatPrice(row?.liabilityCoveragePerAccident)
          : '-';
      return `${perPerson} / ${perAccident}`;
    },
    id: 'Liability',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      const perPerson =
        typeof row?.umUimCoverageAmountPerPerson === 'number'
          ? formatPrice(row?.umUimCoverageAmountPerPerson)
          : '-';
      const perAccident =
        typeof row?.umUimCoverageAmountPerAccident === 'number'
          ? formatPrice(row?.umUimCoverageAmountPerAccident)
          : '-';
      return `${perPerson} / ${perAccident}`;
    },
    id: 'UM / UIM',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => row?.personalInjuryProtectionAmount,
    id: 'PIP',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props, valueModifierFunc: formatPrice });
    },
  },
  {
    accessorFn: (row) => row?.propertyDamageLiability,
    id: 'Property',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props, valueModifierFunc: formatPrice });
    },
  },
  {
    accessorFn: (row) => row?.medicalPaymentCoverageAmount,
    id: 'MedPay',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props, valueModifierFunc: formatPrice });
    },
  },
  {
    accessorFn: (row) => row?.umbrellaPolicy,
    id: 'Umbrella',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props, valueModifierFunc: formatPrice });
    },
  },
];
