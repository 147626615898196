import { PolicyType } from '@gladiate/types';
import { formatPrice } from './priceUtils';

const truncatePersonAccidentValues = (perPerson?: number, perAccident?: number) => {
  const isPerPersonNumber = typeof perPerson === 'number';
  const isPerAccidentNumber = typeof perAccident === 'number';
  if (isPerPersonNumber || isPerAccidentNumber) {
    return `${isPerPersonNumber ? formatPrice(perPerson, true) : '-'} / ${
      isPerAccidentNumber ? formatPrice(perAccident, true) : '-'
    }`;
  }
  return undefined;
};

export const getPolicyValueLines = (policy?: PolicyType) => [
  {
    title: 'BI Liability',
    value: truncatePersonAccidentValues(
      policy?.bodilyInjuryLiabilityPerPerson,
      policy?.bodilyInjuryLiabilityPerAccident,
    ),
  },
  {
    title: 'Property Liability',
    value:
      typeof policy?.propertyDamageLiability === 'number'
        ? formatPrice(policy.propertyDamageLiability, true)
        : undefined,
  },

  {
    title: 'PIP',
    value:
      typeof policy?.personalInjuryProtectionAmount === 'number'
        ? formatPrice(policy.personalInjuryProtectionAmount, true)
        : undefined,
  },
  {
    title: 'MedPay',
    value:
      typeof policy?.medicalPaymentCoverageAmount === 'number'
        ? formatPrice(policy.medicalPaymentCoverageAmount, true)
        : undefined,
  },
  {
    title: 'UM/UIM',
    value: truncatePersonAccidentValues(
      policy?.umUimCoverageAmountPerPerson,
      policy?.umUimCoverageAmountPerAccident,
    ),
  },
  {
    title: 'UM/UIM PD',
    value:
      typeof policy?.umUimMotoristPropertyDamage === 'number'
        ? formatPrice(policy.umUimMotoristPropertyDamage, true)
        : undefined,
  },
  {
    title: 'Ded',
    value:
      typeof policy?.deductibleAmount === 'number'
        ? formatPrice(policy.deductibleAmount, true)
        : undefined,
  },
  {
    title: 'Rental',
    value:
      typeof policy?.rentalReimbursement === 'number'
        ? formatPrice(policy.rentalReimbursement, true)
        : undefined,
  },
  {
    title: 'Liability',
    value: truncatePersonAccidentValues(
      policy?.liabilityCoveragePerPerson,
      policy?.liabilityCoveragePerAccident,
    ),
  },
  {
    title: 'Umbrella',
    value:
      typeof policy?.umbrellaPolicy === 'number'
        ? formatPrice(policy.umbrellaPolicy, true)
        : undefined,
  },
];
