import { cn, settingsSectionMappings } from '@colosseum/data';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  tabTriggerClass,
  tabsListClass,
} from '@colosseum/shared-ui';
import CaseStatuses from '../CaseStatuses/CaseStatuses';
import CaseTypes from '../CaseTypes/CaseTypes';
import ReferralSources from '../ReferralSources/ReferralSources';
import StaffRoles from '../StaffRoles/StaffRoles';

/* eslint-disable-next-line */
export interface FirmStatusesTypesProps {}

export function FirmStatusesTypes(props: FirmStatusesTypesProps) {
  return (
    <Tabs defaultValue="types">
      <h1 className="pb-4 pl-2 text-xl font-semibold text-gray-900">Case Defaults</h1>
      <TabsList
        id="firm-settings-types-statuses"
        data-settings-submenu-item={settingsSectionMappings.caseDefaults}
        className={cn(tabsListClass, 'mb-4')}
      >
        <TabsTrigger
          className={tabTriggerClass}
          key="types"
          value="types"
          data-cy="firm-settings-types"
        >
          Types
        </TabsTrigger>
        <TabsTrigger
          className={tabTriggerClass}
          key="statuses"
          value="statuses"
          data-cy="firm-settings-statuses"
        >
          Statuses
        </TabsTrigger>
        <TabsTrigger
          className={tabTriggerClass}
          key="roles"
          value="roles"
          data-cy="firm-settings-roles"
        >
          Staff Roles
        </TabsTrigger>
        <TabsTrigger
          className={tabTriggerClass}
          key="referral-sources"
          value="referral-sources"
          data-cy="firm-settings-roles"
        >
          Referral Sources
        </TabsTrigger>
      </TabsList>
      <TabsContent value="types">
        <CaseTypes />
      </TabsContent>
      <TabsContent value="statuses">
        <CaseStatuses />
      </TabsContent>
      <TabsContent value="roles">
        <StaffRoles />
      </TabsContent>
      <TabsContent value="referral-sources">
        <ReferralSources />
      </TabsContent>
    </Tabs>
  );
}

export default FirmStatusesTypes;
