import {
  cn,
  useDeleteResourceAttachment,
  useGetParallelFiles,
  useGetParallelS3FileDownloadLinks,
  useGetResourceAttachments,
} from '@colosseum/data';
import { FileResourceType } from '@gladiate/types';
import { useState } from 'react';
import AttachedFileChip from '../AttachedFileChip/AttachedFileChip';
import FilePreview from '../FilePreview/FilePreview';
import GladiateLoader from '../GladiateLoader/GladiateLoader';

/* eslint-disable-next-line */
export interface ResourceFileAttachmentsProps {
  caseId?: string;
  resourceId: string;
  search?: string;
  restrictDelete?: boolean;
  attachmentsContainerClassNames?: string;
  inSlideover?: boolean;
  loading?: boolean;
}

export function ResourceFileAttachments(props: ResourceFileAttachmentsProps) {
  const {
    caseId,
    resourceId,
    search,
    restrictDelete,
    attachmentsContainerClassNames,
    inSlideover,
    loading,
  } = props;

  const [showFilePreview, setShowFilePreview] = useState(false);
  const [fileToPreview, setFileToPreview] = useState<FileResourceType>();

  const deleteAttachment = useDeleteResourceAttachment();

  const { data: resourceAttachmentsData, isLoading: resourceAttachmentsLoading } =
    useGetResourceAttachments(resourceId);
  const resourceAttachments = resourceAttachmentsData?.data?.data;

  const mappedResourceAttachments = resourceAttachments?.map(
    (attachment) => `${attachment?.resource?.parentResourceId}-${attachment?.resource?.resourceId}`,
  );

  const attachmentFileQueries = useGetParallelFiles(mappedResourceAttachments ?? []);
  const attachmentData = attachmentFileQueries?.map((query) => query?.data?.data);
  const mappedS3ObjKeys = attachmentData?.map((file) => {
    return file?.s3ObjKey ?? '';
  });
  const downloadLinkUrlQueries = useGetParallelS3FileDownloadLinks(mappedS3ObjKeys ?? []);
  const mappedDownloadLinks = downloadLinkUrlQueries.map((query) => query?.data?.data);

  const filteredResourceAttachments = search
    ? resourceAttachments?.filter((attachment) =>
        attachment?.resource?.name?.toLowerCase().includes(search.toLowerCase()),
      )
    : resourceAttachments;

  if (!filteredResourceAttachments || resourceAttachmentsLoading) {
    return <div className="pb-2 mt-2"></div>;
  }

  return (
    <>
      <FilePreview
        caseId={caseId}
        isOpen={showFilePreview}
        setOpen={setShowFilePreview}
        filePreviewItem={fileToPreview}
      />
      <div
        className={cn(
          attachmentsContainerClassNames
            ? attachmentsContainerClassNames
            : 'flex flex-wrap items-center w-full gap-1.5 pb-2 mt-2',
        )}
      >
        {filteredResourceAttachments.length === 0 && inSlideover && (
          <div className="text-sm text-gray-500">No attachments</div>
        )}
        {filteredResourceAttachments.map((attachment) => {
          const downloadLink = mappedDownloadLinks?.find((link) => {
            return link?.objKey === attachment?.resource?.s3ObjKey;
          });

          return (
            <AttachedFileChip
              key={attachment.attachmentId}
              file={attachment.resource}
              downloadLink={downloadLink?.url ?? downloadLink?.previewUrl ?? ''}
              onPreview={() => {
                setFileToPreview(attachment.resource);
                setShowFilePreview(true);
              }}
              onDelete={() => {
                if (!attachment.attachmentId) return;

                deleteAttachment.mutate({
                  attachmentId: attachment.attachmentId,
                  resourceId,
                });
              }}
              deletable={!restrictDelete}
            />
          );
        })}
        {loading && (
          <div>
            <GladiateLoader />
          </div>
        )}
      </div>
    </>
  );
}

export default ResourceFileAttachments;
