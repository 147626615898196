import {
  casesSectionMappings,
  useCompleteMedicalTreatmentValues,
  useGetAttorneyFeesForCase,
  useGetCaseExpensesWithCategories,
  useGetMedicalTreatments,
  useUpdateCaseData,
} from '@colosseum/data';
import { Slideover } from '@colosseum/shared-ui';
import { CaseType } from '@gladiate/types';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import CaseSettings from '../../case/CaseSettings/CaseSettings';
import CloseExpenseForm from '../CaseCloseForms/CloseExpenseForm/CloseExpenseForm';
import CloseOutstandingBillsForm from '../CaseCloseForms/CloseOutstandingBillsForm/CloseOutstandingBillsForm';

/* eslint-disable-next-line */
export interface CaseCloseProps {
  caseId: string;
  activeCaseQuery: CaseType | undefined;
}

export function CaseClose(props: CaseCloseProps) {
  const { caseId, activeCaseQuery } = props;
  const [slideoverType, setSlideoverType] = useState<
    'expense' | 'outstandingBills' | 'fees' | 'liens'
  >('expense');
  const [slideoverOpen, setSlideoverOpen] = useState(false);

  const updateCaseMutation = useUpdateCaseData();
  const { data: expensesWithCategories } = useGetCaseExpensesWithCategories(caseId);
  const { data: medicalTreatmentQuery } = useGetMedicalTreatments(caseId);
  const { getCompleteMedicalTreatmentValues } = useCompleteMedicalTreatmentValues(caseId);
  const { completeTreatments, ...aggregateValues } = getCompleteMedicalTreatmentValues(
    medicalTreatmentQuery?.data || [],
  );

  const { data: feeAgreementsData } = useGetAttorneyFeesForCase(caseId);
  const feeAgreements = feeAgreementsData?.data ?? [];

  // Calculations and Data
  const activeCaseData = activeCaseQuery;
  const expenseTotal = expensesWithCategories?.meta?.caseExpenseTotal || 0;
  const outstandingBills = aggregateValues?.aggregateNetBillAfterAdjustments || 0;
  const aggregateLiens = 0; // TODO: FIX
  const activeFeeAgreement = feeAgreements?.find((feeAgreement) => {
    return feeAgreement?.currentlyApplicable;
  });
  const activeFeePercentage = activeFeeAgreement?.feePercentage ?? 0;
  const attorneyFee = () => {
    if (!activeCaseData?.totalAmountRecovered) {
      return '$0.00';
    }

    const totalAmountRecovered = activeCaseData?.totalAmountRecovered;

    const attorneyFee =
      activeFeePercentage === 33.3
        ? totalAmountRecovered / 3
        : (totalAmountRecovered * activeFeePercentage) / 100;

    const attorneyFeeFormatted = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(attorneyFee);

    if (attorneyFeeFormatted === '$NaN' || attorneyFeeFormatted === undefined) {
      return '$0.00';
    }

    return attorneyFeeFormatted;
  };
  const netProceedsToClient = () => {
    if (!activeCaseData?.totalAmountRecovered) {
      return '$0.00';
    }

    const totalAmountRecovered = activeCaseData?.totalAmountRecovered;

    const netProceedsToClient =
      totalAmountRecovered -
      expenseTotal -
      outstandingBills -
      parseFloat(attorneyFee().replace(/[^0-9.-]+/g, ''));

    const netProceedsToClientFormatted = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(netProceedsToClient);

    if (netProceedsToClientFormatted === '$NaN' || netProceedsToClientFormatted === undefined) {
      return '$0.00';
    }

    return netProceedsToClientFormatted;
  };

  function updateTotalAmountRecovered(amount: number) {
    updateCaseMutation.mutate({
      caseId: caseId,
      totalAmountRecovered: amount,
    });
  }

  function updateProjectedSettlementAmount(amount: number) {
    updateCaseMutation.mutate({
      caseId: caseId,
      projectedSettlementAmount: amount,
    });
  }

  const getSlideOverDetails = () => {
    //
    switch (slideoverType) {
      case 'expense':
        return {
          title: 'Expenses',
          description:
            'View total case expenses logged organized by expense category. Confirm how expenses will be reflected in closing and disbursement statements.',
          children: (
            <CloseExpenseForm
              expenses={expensesWithCategories?.data || []}
              expenseTotal={expenseTotal}
            />
          ),
          displayDeleteButton: false,
          typing: false,
        };
      case 'outstandingBills':
        return {
          title: 'Medical Bills',
          description:
            'View total medical bills logged organized by medical billing entity. Confirm how remaining medical bills will be reflected in closing and disbursement statements.',
          children: (
            <CloseOutstandingBillsForm
              aggregatePresentBalance={aggregateValues?.aggregatePresentBalance || 0}
              aggregateReductions={aggregateValues.aggregateReductions || 0}
              aggregateUnpaidBalance={aggregateValues.aggregateUnpaidBalance || 0}
              medicalTreatments={completeTreatments || []}
            />
          ),
          displayDeleteButton: false,
          typing: false,
        };
      case 'fees':
        return {
          title: 'Settings',
          description: '',
          children: <CaseSettings caseId={caseId} feeAgreements={feeAgreements} />,
          displayDeleteButton: false,
          typing: false,
        };
      case 'liens':
        return {
          title: 'Liens',
          description:
            'View total liens logged organized by lien holder. Confirm how liens will be reflected in closing and disbursement statements.',
          children: (
            // <CloseLiensForm
            //   aggregateHealthInsurancePayments={
            //     medicalTreatmentQuery?.data?.aggregateHealthInsurancePayments ||
            //     0
            //   }
            //   aggregateMedPayPayment={
            //     medicalTreatmentQuery?.data?.aggregateMedPayPayment || 0
            //   }
            //   aggregateLiens={medicalTreatmentQuery?.data?.aggregateLiens || 0}
            //   medicalTreatments={
            //     medicalTreatmentQuery?.data?.medicalTreatments || []
            //   }
            // />
            <div></div>
          ),
          displayDeleteButton: false,
          typing: false,
        };
      default:
        return {
          title: '',
          description: '',
          children: <div>An error has occurred please return to the case and try again.</div>,
          displayDeleteButton: false,
          typing: false,
        };
    }
  };

  return (
    <React.Fragment>
      <Slideover
        title={getSlideOverDetails().title}
        description={getSlideOverDetails().description}
        open={slideoverOpen}
        setOpen={setSlideoverOpen}
        displayDeleteButton={false}
        typing={false}
      >
        {getSlideOverDetails().children}
      </Slideover>
      <div className="w-full">
        <div className="flex items-center justify-between pb-5 align-middle">
          <div className="flex items-center justify-between w-full">
            <h1
              data-case-submenu-item={casesSectionMappings.closing}
              className="text-xl font-semibold"
            >
              {casesSectionMappings.closing}
            </h1>
          </div>
        </div>
        <div className="flex flex-col -mx-4 sm:-mx-6 md:mx-0">
          <table className="min-w-full divide-y divide-gray-300">
            <tbody className="">
              <tr className="border-b border-gray-200">
                <td className="pb-2 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                  <div className="font-medium text-gray-900">Projected Settlement Amount</div>
                </td>
                <td className="pb-2 pl-3 pr-4 text-sm text-right sm:pr-6 md:pr-0 ">
                  <div className="flex items-center float-right border border-gray-300 rounded-md py-0.5 px-1 -mr-1 text-right">
                    <span className="mr-2 text-sm text-gray-500">$</span>
                    <NumericFormat
                      className="w-24 text-right focus:outline-none"
                      decimalScale={2}
                      thousandSeparator=","
                      onBlur={(e) => {
                        updateProjectedSettlementAmount(
                          parseFloat(e.target.value.replace(/,/g, '')),
                        );
                      }}
                      value={activeCaseData?.projectedSettlementAmount || 0}
                      placeholder="0.00"
                      maxLength={17}
                    />
                  </div>
                </td>
              </tr>
              <tr className="">
                <td className="pt-2 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                  <div className="font-medium text-gray-900">Total Amount Recovered</div>
                </td>

                <td className="pt-2 pl-3 pr-4 text-sm text-right sm:pr-6 md:pr-0 ">
                  <div className="flex items-center float-right border border-gray-300 rounded-md py-0.5 px-1 -mr-1 text-right">
                    <span className="mr-2 text-sm text-gray-500">$</span>
                    <NumericFormat
                      className="w-24 text-right focus:outline-none"
                      decimalScale={2}
                      thousandSeparator=","
                      onBlur={(e) => {
                        updateTotalAmountRecovered(parseFloat(e.target.value.replace(/,/g, '')));
                      }}
                      value={activeCaseData?.totalAmountRecovered || 0}
                      placeholder="0.00"
                      maxLength={17}
                    />
                  </div>
                </td>
              </tr>
              <tr className="">
                <td className="py-1 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                  <div className="font-medium text-gray-900">Attorneys Fees</div>
                </td>

                <td className="py-1 pl-3 pr-4 text-sm text-right text-gray-500 sm:pr-6 md:pr-0">
                  <div className="flex items-center float-right -mr-5">
                    <div className="pr-3 text-black">
                      {'('}
                      {activeFeePercentage === 33.3 ? (
                        <div className="inline">33 &#8531;</div>
                      ) : (
                        activeFeePercentage
                      )}
                      {' %)'}
                    </div>
                    {attorneyFee()}
                    <button
                      onClick={() => {
                        setSlideoverType('fees');
                        setSlideoverOpen(true);
                      }}
                      className="ml-1 cursor-pointer hover:animate-pulse"
                    >
                      <EllipsisVerticalIcon className="w-4 h-4 text-black " />
                    </button>
                  </div>
                </td>
              </tr>
              <tr className="">
                <td className="py-1 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                  <div className="font-medium text-gray-900">Expenses</div>
                </td>

                <td className="py-1 pl-3 pr-4 text-sm text-right text-gray-500 sm:pr-6 md:pr-0">
                  <div className="flex items-center float-right -mr-5">
                    {expenseTotal.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }) || '$0.00'}
                    <button
                      onClick={() => {
                        setSlideoverType('expense');
                        setSlideoverOpen(true);
                      }}
                      className="ml-1 cursor-pointer hover:animate-pulse"
                    >
                      <EllipsisVerticalIcon className="w-4 h-4 text-black " />
                    </button>
                  </div>
                </td>
              </tr>
              <tr className="">
                <td className="py-1 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                  <div className="font-medium text-gray-900">Outstanding bills</div>
                </td>

                <td className="py-1 pl-3 pr-4 text-sm text-right text-gray-500 sm:pr-6 md:pr-0">
                  <div className="flex items-center float-right -mr-5">
                    {outstandingBills.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }) || '$0.00'}
                    <button
                      onClick={() => {
                        setSlideoverType('outstandingBills');
                        setSlideoverOpen(true);
                      }}
                      className="ml-1 cursor-pointer hover:animate-pulse"
                    >
                      <EllipsisVerticalIcon className="w-4 h-4 text-black " />
                    </button>
                  </div>
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <td className="pt-1 pb-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                  <div className="font-medium text-gray-900">MedPay & Health Insurance Liens</div>
                </td>

                <td className="pt-1 pb-4 pl-3 pr-4 text-sm text-right text-gray-500 sm:pr-6 md:pr-0">
                  <div className="flex items-center float-right -mr-5">
                    {aggregateLiens.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }) || '$0.00'}
                    <button
                      onClick={() => {
                        setSlideoverType('liens');
                        setSlideoverOpen(true);
                      }}
                      className="ml-1 cursor-pointer hover:animate-pulse"
                    >
                      <EllipsisVerticalIcon className="w-4 h-4 text-black " />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th
                  scope="row"
                  className="pt-4 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 md:pl-0"
                >
                  Net Proceeds to Client
                </th>
                <td className="pt-4 pl-3 pr-4 text-sm font-semibold text-right text-gray-900 sm:pr-6 md:pr-0">
                  {netProceedsToClient() || '$0.00'}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CaseClose;
