import {
  tanstackTableNames,
  useCreateExpenseCategory,
  useDeleteExpenseCategory,
  useGetExpenseCategories,
  useUpdateExpenseCategory,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  Form,
  Slideover,
  TextFormInput,
  getInitialVisibilityForTable,
} from '@colosseum/shared-ui';
import { ExpenseCategoryType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Row } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { columns } from './ExpenseCategoryDefaultsTableColumns';

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
});

export function ExpenseCategoryDefaults() {
  const {
    data: expenseCategoriesData,
    isLoading: isExpenseCategoriesLoading,
    isError: isExpenseCategoriesError,
  } = useGetExpenseCategories(true);
  const createExpenseCategory = useCreateExpenseCategory();
  const updateExpenseCategory = useUpdateExpenseCategory();
  const deleteExpenseCategory = useDeleteExpenseCategory();
  const expenseCategories = expenseCategoriesData?.data;
  const typing = createExpenseCategory.isLoading || updateExpenseCategory.isLoading;
  const [openExpenseCategory, setOpenExpenseCategory] = useState<ExpenseCategoryType | undefined>();
  const [open, setOpen] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: {
      title: openExpenseCategory?.title || '',
      description: openExpenseCategory?.description || '',
    },
  });

  const handleCreate = () => {
    createExpenseCategory.mutateAsync({}).then((res) => {
      setOpenExpenseCategory(res.data);
      setOpen(true);
    });
  };

  const handleUpdate = (key: string, value: string) => {
    updateExpenseCategory.mutate({
      caseExpenseCategoryId: openExpenseCategory?.caseExpenseCategoryId ?? '',
      [key]: value,
    });
  };

  useEffect(() => {
    if (!open) {
      form.reset();
      setOpenExpenseCategory(undefined);
    }
  }, [open, form]);

  return (
    <>
      <Slideover
        displayDeleteButton={true}
        open={open}
        setOpen={setOpen}
        deleteFunction={() => {
          if (openExpenseCategory?.caseExpenseCategoryId)
            deleteExpenseCategory
              .mutateAsync(openExpenseCategory?.caseExpenseCategoryId)
              .then(() => {
                setOpenExpenseCategory(undefined);
                setOpen(false);
              });
        }}
        title="Expense Category"
        description="Update your Expense category information here."
        typing={typing}
      >
        <Form {...form}>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="flex flex-col mb-2 gap-x-3 gap-y-5">
              <TextFormInput
                {...form.register(`title`)}
                title="Title"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('title', target.value);
                }}
              />
              <TextFormInput
                {...form.register(`description`)}
                title="Description"
                type="textarea"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('description', target.value);
                }}
              />
            </div>
          </form>
        </Form>
      </Slideover>
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 id="expense-categories" className="text-xl font-semibold text-gray-900">
              Categories
            </h1>
            <p className="mt-2 text-sm text-gray-700">{`Your firm's expense categories.`}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              title={'Create new category'}
              loading={createExpenseCategory.isLoading}
              onClick={handleCreate}
            />
          </div>
        </div>
        <DataTable
          data={expenseCategories || []}
          initialSort={{
            id: 'Title',
            desc: false,
          }}
          columns={columns}
          isLoading={isExpenseCategoriesLoading}
          isError={isExpenseCategoriesError}
          tableName={tanstackTableNames.expenseCategories}
          handleRowClick={(item: Row<ExpenseCategoryType>) => {
            setOpenExpenseCategory(item.original);
            setOpen(true);
          }}
          initialVisibility={getInitialVisibilityForTable(tanstackTableNames.expenseCategories, {})}
          hideViewButton
        />
      </div>
    </>
  );
}

export default ExpenseCategoryDefaults;
