import {
  bitBToB,
  cn,
  registerChangeEvents,
  useCreateCalendarEvent,
  useGetCase,
  useGetEventsForCalendar,
  useGetPrimaryFirmCalendar,
  useUpdateCalendarEvent,
} from '@colosseum/data';
import { NylasEvent, NylasEventPayload, NylasMetadataType } from '@gladiate/types';
import { ArrowPathIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import { format, parseISO } from 'date-fns';
import dayjs from 'dayjs';
import kebabCase from 'lodash/kebabCase';
import { CalendarIcon } from 'lucide-react';
import { FormEventHandler, forwardRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import GladiateLoader from '../../GladiateLoader/GladiateLoader';
import TooltipWrapper from '../../data-display/TooltipWrapper/TooltipWrapper';
import { Button } from '../../shadcn/Button/Button';
import { CalendarPicker, CalendarPickerProps } from '../../shadcn/CalendarPicker/CalendarPicker';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../shadcn/Form/Form';
import { Popover, PopoverContent, PopoverTrigger } from '../../shadcn/Popover/Popover';

/* eslint-disable-next-line */
export interface CalendarFormInputProps {
  handleOnChange?: FormEventHandler<HTMLButtonElement>;
  name: string;
  title: string;
  disabled?: boolean | ((date: Date) => boolean);
  resourceTypeObj?: {
    id: string | undefined;
    type: NylasMetadataType;
  };
  hideSyncButton?: boolean;
  additionalCalendarProps?: CalendarPickerProps;
}

export const CalendarFormInput = forwardRef<HTMLInputElement, CalendarFormInputProps>(
  (props, ref) => {
    const { caseId } = useParams() as { caseId: string };

    const {
      handleOnChange,
      name,
      title,
      disabled,
      resourceTypeObj,
      hideSyncButton,
      additionalCalendarProps,
    } = props;

    // This is needed to make the form value dirty
    const handleOnChangeForm = (e: React.FormEvent<HTMLButtonElement>) => {
      const target = e.target as HTMLInputElement;
      form.setValue(name, target.value, {
        shouldDirty: true,
      });
      if (handleOnChange) {
        handleOnChange(e);
      }
    };
    const form = useFormContext();

    const [open, setOpen] = useState(false);
    const { data: caseData } = useGetCase(caseId);
    const caseTitleToAppend = caseData?.data?.caseTitle ? ` - ${caseData?.data?.caseTitle}` : '';
    const [syncedEventMismatch, setSyncedEventMismatch] = useState(false);
    const [currentDateValue, setCurrentDateValue] = useState<string>(
      dayjs(form.getValues(name)).format('YYYY-MM-DD'),
    );
    const [currentSyncedEvent, setCurrentSyncedEvent] = useState<
      NylasEvent | NylasEventPayload | null
    >();

    const { data: primaryCalendarData, isLoading: isPrimaryCalLoading } =
      useGetPrimaryFirmCalendar();
    const primaryCalendarId = primaryCalendarData?.data?.calendarId ?? '';
    const createEventMutation = useCreateCalendarEvent(primaryCalendarId);
    const updateEventMutation = useUpdateCalendarEvent(primaryCalendarId);

    const dateValue = form.getValues(name);

    const primaryCalendarDoesNotExist = !primaryCalendarData?.data;

    const { data: primaryCalendarEventsData, isLoading: isEventsLoading } = useGetEventsForCalendar(
      {
        calendarId: primaryCalendarId,
        dateRange: {
          startDate: dateValue,
          endDate: dateValue,
        },
        caseId,
        primaryCalendar: true,
      },
    );

    const eventsForDateRange = primaryCalendarEventsData?.data;

    function checkSyncedEventMismatch() {
      if (bitBToB(currentSyncedEvent?.eventMetadata?.oneDay)) {
        setSyncedEventMismatch(
          // @ts-expect-error - TS is not recognizing the oneDay property
          currentSyncedEvent?.eventWhen?.start_date !== currentDateValue,
        );
        return;
      }

      setSyncedEventMismatch(false);
      return;
    }

    useEffect(() => {
      checkSyncedEventMismatch();
    }, [currentDateValue]);

    useEffect(() => {
      const syncedEvent = eventsForDateRange?.find((event) => {
        return (
          resourceTypeObj?.id === event.eventMetadata.objectId &&
          resourceTypeObj?.type === event.eventMetadata.objectType &&
          event.eventTitle.indexOf(props.title) !== -1
        );
      });
      setCurrentSyncedEvent(syncedEvent);
    }, [eventsForDateRange]);

    const syncButtonTooltipMessage = () => {
      if (primaryCalendarDoesNotExist) {
        return 'Your firm does not have a primary calendar. Please create one in the firm settings.';
      }

      if (currentSyncedEvent) {
        return syncedEventMismatch
          ? 'This date is different than the date on your firms calendar. Please click the refresh button to update the date on your firms calendar.'
          : 'This date is already on your firms calendar.';
      }

      return dateValue
        ? 'Add this date to your firms calendar.'
        : 'Pick a date to add to your firms calendar.';
    };

    const syncButtonDisabled = (field: { value: string | Date | undefined }) => {
      if (primaryCalendarDoesNotExist) return true;
      if (currentSyncedEvent === undefined && field.value) return false;
      return !syncedEventMismatch;
    };

    return (
      <div className="flex items-center w-full gap-x-2">
        <FormField
          control={form.control}
          name={name}
          render={({ field }) => {
            const dateValue =
              field.value && typeof field.value === 'string'
                ? parseISO(field.value)
                : field.value
                ? field.value
                : null;
            return (
              <FormItem className="flex flex-col grow">
                <FormLabel>{title}</FormLabel>
                <span className="flex h-10 gap-x-2">
                  <Popover
                    modal
                    open={open}
                    onOpenChange={(e) => {
                      setOpen(e);
                    }}
                  >
                    <PopoverTrigger
                      disabled={disabled === true}
                      asChild
                      data-cy={`calendar-input-${kebabCase(name) as string}`}
                      onClick={() => setOpen(!open)}
                    >
                      <FormControl ref={ref} className="border border-gray-300">
                        <Button
                          {...registerChangeEvents({
                            field,
                            form,
                            handleOnChange: handleOnChangeForm,
                          })}
                          variant={'outline'}
                          className={cn(
                            'w-full pl-3 text-left font-normal',
                            !field.value && 'text-muted-foreground',
                          )}
                        >
                          {dateValue && dayjs(dateValue).isValid() ? (
                            format(dateValue, 'PPP')
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="z-50 w-auto p-0 bg-white" align="start">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          const input = document.getElementById('date-input') as HTMLInputElement;
                          const inputValue = input.value;
                          if (dayjs(inputValue).isValid()) {
                            field.onChange(dayjs(inputValue).format('YYYY-MM-DD'));
                            setOpen(false);
                          }
                        }}
                      >
                        <div className="flex p-2 gap-x-1">
                          <input
                            id="date-input"
                            defaultValue={field.value && dayjs(field.value).format('YYYY-MM-DD')}
                            className={cn(
                              'w-full pl-3 text-left font-normal border rounded-md appearance-none	',
                              !field.value && 'text-muted-foreground',
                            )}
                            type="date"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          />
                          <Button type="submit" variant="outline">
                            Set
                          </Button>
                        </div>
                      </form>
                      <CalendarPicker
                        defaultMonth={dateValue}
                        disabled={(date) =>
                          (typeof disabled === 'function' && (disabled(date) as boolean)) || false
                        }
                        fixedWeeks
                        {...additionalCalendarProps} // this is on this line because everything below it, we dont want to allow overriding
                        onSelect={
                          (e) => {
                            field.onChange(dayjs(e).format('YYYY-MM-DD'));
                            setCurrentDateValue(dayjs(e).format('YYYY-MM-DD'));
                            setOpen(false);
                          } // Format date to be like '2021-09-25' for backend
                        }
                        selected={dateValue}
                        mode="single"
                      />
                      <Button
                        variant="outline"
                        className="p-2 m-2"
                        onClick={() => {
                          field.onChange('');
                          setOpen(false);
                        }}
                      >
                        Reset Date
                      </Button>
                    </PopoverContent>
                  </Popover>
                  {caseId &&
                    !hideSyncButton &&
                    ((isPrimaryCalLoading || isEventsLoading) &&
                    primaryCalendarEventsData !== undefined &&
                    !primaryCalendarDoesNotExist ? (
                      <div className="flex flex-col justify-center h-full border border-gray-300 rounded-md">
                        <GladiateLoader height={25} />
                      </div>
                    ) : (
                      <TooltipWrapper message={syncButtonTooltipMessage()}>
                        <div className="flex h-full border border-gray-300 rounded-md">
                          <button
                            disabled={syncButtonDisabled(field)}
                            onClick={(e) => {
                              e.preventDefault();
                              if (currentSyncedEvent) {
                                // Update event
                                updateEventMutation
                                  .mutateAsync({
                                    eventId: currentSyncedEvent?.eventId ?? '',
                                    title: props.title + caseTitleToAppend,
                                    when: {
                                      date: dayjs(field.value).format('YYYY-MM-DD'),
                                    },
                                  })
                                  .then((res) => {
                                    setCurrentSyncedEvent(res.data);
                                    if (
                                      // @ts-expect-error - start_date exists
                                      res.data?.eventWhen?.start_date === currentDateValue
                                    ) {
                                      setSyncedEventMismatch(false);
                                    }
                                  });
                              } else {
                                createEventMutation
                                  .mutateAsync({
                                    title: props.title + caseTitleToAppend,
                                    when: {
                                      date: dayjs(field.value).format('YYYY-MM-DD'),
                                    },
                                    metadata: resourceTypeObj
                                      ? {
                                          key1: caseId,
                                          objectType: resourceTypeObj.type,
                                          objectId: resourceTypeObj.id,
                                        }
                                      : {
                                          key1: caseId,
                                        },
                                  })
                                  .then((res) => {
                                    setCurrentSyncedEvent(res.data);
                                    setSyncedEventMismatch(false);
                                  });
                              }
                            }}
                            className={cn(
                              'px-2 py-1 text-gray-500 rounded-l-md',
                              syncButtonDisabled(field)
                                ? 'cursor-not-allowed opacity-50'
                                : 'hover:bg-gray-200  fadeAnimation',
                              !currentSyncedEvent && 'rounded-r-md',
                            )}
                          >
                            <ArrowPathIcon
                              className={cn(
                                'w-4 h-4',
                                createEventMutation.isLoading || updateEventMutation.isLoading
                                  ? 'animate-spin'
                                  : '',
                              )}
                            />
                          </button>
                          {currentSyncedEvent && (
                            <>
                              {syncedEventMismatch ? (
                                <>
                                  <div className="flex flex-col justify-center px-1 align-middle bg-yellow-100 border-l rounded-r-md">
                                    <ExclamationTriangleIcon className="w-4 h-4 text-yellow-950" />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="flex flex-col justify-center px-1 align-middle bg-green-100 border-l rounded-r-md">
                                    <CheckIcon className="w-4 h-4 text-green-950" />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {/* {syncedEvent && !syncedEventMismatch() && (
                          <div className="flex flex-col justify-center px-1 align-middle bg-green-100 border-l rounded-r-md">
                            <CheckIcon className="w-4 h-4 text-green-950" />
                          </div>
                        )}
                        {syncedEventMismatch() && (
                          <div className="flex flex-col justify-center px-1 align-middle bg-yellow-100 border-l rounded-r-md">
                            <ExclamationTriangleIcon className="w-4 h-4 text-yellow-950" />
                          </div>
                        )} */}
                        </div>
                      </TooltipWrapper>
                    ))}
                </span>
                <FormMessage className="z-0" />
              </FormItem>
            );
          }}
        />
      </div>
    );
  },
);

CalendarFormInput.displayName = 'CalendarFormInput';

export default CalendarFormInput;
