import { DefaultV3Error, ExpenseWithCategoryType } from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { queryKeys } from '../../static/queryKeys';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import {
  createCaseExpenseCategoryV3,
  createCaseExpenseV3,
  createExpenseAttachment,
  createUTBMSExpenseCode,
  deleteCaseExpenseCategoryV3,
  deleteCaseExpenseV3,
  deleteExpenseAttachment,
  deleteUTBMSExpenseCode,
  getAllCaseExpensesV3,
  getCaseExpenseCategoriesV3,
  getCaseExpensesV3,
  getExpenseAttachments,
  getUTBMSExpenseCodes,
  updateCaseExpenseCategoryV3,
  updateCaseExpenseV3,
  updateUTBMSExpenseCode,
} from '../requests/nexum';

export const useGetExpensesForCase = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.expenses, caseId],
    queryFn: async () => getCaseExpensesV3(caseId),
    enabled: !!caseId,
  });
};

export const useGetFirmExpenses = () => {
  return useQuery({
    queryKey: [queryKeys.expenses],
    queryFn: async () => getAllCaseExpensesV3(),
  });
};

export const useGetUTBMSCodes = () => {
  return useQuery({
    queryFn: async () => getUTBMSExpenseCodes(),
    queryKey: [queryKeys.UTBMSCodes],
  });
};

export const useCreateUTBMSCode = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => createUTBMSExpenseCode(),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.UTBMSCodes],
      });
    },
  });
};

export const useUpdateUTBMSCode = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateUTBMSExpenseCode>[0]) =>
      updateUTBMSExpenseCode(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.UTBMSCodes],
      });
    },
  });
};

export const useDeleteUTBMSCode = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (caseExpenseUtbmsCodeId: string) => deleteUTBMSExpenseCode(caseExpenseUtbmsCodeId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.UTBMSCodes],
      });
    },
  });
};

export const useGetCaseExpensesWithCategories = (caseId: string) => {
  const { data: expenses, isLoading: isCaseExpensesLoading } = useGetExpensesForCase(caseId);
  const { data: expenseCategories, isLoading: isExpenseCategoriesLoading } =
    useGetExpenseCategories();
  const { data: UTBMSCodesData, isLoading: isUTBMSCodesLoading } = useGetUTBMSCodes();

  const expensesWithCategories = useMemo(
    () =>
      expenses?.data?.map((expense) => {
        const category = expenseCategories?.data?.find(
          (category) => category.caseExpenseCategoryId === expense.categoryId,
        );
        const UTBMSCode = UTBMSCodesData?.data?.find(
          (code) => code.caseExpenseUtbmsCodeId === expense.utbmsCodeId,
        );
        return {
          ...expense,
          category,
          UTBMSCode,
        };
      }),
    [expenses, expenseCategories],
  );
  return {
    isLoading: isCaseExpensesLoading || isExpenseCategoriesLoading || isUTBMSCodesLoading,
    data: {
      data: expensesWithCategories as ExpenseWithCategoryType[],
      meta: expenses?.meta,
    },
  };
};

export const useCreateExpense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createCaseExpenseV3>[0]) => createCaseExpenseV3(data),
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.expenses, variables.caseId],
      });
    },
  });
};

export const useDeleteExpense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (caseExpenseId: Parameters<typeof deleteCaseExpenseV3>[0]) =>
      deleteCaseExpenseV3(caseExpenseId),
    onSettled: (data, error, caseExpenseId) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.expenses, caseExpenseId.split('-')[0]],
      });
    },
  });
};

export const useUpdateExpense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateCaseExpenseV3>[0]) => updateCaseExpenseV3(data),
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.expenses, variables.caseExpenseId.split('-')[0]],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      }
    },
  });
};

// ------------------ CASE EXPENSE CATEGORY ------------------

export const useGetExpenseCategories = (showEmptyExpenses?: boolean) => {
  return useQuery({
    queryKey: [queryKeys.expenseCategories, showEmptyExpenses],
    queryFn: async () =>
      getCaseExpenseCategoriesV3().then((res) => {
        if (showEmptyExpenses) return res;
        else {
          return {
            ...res,
            data: res.data.filter((type) => type.title),
          };
        }
      }),
  });
};

export const useCreateExpenseCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createCaseExpenseCategoryV3>[0]) =>
      createCaseExpenseCategoryV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.expenseCategories],
      });
    },
  });
};

export const useUpdateExpenseCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateCaseExpenseCategoryV3>[0]) =>
      updateCaseExpenseCategoryV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.expenseCategories],
      });
    },
  });
};

export const useDeleteExpenseCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (caseExpenseCategoryId: Parameters<typeof deleteCaseExpenseCategoryV3>[0]) =>
      deleteCaseExpenseCategoryV3(caseExpenseCategoryId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.expenseCategories],
      });
    },
  });
};

export const useCreateExpenseAttachment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createExpenseAttachment>[0]) =>
      createExpenseAttachment(data),
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.expenses, variables.caseExpenseId],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.expenseAttachments, variables.caseExpenseId],
      });
    },
  });
};

export const useGetExpenseAttachments = (caseExpenseId: string) => {
  return useQuery({
    queryKey: [queryKeys.expenseAttachments, caseExpenseId],
    queryFn: async () => getExpenseAttachments(caseExpenseId),
    enabled: !!caseExpenseId,
  });
};

export const useDeleteExpenseAttachment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (attachmentId: Parameters<typeof deleteExpenseAttachment>[0]) =>
      deleteExpenseAttachment(attachmentId),
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.expenseAttachments],
      });
    },
  });
};
