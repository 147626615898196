import {
  CaseCompleteType,
  getEarliestStatuteOfLimitations,
  getOpenCases,
  useGetCasesComplete,
} from '@colosseum/data';
import { GladiateLoader } from '@colosseum/shared-ui';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import FirmStatistic from '../FirmStatistic/FirmStatistic';
import { Statistic } from '../models/Statistic'; // TODO: Move this to models folder in data

/* eslint-disable-next-line */
export interface FirmAnalyticsProps {}

export function FirmAnalytics(props: FirmAnalyticsProps) {
  const firmStatistics: Statistic[] = [];

  // REACT QUERY
  const casesQuery = useGetCasesComplete();
  const casesEntities = casesQuery.data?.data ?? [];
  const cases = Object.values(casesEntities); // it was complaining about about stuff so I added object as well

  const openCaseMetrics = useMemo(() => {
    const openCases = getOpenCases(cases) as CaseCompleteType[];
    const totalExpenses = openCases.reduce((acc, curr) => {
      return typeof curr?.expenses === 'number' ? acc + curr?.expenses : acc;
    }, 0);

    const daysOnDeskTotal = openCases.reduce((acc, curr) => {
      return (acc + dayjs().diff(curr.dateCreated, 'day')) as number;
    }, 0);

    const daysOnDesk = openCases.length > 0 ? Math.round(daysOnDeskTotal / openCases.length) : '-';
    const casesApproachingStatute = openCases.filter((c) => {
      const sixMonthsAway = dayjs().add(6, 'month').format('YYYY-MM-DD');
      const statute = getEarliestStatuteOfLimitations(c);
      return statute.date ? statute.date < sixMonthsAway : false;
    }).length;

    return {
      openCases,
      totalExpenses,
      daysOnDesk,
      casesApproachingStatute,
    };
  }, [cases]);

  firmStatistics.push({
    icon: 'briefcase',
    value: openCaseMetrics.openCases.length,
    title: 'Total Open Cases',
    currency: false,
  });

  firmStatistics.push({
    icon: 'clock',
    value: openCaseMetrics.casesApproachingStatute,
    title: 'Cases Approaching Statute',
    currency: false,
  });

  firmStatistics.push({
    icon: 'banknotes',
    value: openCaseMetrics.totalExpenses,
    title: 'Total Open Case Expenses',
    currency: true,
  });

  firmStatistics.push({
    icon: 'calendar-days',
    value: openCaseMetrics.daysOnDesk ?? 0,
    title: 'Average Days on Desk',
    currency: false,
  });

  return (
    <div className="relative p-5 mt-5 bg-white border shadow-sm rounded-xl">
      <h1 className="text-lg font-medium leading-6 text-gray-900">Case Analytics</h1>
      <div>
        <dl className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-4">
          {casesQuery.isLoading ? (
            <GladiateLoader className="col-span-4" />
          ) : (
            firmStatistics?.map((statistic: Statistic) => {
              return <FirmStatistic key={statistic.title} statistic={statistic} />;
            })
          )}
        </dl>
      </div>
    </div>
  );
}

export default FirmAnalytics;
