import {
  displayContactName,
  formatPrice,
  getChipsForParty,
  getPolicyValueLines,
} from '@colosseum/data';
import { TooltipWrapper } from '@colosseum/shared-ui';
import { CaseContactConnectionType, ContactType, PolicyType } from '@gladiate/types';
import {
  CheckCircleIcon,
  MinusCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { startCase } from 'lodash';
import CarouselCard from './CarouselCard';

export interface PartyPolicySummaryCardProps {
  contact?: ContactType & CaseContactConnectionType;
  policy?: PolicyType & { insurer: ContactType | undefined };
}

export const PartyPolicySummaryCard = (props: PartyPolicySummaryCardProps) => {
  const { contact, policy } = props;
  const valueLines = getPolicyValueLines(policy);
  const chips = getChipsForParty(contact?.roles ?? []);
  return (
    <CarouselCard
      contact={contact}
      type="policy"
      id={policy?.policyId ?? ''}
      roleChips={chips}
      valueLines={valueLines}
    >
      <div className="flex gap-1">
        <span>
          <div className="text-xs typography-gray-upper mb-[-2px] select-none">Insurer</div>
          <div className="flex items-center text-sm gap-x-0.5 select-none">
            {policy?.insurer ? `${displayContactName(policy.insurer)}` : '-'}
            {/* Unknown, pending, confirmed, denied */}
            {policy?.coverageStatus === 'confirmed' && (
              <TooltipWrapper message="Coverage Confirmed">
                <CheckCircleIcon className="w-4 h-4 text-green-500 min-w-4" />
              </TooltipWrapper>
            )}
            {policy?.coverageStatus === 'denied' && (
              <TooltipWrapper message="Coverage Denied">
                <XCircleIcon className="w-4 h-4 text-red-500 min-w-4" />
              </TooltipWrapper>
            )}
            {policy?.coverageStatus === 'pending' && (
              <TooltipWrapper message="Coverage Pending">
                <MinusCircleIcon className="w-4 h-4 text-yellow-500 min-w-4" />
              </TooltipWrapper>
            )}
            {(!policy?.coverageStatus || policy?.coverageStatus === 'unknown') && (
              <TooltipWrapper message="Coverage Unknown">
                <QuestionMarkCircleIcon className="w-4 h-4 text-gray-500 min-w-4" />
              </TooltipWrapper>
            )}
          </div>
        </span>
        <span className="flex grow"></span>
        <span>
          <div className="text-xs typography-gray-upper mb-[-2px] select-none">Liability</div>
          <div className="text-sm select-none">
            {startCase(contact?.liabilityStatus) || 'Unknown'}
          </div>
        </span>
      </div>
    </CarouselCard>
  );
};

export default PartyPolicySummaryCard;
