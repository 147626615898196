import {
  formatTagName,
  getCaseAttorneyFeesV3,
  getCaseExpensesV3,
  getCaseOptions,
  getCocounselOptions,
  getContactOptions,
  getFirmOptions,
  getFormatOptions,
  getInsuranceOptions,
  getNonSpecificOptions,
  getSettlementNegotiationOptions,
  getStaffOptions,
  getVehicleOptions,
  processTag,
  useGenerateDocumentData,
  useGetAttorneyFeesForCase,
  useGetCase,
  useGetCaseContactConnectionsViewModel,
  useGetCoCounselsComplete,
  useGetDocumentTags,
  useGetExpensesForCase,
  useGetFirm,
  useGetFirmOffices,
  useGetFirmUserWithDisplayNameFromUsername,
  useGetMedicalTreatments,
  useGetScenarioForCase,
  useGetSettlementProposals,
  useGetStaff,
  useGetTemplates,
} from '@colosseum/data';
import {
  KeyValuePair,
  MedicalTreatmentBill,
  TemplateType,
  TemplateVariables,
} from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import FilePreview from '../FilePreview/FilePreview';
import FilterCombobox from '../FilterCombobox/FilterCombobox';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import Typography from '../Typography/Typography';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import { Form } from '../shadcn/Form/Form';
import styles from './DocGenerationForm.module.css';

/* eslint-disable-next-line */
export interface DocGenerationFormProps {
  setSelectedTemplate: React.Dispatch<React.SetStateAction<TemplateType>>;
  setSelectedInsurancePolicy: React.Dispatch<React.SetStateAction<string>>;
  setDocGenPayload: React.Dispatch<React.SetStateAction<TemplateVariables>>;
  setDocGenReady: (ready: boolean) => void;
  caseId: string;
}

const formSchema = z.object({
  templateId: z.string().optional(),
  description: z.string().optional(),
});

export type OptionType = {
  value: string;
  label: string;
};

export function DocGenerationForm(props: DocGenerationFormProps) {
  const { caseId, setDocGenPayload } = props;

  const [template, setTemplate] = useState<TemplateType | undefined>(undefined);
  const [docGenState, setDocGenState] = useState<{ [key: string]: any }>({});
  const [showFilePreview, setShowFilePreview] = useState(false);

  const { data: caseContactConnectionsData } = useGetCaseContactConnectionsViewModel(caseId);
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();
  const { data: caseData } = useGetCase(caseId);
  const { data: medicalTreatmentData } = useGetMedicalTreatments(caseId);
  const { data: settlementsData } = useGetSettlementProposals(caseId);
  const { data: expenseData } = useGetExpensesForCase(caseId);
  const { data: scenarioData } = useGetScenarioForCase(caseId);
  const { data: documentTagsData, isLoading: isDocumentTagsLoading } = useGetDocumentTags(
    template?.documentId ?? '',
  );
  const { data: staffData } = useGetStaff(caseId);
  const { data: coCounselData } = useGetCoCounselsComplete();
  const { data: firmSettingsData, isFetched: isFirmSettingsFetched } = useGetFirm();
  const { data: firmOfficesData, isFetched: isFirmOfficesFetched } = useGetFirmOffices();
  const { data: feeAgreementsData } = useGetAttorneyFeesForCase(caseId);

  const firmOffices = firmOfficesData?.data;
  const firmSettings = firmSettingsData?.data;
  const allContacts = caseContactConnectionsData?.data?.map(
    (caseContactConnection) => caseContactConnection.contact,
  );
  const scenario = scenarioData?.data;
  const staff = staffData?.data ?? [];
  const coCounsel =
    coCounselData.data.filter((coCounsel) => {
      return coCounsel.caseAccesses.some((caseAccess) => {
        return caseAccess.caseId === caseId;
      });
    }) ?? [];
  const vehicles = scenario?.vehicles;
  const insurances = scenario?.policies;
  const documentTags = documentTagsData?.data?.documentTags;
  const documentTagsToDisplay = documentTags?.display;
  const documentTagsToHide = documentTags?.hide;

  // We will want to remove this once we have a better way to generate some of the medical treatment table data
  const { generateDocumentData, isLoading: isGenerateDocumentLoading } = useGenerateDocumentData(
    caseId,
    template?.documentId ?? '',
    '.pdf',
  );

  const templateQuery = useGetTemplates();
  const templates = templateQuery?.data?.data;

  const templateOptions = templates
    ?.sort((a, b) => {
      if (!a.title || !b.title) return 0;
      return a.title?.localeCompare(b.title);
    })
    .filter((template) => template?.title && template.documentId)
    .reduce((next, template) => {
      const { title, templateId } = template;
      return { ...next, [title ?? '']: templateId };
    }, {});
  const initialValues: KeyValuePair = {
    template: undefined,
    insurance: undefined,
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: initialValues,
  });

  const documentTagsToDisplayGrouped = groupBy(documentTagsToDisplay, (rawTag) => {
    const { tagParts, instance } = processTag(rawTag);
    const itemName = tagParts.length > 1 ? tagParts.slice(0, -1).join('_') : tagParts[0];
    return `${itemName}${instance === 1 ? '' : `_${instance}`}`;
  });

  // This function takes a tag and returns the corresponding options.
  const getOptionsForTag = (tag: string) => {
    if (!documentTags) return [];
    const { tagParts } = processTag(tag);

    // Early exit if the tag is invalid
    if (tagParts.length === 0) {
      return [];
    }

    switch (tagParts[0].toLowerCase()) {
      case 'attorneysfees': {
        return getNonSpecificOptions<
          Awaited<ReturnType<Awaited<typeof getCaseAttorneyFeesV3>>>['data'][0]
        >({
          options: feeAgreementsData?.data,
          tagParts,
        });
      }
      case 'currentdate':
        return [
          {
            value: dayjs().format('MMMM DD YYYY'),
            label: dayjs().format('MMMM DD YYYY'),
          },
        ];
      case 'contact':
        return getContactOptions({
          caseContactConnectionsData: caseContactConnectionsData?.data,
          contacts: allContacts,
          tagParts,
        });
      case 'medicaltreatment':
        return getNonSpecificOptions<MedicalTreatmentBill>({
          options: medicalTreatmentData?.data,
          tagParts,
        });
      case 'settlement':
        return getSettlementNegotiationOptions({
          settlements: settlementsData?.data,
          caseContactConnections: caseContactConnectionsData?.data,
          tagParts,
        });

      case 'expense':
        return getNonSpecificOptions<
          Awaited<ReturnType<Awaited<typeof getCaseExpensesV3>>>['data'][0]
        >({
          options: expenseData?.data,
          tagParts,
        });
      case 'case':
        return getCaseOptions({
          caseData: caseData?.data,
          tagParts,
        });
      case 'insurance':
        return getInsuranceOptions({
          tagParts,
          insurances,
          caseContactConnections: caseContactConnectionsData?.data,
          contacts: allContacts,
        });
      case 'vehicle':
        return getVehicleOptions({ vehicles, tagParts });
      case 'staff':
        return getStaffOptions({
          staff,
          getFirmUserWithDisplayNameFromUsername,
          tagParts,
        });
      case 'cocounsel':
        return getCocounselOptions({
          coCounsel,
          getFirmUserWithDisplayNameFromUsername,
          tagParts,
        });
      case 'firm':
        return getFirmOptions({
          tagParts,
          firmOffices,
          firmSettings,
        });
      default:
        return [];
    }
  };

  const setDocGenStateWithPrevState = (key: string, value: any) => {
    const formatOptions = getFormatOptions();
    const formatKey = key.split('_').filter((part) => Object.keys(formatOptions).includes(part))[0];
    const formatOption = formatOptions[formatKey];
    setDocGenState((prevState: { [key: string]: any }) => ({
      ...prevState,
      [key]: value,
    }));
    setDocGenPayload((prevState: { [key: string]: any }) => ({
      ...prevState,
      [key]: formatOption?.modifierFunc ? formatOption?.modifierFunc(value) : value,
    }));
  };

  const checkIfAllDropdownsFilled = () => {
    // Ensure that documentTagsToDisplay is available and not empty
    if (
      !documentTagsToDisplay ||
      documentTagsToDisplay.length + (documentTagsToHide?.length ?? 0) === 0 ||
      !docGenState
    ) {
      return false;
    }
    return documentTagsToDisplay.every((tag) => {
      const formattedTag = tag.replace(/{{|}}/g, '');
      if (!docGenState?.[formattedTag]) {
        return false;
      }
      return true;
    });
  };

  const handleTemplateChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const selectedTemplate = templates?.find((template) => template.templateId === target.value);

    // Clear out the payload when a new template is selected
    setDocGenPayload({}); // Reset the payload
    setDocGenState({}); // Reset the state

    // Set the selected template
    setTemplate(selectedTemplate);
    props.setSelectedTemplate(selectedTemplate ?? ({} as TemplateType));

    // Reset the form to clear other fields if necessary
    form.reset(); // Uncomment this if you want to reset the entire form
  };

  useEffect(() => {
    const documentDataFromHook = generateDocumentData();
    if (documentTagsToHide) {
      const valuesToSet: Array<keyof TemplateVariables> = [
        'treatment',
        'treatmentAggregates',
        'expenses',
        'expenseAggregates',
        'liens',
        'liensAggregates',
      ];
      valuesToSet.forEach((key) => {
        setDocGenStateWithPrevState(key, documentDataFromHook?.[key]);
      });
    }
  }, [documentTagsToHide, isGenerateDocumentLoading]);

  useEffect(() => {
    const allFilled = checkIfAllDropdownsFilled();
    props.setDocGenReady(allFilled && !isGenerateDocumentLoading);
  }, [docGenState, documentTagsToDisplay, isGenerateDocumentLoading]);

  return (
    <div className={styles['container']}>
      {template?.documentId && (
        <FilePreview
          isOpen={showFilePreview}
          setOpen={setShowFilePreview}
          filePreviewItem={
            template.documentId
              ? {
                  name: template?.fileName,
                  s3ObjKey: template?.documentId,
                }
              : {}
          }
        />
      )}
      <Form {...form}>
        <form>
          <div className="grid grid-cols-1 pt-2 pb-2 gap-y-5 gap-x-3">
            {templateQuery.isLoading ? (
              <GladiateLoader />
            ) : (
              <SelectFormInput
                title="Template"
                listItems={templateOptions ?? {}}
                listItemsIsObject
                handleOnChange={handleTemplateChange}
                {...form.register('templateId')}
              />
            )}
          </div>
          {template?.documentId && (
            <div className="flex items-center justify-between p-1 px-2 mt-2 ml-2 font-semibold text-center rounded-full text-atlantic-blue bg-light-blue min-w-[200px]">
              <p
                className="cursor-pointer hover:underline hover:text-black line-clamp-1"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFilePreview(true);
                }}
              >
                {template.fileName}
              </p>
            </div>
          )}
        </form>
      </Form>
      <div>
        {/* if document tags are loading make a skeleton of pulsing of 8 dropdowns */}
        {isDocumentTagsLoading && template?.documentId && (
          <>
            {[...Array(8)].map((_, i) => (
              <div key={i} className="w-full h-8 py-4 bg-gray-200 rounded animate-pulse" />
            ))}
          </>
        )}
        {!isDocumentTagsLoading &&
          Object.keys(documentTagsToDisplayGrouped)
            .sort((a, b) => {
              const aParts = a.split('_');
              const bParts = b.split('_');
              // Sorting alphabetically on first item, then by instance, then by depth (depth meaning number of parent objects)
              if (aParts[0] !== bParts[0]) {
                return a.split('_')[0].localeCompare(b.split('_')[0]);
              }
              const aInstance = a?.replace(/^\D+/g, '') || '1';
              const bInstance = b?.replace(/^\D+/g, '') || '1';
              if (aInstance !== bInstance) {
                return Number(aInstance) - Number(bInstance);
              }
              const aDepth = a.split('_').length;
              const bDepth = b.split('_').length;

              if (aDepth !== bDepth) {
                return aDepth - bDepth;
              }
              return a.localeCompare(b);
            })
            .map((tagGroupName) => (
              <div className="grid grid-cols-2 gap-5 py-4 border-b" key={tagGroupName}>
                <Typography variant="heading" className="col-span-2">
                  {formatTagName(tagGroupName)}
                </Typography>
                {documentTagsToDisplayGrouped[tagGroupName]?.map((tag) => {
                  const formattedTagName = formatTagName(tag, false).split('/');
                  const fieldTitle = formattedTagName[formattedTagName.length - 1];

                  const updateKeyTag = tag.replace(/{{/g, '').replace(/}}/g, '');

                  return (
                    <FilterCombobox
                      key={tag}
                      title={fieldTitle}
                      updateKey={updateKeyTag}
                      options={getOptionsForTag(tag)}
                      externalState={docGenState}
                      setExternalState={setDocGenStateWithPrevState}
                    />
                  );
                })}
              </div>
            ))}
        {!isDocumentTagsLoading && documentTagsToDisplay?.length === 0 && template?.documentId && (
          <div className="text-center text-gray-400">No document tags found</div>
        )}
      </div>
    </div>
  );
}

export default DocGenerationForm;
