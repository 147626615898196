/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// Dependencies
import { ArrowLeftIcon, ArrowRightIcon, CheckIcon } from '@heroicons/react/24/outline';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback } from 'react';

// Components
import {
  AssigneeChipGroup,
  Button,
  CaseStatusSelect,
  CustomErrorBoundary,
  GladiateLoader,
  ResourceTags,
  TextSkeleton,
  TooltipWrapper,
  Typography,
} from '@colosseum/shared-ui';
import CaseTypeSelect from '../CaseTypeSelect/CaseTypeSelect';

// Helpers
import {
  formatPrice,
  getCaseContactConnectionRoleIdForParty,
  getEarliestStatuteOfLimitations,
  prettifyDateString,
  useCreateCaseStatusUpdate,
  useGetCaseMedicalSummary,
  useGetCaseStatuses,
  useGetPoliciesWithInsurer,
  useGetScenarioComplete,
  useUpdateContact,
} from '@colosseum/data';
import { CaseType, caseContactConnectionOptions } from '@gladiate/types';
import { withErrorBoundary } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import MedicalTreatmentSummaryCard from './MedicalTreatmentSummaryCard';
import PartyPolicySummaryCard from './PartyPolicySummaryCard';
import PartyVehicleSummaryCard from './PartyVehicleSummaryCard';

/* eslint-disable-next-line */
export interface CaseSummaryCardProps {
  assignees: string[];
  caseId: string;
  caseData: CaseType | undefined;
  clientContactId: string;
  isCaseLoading: boolean;
  setSettingsSliderOpen: (arg0: boolean) => void;
}

const renderTextOrLoading = (text: string | number, isLoading: boolean, width: number) => {
  return isLoading ? (
    <div className={`w-[${width}px] flex inline-flex`}>
      <TextSkeleton />
    </div>
  ) : (
    text
  );
};

export function CaseSummaryCard(props: CaseSummaryCardProps) {
  const { assignees, caseId, caseData, clientContactId, isCaseLoading, setSettingsSliderOpen } =
    props;

  const queryClient = useQueryClient();
  const updateContactMutation = useUpdateContact();
  const createCaseStatusUpdate = useCreateCaseStatusUpdate();
  const { data: caseMedicalSummaryData } = useGetCaseMedicalSummary(caseId);
  const summarizedClientMedicals = caseMedicalSummaryData?.data
    ?.filter((item) =>
      item.roles?.some((role) => role.roleOnCase === caseContactConnectionOptions.client),
    ) // only use clients
    ?.filter(
      // has a treatment or a bill
      (item) =>
        (item?.medicalTreatmentAppointments?.length &&
          item?.medicalTreatmentAppointments?.length > 0) ||
        (item?.medicalTreatments?.length && item?.medicalTreatments?.length > 0),
    );

  const { data: caseStatusesRes } = useGetCaseStatuses();
  const caseStatuses = caseStatusesRes?.data;
  const { data: scenarioData, isLoading: isScenarioLoading } = useGetScenarioComplete(caseId);
  const scenario = scenarioData?.data;
  const allPolicies = scenario?.policies;
  const policesWithInsurer = useGetPoliciesWithInsurer(caseId, allPolicies);
  const scenarioConnections = scenario?.itemConnections.filter((item) =>
    scenario.parties.some(
      (party) => getCaseContactConnectionRoleIdForParty(party) === item.partyId,
    ),
  ); // make sure the party exists in the scenario
  const contactPolicyLinks =
    scenario?.parties.length && policesWithInsurer.length
      ? scenarioConnections
          .filter((item) => item.partyId && item.policyId)
          .map((item) => {
            const contact = scenario?.parties.find(
              (contact) => getCaseContactConnectionRoleIdForParty(contact) === item.partyId,
            );
            const policy = policesWithInsurer?.find((policy) => policy?.policyId === item.policyId);
            return { contact, policy };
          })
      : [];

  const contactVehicleLinks =
    scenario?.parties.length && scenario?.vehicles.length
      ? scenarioConnections
          .filter((item) => item.partyId && item.vehicleId)
          .map((item) => {
            const contact = scenario?.parties.find(
              (contact) => getCaseContactConnectionRoleIdForParty(contact) === item.partyId,
            );
            const vehicle = scenario?.vehicles.find(
              (vehicle) => vehicle?.vehicleId === item.vehicleId,
            );
            return { contact, vehicle };
          })
      : [];

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
  });
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const rowData = [
    {
      title: 'Incident Date',
      value: prettifyDateString(caseData?.dateOfIncident),
      loader: isCaseLoading,
    },
    {
      title: 'Filing Date',
      value: prettifyDateString(caseData?.initialFilingDate),
      loader: isCaseLoading,
    },
    {
      title: 'Statute Date',
      value: prettifyDateString(getEarliestStatuteOfLimitations(caseData).date),
      loader: isCaseLoading,
    },
    { title: 'State', value: caseData?.incidentState, loader: isCaseLoading },
    { title: 'County', value: caseData?.incidentCounty, loader: isCaseLoading },
    {
      title: 'Projected Settlement Amount',
      value: caseData?.projectedSettlementAmount
        ? formatPrice(caseData?.projectedSettlementAmount)
        : '-',
      loader: isCaseLoading,
    },
  ];

  function handleCaseStateStatusChange(newCaseStatusId: string) {
    if (caseStatuses?.find((cs) => newCaseStatusId === cs.caseStatusId)?.category === 'refer') {
      const contactMutationArgs = {
        contactId: clientContactId,
        role: 'Referred Client',
        successCb: () => {
          queryClient.invalidateQueries({
            queryKey: ['contacts', caseId],
          });
        },
        errorCb: (err: any) => {
          console.error(err);
        },
      };

      updateContactMutation.mutate(contactMutationArgs);
    }

    if (caseStatuses?.find((cs) => newCaseStatusId === cs.caseStatusId)?.category === 'decline') {
      const contactMutationArgs = {
        contactId: clientContactId,
        role: 'Declined Client',
        successCb: () => {
          queryClient.invalidateQueries({
            queryKey: ['contacts', caseId],
          });
        },
        errorCb: (err: any) => {
          console.error(err);
        },
      };

      updateContactMutation.mutate(contactMutationArgs);
    }

    createCaseStatusUpdate.mutate({
      caseId,
      caseStatusId: newCaseStatusId,
    });
  }

  return (
    <div className="relative w-full h-full p-6 pb-4 bg-white border border-gray-200 shadow-sm rounded-xl">
      <div className="flex items-center justify-between pb-6 text-sm border-b">
        {caseData?.caseType?.title || isCaseLoading ? (
          <div className="text-lg font-semibold">
            {renderTextOrLoading(caseData?.caseType?.title ?? '', isCaseLoading, 200)}
          </div>
        ) : (
          <CaseTypeSelect caseId={caseId} currentCaseType={caseData?.caseType} />
        )}
        {isCaseLoading ? (
          <div className="text-lg font-semibold">
            <div className={`w-[200px] flex inline-flex`}>
              <TextSkeleton />
            </div>
          </div>
        ) : (
          <CaseStatusSelect
            currentCaseStatusId={caseData?.caseStatus?.caseStatusId}
            handleValueChange={handleCaseStateStatusChange}
          />
        )}
      </div>
      <div className="grid grid-cols-1 gap-1 my-6 text-sm gap-y-6 xl:grid-cols-3 lg:grid-cols-2">
        {rowData.map((row) => (
          <div key={row.title} className="flex gap-[8px]">
            <Typography variant="grayUpper" className="self-center max-w-20">
              {row.title}
            </Typography>
            <Typography variant="semiBold" size="sm" className="flex self-center line-clamp-1">
              <div className="truncate">
                {renderTextOrLoading(row.value ?? '-', row.loader, 100)}
              </div>

              {row.title === 'Statute Date' &&
                getEarliestStatuteOfLimitations(caseData).satisfied && (
                  <TooltipWrapper message="Statute of Limitations Satisfied">
                    <CheckIcon className="w-4 h-4 text-green-500" />
                  </TooltipWrapper>
                )}
            </Typography>
          </div>
        ))}
      </div>
      <div className="flex pb-6 border-b">
        <Button className="self-center h-5 p-1 mr-2 min-w-5" variant="ghost" onClick={scrollPrev}>
          <ArrowLeftIcon className="w-5 h-5" />
        </Button>
        <div className="overflow-hidden grow" ref={emblaRef}>
          <div className="flex">
            {isScenarioLoading ? (
              <div className="flex items-center justify-center w-full h-[250px] bg-gray-100 rounded-xl">
                <GladiateLoader />
              </div>
            ) : contactPolicyLinks?.length ||
              contactVehicleLinks?.length ||
              summarizedClientMedicals?.length ? (
              <>
                {contactPolicyLinks?.map((link) => (
                  <PartyPolicySummaryCard
                    key={`${link?.contact?.contactId}-${link.policy?.policyId}` ?? ''}
                    contact={link?.contact}
                    policy={link?.policy}
                  />
                ))}
                {contactVehicleLinks?.map((link) => (
                  <PartyVehicleSummaryCard
                    key={`${link?.contact?.contactId}-${link.vehicle?.vehicleId}` ?? ''}
                    contact={link?.contact}
                    vehicle={link?.vehicle}
                  />
                ))}
                {summarizedClientMedicals?.map((summarizedClientMedical) => (
                  <MedicalTreatmentSummaryCard
                    key={summarizedClientMedical.contactId}
                    item={summarizedClientMedical}
                  />
                ))}
              </>
            ) : (
              <div className="flex items-center justify-center w-full h-[250px] bg-gray-100 rounded-xl">
                <span className="text-sm font-semibold">No Summary Results.</span>
              </div>
            )}
          </div>
        </div>
        <Button className="self-center h-5 p-1 ml-2 min-w-5" variant="ghost" onClick={scrollNext}>
          <ArrowRightIcon className="w-5 h-5" />
        </Button>
      </div>
      <div className="flex justify-between mt-4">
        <div className="flex items-center">
          <h3 className="mr-6 text-lg font-semibold">Tags</h3>
          <ResourceTags resourceId={caseId} resourceType="case" />
        </div>
        <div className="flex items-center">
          <div className="self-center mr-2 typography-gray-upper">ASSIGNED</div>
          <div className="cursor-pointer" onClick={() => setSettingsSliderOpen(true)}>
            <AssigneeChipGroup assignees={assignees} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default withErrorBoundary(CaseSummaryCard, {
  fallback: ({ error }) => <CustomErrorBoundary error={error} />,
});
