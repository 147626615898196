import React, { useEffect, useRef } from 'react';
import {
  DocumentOptions,
  displayContactName,
  formatAddress,
  formatPhoneNumber,
  useGetCaseContactConnectionsViewModel,
  useUpdateRecordRequest,
} from '@colosseum/data';
import {
  CaseContactConnectionLinker,
  Form,
  FormField,
  FormMessage,
  MultiSelectDropdown,
  SelectFormInput,
} from '@colosseum/shared-ui';
import {
  ContactViewModelType,
  LabyrinthUpdateParams,
  RecordRequest,
  RequestStatusOptions,
  caseContactConnectionOptions,
  manualRequestStatusOptions,
} from '@gladiate/types';
import { startCase } from 'lodash';
import { useForm } from 'react-hook-form';

export interface ManualRecordRequestFormProps {
  caseId: string;
  data?: RecordRequest;
}

export function ManualRecordRequestForm(props: ManualRecordRequestFormProps) {
  const { caseId, data } = props;

  const { data: caseContactConnectionsViewModelData } =
    useGetCaseContactConnectionsViewModel(caseId);

  const updateManualRecordRequest = useUpdateRecordRequest();

  const formattedAddress = formatAddress({
    streetAddress: data?.streetAddress,
    streetAddress2: data?.streetAddress2,
    streetAddress3: data?.streetAddress3,
    streetAddress4: data?.streetAddress4,
    city: data?.city,
    state: data?.state,
    zipCode: data?.zipCode,
    country: data?.country,
  });

  const form = useForm({
    mode: 'onBlur',
    values: {
      recordsClientContactId: data?.recordsClientContactId,
      requestedInformation: data?.requestedInformation,
      requestStatus: data?.requestStatus,
      phoneNumber: data?.phoneNumber,
      faxNumber: data?.faxNumber,
      email: data?.email,
      address: formattedAddress === '-' ? undefined : formattedAddress,
    },
  });

  const defaultSetDone = useRef(false);

  const clientsOnCase =
    caseContactConnectionsViewModelData?.data?.filter((caseContactConnection) =>
      caseContactConnection.roles.find(
        (role) => role.roleOnCase === caseContactConnectionOptions.client,
      ),
    ) ?? [];

  const recordsProvider = caseContactConnectionsViewModelData?.data?.filter(
    (caseContactConnection) =>
      caseContactConnection.roles.find(
        (role) =>
          role.roleOnCase === caseContactConnectionOptions.recordsProvider &&
          role.itemId === data?.requestId,
      ),
  )[0];

  const recordsProviderPhoneNumbers = recordsProvider?.contact.contactNumbers.filter(
    (phone) => phone.numberType !== 'fax',
  );
  const recordsProviderFaxNumbers = recordsProvider?.contact.contactNumbers.filter(
    (phone) => phone.numberType !== 'phone',
  );

  const getPhoneContext = (phone: ContactViewModelType['contactNumbers'][0]) => {
    let context = '';
    if (phone.numberType) {
      context = startCase(phone.numberType);
    } else {
      context = 'No Type';
    }
    if (phone.numberLabel) {
      context = `${context} (${phone.numberLabel})`;
    }
    return context;
  };

  const recordsProviderPhoneOptions =
    recordsProviderPhoneNumbers?.reduce(
      (acc, phone) => {
        const context = getPhoneContext(phone);
        acc[formatPhoneNumber(phone.number ?? '') ?? ''] = {
          value: phone.number ?? '',
          details: context,
        };
        return acc;
      },
      {} as {
        [key: string]: { value: string; details: string };
      },
    ) ?? {};

  const recordsProviderFaxOptions =
    recordsProviderFaxNumbers?.reduce(
      (acc, phone) => {
        const context = getPhoneContext(phone);
        acc[formatPhoneNumber(phone.number ?? '') ?? ''] = {
          value: phone.number ?? '',
          details: context,
        };
        return acc;
      },
      {} as {
        [key: string]: { value: string; details: string };
      },
    ) ?? {};

  const recordsProviderEmailOptions =
    recordsProvider?.contact.contactEmails?.reduce(
      (acc, email) => {
        acc[email.emailAddress ?? ''] = {
          value: email.emailAddress ?? '',
          details: email.emailLabel ?? '',
        };
        return acc;
      },
      {} as {
        [key: string]: { value: string; details: string };
      },
    ) ?? {};

  const recordsProviderAddressOptions =
    recordsProvider?.contact.contactAddresses?.reduce(
      (acc, address) => {
        const contactFormattedAddress = formatAddress(address);
        acc[formatAddress(address) ?? ''] = {
          value: contactFormattedAddress,
          details: address.addressLabel ?? '',
        };
        return acc;
      },
      {} as {
        [key: string]: { value: string; details: string };
      },
    ) ?? {};

  const clientsOnCaseDropDownOptions = clientsOnCase
    ?.map((client) => {
      return {
        name: displayContactName(client.contact),
        caseContactConnectionId: client.caseContactConnectionId,
        contactId: client.contact?.contactId,
      };
    })
    ?.reduce(
      (acc, client) => {
        acc[client.name] = client.contactId;
        return acc;
      },
      {} as {
        [key: string]: string;
      },
    );

  const handleUpdate = (updateData: Partial<RecordRequest>) => {
    if (data?.requestId) {
      updateManualRecordRequest.mutate({
        ...updateData,
        requestId: data.requestId,
      });
    }
  };

  const handleDocumentSelect = (params: LabyrinthUpdateParams) => {
    const value = params.value as string[];
    handleUpdate({
      requestedInformation: value.length === 0 ? '' : value.join(', '),
    });
  };

  useEffect(() => {
    if (data?.requestId && recordsProvider && !defaultSetDone.current) {
      const defaultValues: Partial<RecordRequest> = {};

      if (!data.recordsClientContactId && clientsOnCaseDropDownOptions) {
        defaultValues.recordsClientContactId = Object.values(clientsOnCaseDropDownOptions)[0];
      }

      if (!data.phoneNumber && recordsProviderPhoneOptions) {
        defaultValues.phoneNumber = Object.values(recordsProviderPhoneOptions)[0]?.value;
      }

      if (!data.faxNumber && recordsProviderFaxOptions) {
        defaultValues.faxNumber = Object.values(recordsProviderFaxOptions)[0]?.value;
      }

      if (!data.email && recordsProviderEmailOptions) {
        defaultValues.email = Object.values(recordsProviderEmailOptions)[0]?.value;
      }

      if (formattedAddress === '-' && recordsProviderAddressOptions) {
        const defaultAddress = Object.values(recordsProviderAddressOptions)[0]?.value;
        if (defaultAddress) {
          const addressParts = defaultAddress.split(', ');
          defaultValues.streetAddress = addressParts[0] || '';
          defaultValues.city = addressParts[1] || '';
          defaultValues.state = addressParts[2] || '';
          defaultValues.zipCode = addressParts[3] || '';
        }
      }
      if (Object.keys(defaultValues).length > 0) {
        handleUpdate(defaultValues);
      }

      defaultSetDone.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    clientsOnCaseDropDownOptions,
    recordsProviderPhoneOptions,
    recordsProviderFaxOptions,
    recordsProviderEmailOptions,
    recordsProviderAddressOptions,
  ]);

  if (!data?.requestId) {
    return null;
  }

  return (
    <Form {...form}>
      <form className="grid grid-cols-2 mb-2 gap-x-3 gap-y-5">
        <div className="col-span-2">
          <SelectFormInput
            name="recordsClientContactId"
            title="Client"
            listItemsIsObject
            listItems={clientsOnCaseDropDownOptions}
            key={data?.recordsClientContactId}
            defaultValue={
              data?.recordsClientContactId || Object.values(clientsOnCaseDropDownOptions)[0]
            }
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLSelectElement;
              handleUpdate({
                recordsClientContactId: target.value,
              });
            }}
          />
        </div>
        <div className="col-span-2">
          <CaseContactConnectionLinker
            caseId={caseId}
            title="Records Provider"
            roleOnCase={caseContactConnectionOptions.recordsProvider}
            itemType="recordRequest"
            itemId={data?.requestId}
            linkingFunction={() => {
              handleUpdate({
                // @ts-expect-error - this needs to be allowed to be null
                phoneNumber: null,
                // @ts-expect-error - this needs to be allowed to be null
                faxNumber: null,
                // @ts-expect-error - this needs to be allowed to be null
                email: null,
                // @ts-expect-error - this needs to be allowed to be null
                streetAddress: null,
                // @ts-expect-error - this needs to be allowed to be null
                streetAddress2: null,
                // @ts-expect-error - this needs to be allowed to be null
                streetAddress3: null,
                // @ts-expect-error - this needs to be allowed to be null
                streetAddress4: null,
                // @ts-expect-error - this needs to be allowed to be null
                city: null,
                // @ts-expect-error - this needs to be allowed to be null
                state: null,
                // @ts-expect-error - this needs to be allowed to be null
                zipCode: null,
                // @ts-expect-error - this needs to be allowed to be null
                country: null,
              });
              form.setValue('phoneNumber', undefined);
              form.setValue('faxNumber', undefined);
              form.setValue('email', undefined);
              form.setValue('address', undefined);
            }}
          />
        </div>
        {recordsProvider && (
          <>
            <SelectFormInput
              title="Phone Number"
              listItems={recordsProviderPhoneOptions}
              listItemsIsObjectWithDetails
              defaultValue={
                data?.phoneNumber || Object.values(recordsProviderPhoneOptions)[0]?.value
              }
              placeholderText="Select a phone number"
              emptyMessage="No phone numbers exist for this contact."
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLSelectElement;
                handleUpdate({
                  phoneNumber: target.value,
                });
              }}
              {...form.register(`phoneNumber`)}
              triggerClassName="flex h-50 text-left"
            />
            <SelectFormInput
              title="Fax Number"
              listItems={recordsProviderFaxOptions}
              listItemsIsObjectWithDetails
              defaultValue={data?.faxNumber || Object.values(recordsProviderFaxOptions)[0]?.value}
              placeholderText="Select a fax number"
              emptyMessage="No fax numbers exist for this contact."
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLSelectElement;
                handleUpdate({
                  faxNumber: target.value,
                });
              }}
              {...form.register(`faxNumber`)}
              triggerClassName="flex h-50 text-left"
            />
            <div className="col-span-2">
              <SelectFormInput
                title="Email"
                listItems={recordsProviderEmailOptions}
                listItemsIsObjectWithDetails
                defaultValue={data?.email || Object.values(recordsProviderEmailOptions)[0]?.value}
                placeholderText="Select an email"
                emptyMessage="No email addresses exist for this contact."
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLSelectElement;
                  handleUpdate({
                    email: target.value,
                  });
                }}
                {...form.register(`email`)}
                triggerClassName="flex h-50 text-left"
              />
            </div>
            <div className="col-span-2">
              <SelectFormInput
                title="Address"
                listItems={recordsProviderAddressOptions}
                listItemsIsObjectWithDetails
                defaultValue={
                  formattedAddress === '-'
                    ? Object.values(recordsProviderAddressOptions)[0]?.value
                    : formattedAddress
                }
                placeholderText="Select an address"
                emptyMessage="No addresses exist for this contact."
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLSelectElement;
                  const address = recordsProvider?.contact.contactAddresses?.find(
                    (address) => formatAddress(address) === target.value,
                  );
                  handleUpdate({
                    state: address?.state ?? '',
                    city: address?.city ?? '',
                    zipCode: address?.zipCode ?? '',
                    streetAddress: address?.streetAddress ?? '',
                    streetAddress2: address?.streetAddress2 ?? '',
                    streetAddress3: address?.streetAddress3 ?? '',
                    streetAddress4: address?.streetAddress4 ?? '',
                    country: address?.country ?? '',
                  });
                }}
                {...form.register(`address`)}
                triggerClassName="flex h-50 text-left w-full"
              />
            </div>
          </>
        )}
        <div className="col-span-2">
          <MultiSelectDropdown
            fieldInfo={{
              title: 'Document Type *',
              valueVariable: 'documents',
              type: 'multiSelectDropdown',
              options: DocumentOptions,
            }}
            fieldValue={[
              ...(data?.requestedInformation ? data.requestedInformation.split(', ') : ''),
            ]}
            handleOnChange={handleDocumentSelect}
          />
          <FormField
            control={form.control}
            name={'requestedInformation'}
            render={() => <FormMessage />}
          />
        </div>
        <div className="col-span-2">
          <SelectFormInput
            name="requestStatus"
            title="Status"
            valueClassNames="capitalize"
            contentClassNames="capitalize"
            listItems={Object.values(manualRequestStatusOptions)}
            defaultValue={data?.requestStatus}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLSelectElement;
              handleUpdate({
                requestStatus: target.value as RequestStatusOptions,
              });
            }}
          />
        </div>
      </form>
    </Form>
  );
}
