import { BitBoolean } from './global';

export const settlementCoverageTypeOptions = {
  'Bodily Injury': 'bodilyInjury',
  'General Liability': 'generalLiability',
  MedPay: 'medPay',
  'Personal Injury Protection (PIP)': 'pip',
  'Property Damage': 'propertyDamage',
  'Underinsured Motorist (UIM)': 'uim',
  'Uninsured Motorist (UM)': 'um',
} as const;

export const settlementPolicyTypeOptions = {
  'First Party': 'firstParty',
  'Third Party': 'thirdParty',
} as const;

export const settlementCategoryOptions = {
  Authority: 'authority',
  Demand: 'demand',
  Negotiation: 'negotiation',
  Offer: 'offer',
} as const;

export const settlementStatusOptions = {
  Accepted: 'accepted',
  'Accepted with Contingencies': 'acceptedWithContingencies',
  'Not Accepted': 'notAccepted',
} as const;

export type SettlementProposalType = {
  firmId: string;
  settlementProposalId: string;
  dateCreated: string;
  dateModified: string;

  amount?: number;
  coverageType?: (typeof settlementCoverageTypeOptions)[keyof typeof settlementCoverageTypeOptions];
  dateIssued?: string;
  description?: string;
  expirationDate?: string;
  policyLimits?: BitBoolean;
  policyType?: (typeof settlementPolicyTypeOptions)[keyof typeof settlementPolicyTypeOptions];
  type?: (typeof settlementCategoryOptions)[keyof typeof settlementCategoryOptions];
  proposalStatus?: (typeof settlementStatusOptions)[keyof typeof settlementStatusOptions];
  proposalContingencies?: string[];
};
