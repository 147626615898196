import { tanstackTableNames, useGetCaseCampaignSubscriptions } from '@colosseum/data';
import {
  DataTable,
  renderCell,
  renderCellWithChips,
  renderHeader,
  stringIncludesFilterFn,
} from '@colosseum/shared-ui';
import { CaseCampaignSubscription } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import CaseCampaignSubscriptionSlideover from '../CaseCampaignSubscriptionPage/CaseCampaignSubscriptionSlideover';

/* eslint-disable-next-line */
export interface SubscriptionTableProps {
  caseCampaignSubscriptionId: string;
  isViewOnly?: boolean;
}

export const columns: ColumnDef<CaseCampaignSubscription>[] = [
  {
    accessorFn: (row) => row.case?.caseTitle,
    id: 'Case Title',
    sortUndefined: 1,
    enableHiding: false,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.status,
    id: 'Subscription Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCellWithChips({ props }),
  },
  {
    accessorFn: (row) => row.case?.caseStatus?.title,
    id: 'Case Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.case?.caseType?.title,
    id: 'Case Type',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];

export function CaseSubscriptionTable(props: SubscriptionTableProps) {
  const { caseCampaignSubscriptionId, isViewOnly = false } = props;
  // #region Hooks
  // #endregion

  // #region Data Fetching
  const { data, isLoading } = useGetCaseCampaignSubscriptions(caseCampaignSubscriptionId);
  // #endregion

  // #region State
  const [slideoverOpen, setSlideoverOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] =
    useState<CaseCampaignSubscription | null>();
  // #endregion

  // #region Derived State
  const caseCampaignSubscriptions = data?.data;
  // #endregion

  // #region useEffects
  // #endregion

  // #region Event Handlers
  // #endregion

  return (
    <>
      {selectedSubscription && (
        <CaseCampaignSubscriptionSlideover
          slideoverOpen={slideoverOpen}
          setSlideoverOpen={setSlideoverOpen}
          caseCampaignSubscription={selectedSubscription}
        />
      )}
      {isViewOnly ? (
        <DataTable
          isViewOnly={true}
          data={caseCampaignSubscriptions ?? []}
          columns={columns}
          filters={[]}
          isLoading={isLoading}
          tableName={tanstackTableNames.caseCampaignSubscription}
          showSearchBar
        />
      ) : (
        <DataTable
          isViewOnly={false}
          handleRowClick={(row) => {
            setSelectedSubscription(row.original);
            setSlideoverOpen(true);
          }}
          data={caseCampaignSubscriptions ?? []}
          columns={columns}
          filters={[]}
          isLoading={isLoading}
          tableName={tanstackTableNames.caseCampaignSubscription}
          showSearchBar
        />
      )}
    </>
  );
}

export default CaseSubscriptionTable;
