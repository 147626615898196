import {
  LeadListViewType,
  QuickCaseCreateQueryKeyValues,
  US_STATES,
  bToBitB,
  bitBToB,
  booleanToString,
  caseIncidentFormSchema,
  queryKeys,
  useCaseUpdateHandler,
  useGetCaseStatuses,
  useGetCaseTypes,
  usePermissions,
  useSetCaseTitle,
  useUpdateContact,
  useUpdateContactResource,
} from '@colosseum/data';
import {
  CalendarFormInput,
  CaseContactConnectionLinker,
  CheckboxFormInput,
  Form,
  GladiateLoader,
  PhoneInput,
  ResourceTags,
  SectionCollapsible,
  SelectFormInput,
  Switch,
  TextFormInput,
} from '@colosseum/shared-ui';
import {
  BitBoolean,
  ContactNumberType,
  ContactViewModelType,
  caseContactConnectionOptions,
} from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import CaseStaff from '../../case/CaseStaff/CaseStaff';
import ReferralSourceSection from '../../cases/ReferralSourceSection/ReferralSourceSection';

/* eslint-disable-next-line */
export interface LeadFormProps {
  lead: LeadListViewType & Partial<ContactViewModelType>;
  isLeadLoading?: boolean;
  disabled?: boolean;
}

const formSchema = z.object({
  caseTitle: z.string().optional(),
  caseTypeId: z.string().optional(),
  caseStatusId: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  nickname: z.string().max(60).optional(),
  maritalStatus: z.string().max(60).optional(),
  number: z.string().optional(),
  email: z.string().optional(),
  dateOfBirth: z.string().optional(),
  descriptionOfClientInjuries: z.string().optional(),
  ...caseIncidentFormSchema.shape,
});

export function LeadForm(props: LeadFormProps) {
  const { lead, disabled, isLeadLoading } = props;

  const queryClient = useQueryClient();

  const { data: caseTypesData, isLoading: isCaseTypesLoading } = useGetCaseTypes(true);
  const { data: caseStatusesRes } = useGetCaseStatuses();

  const setCaseTitle = useSetCaseTitle(lead.caseId);
  const updateContact = useUpdateContact();
  const updateContactPhoneNumber = useUpdateContactResource<ContactNumberType>('numbers');
  const updateContactEmail = useUpdateContactResource('emails');
  const { handleUpdateCase } = useCaseUpdateHandler(lead.caseId);

  const handleUpdateContactValue = (key: string, value: string) => {
    if (key === 'firstName' || key === 'lastName') {
      setCaseTitle({
        firstName: key === 'firstName' ? value : lead?.firstName ?? '',
        lastName: key === 'lastName' ? value : lead.lastName ?? '',
      });
      updateContact
        .mutateAsync({
          contactId: lead.contactId ?? '',
          [key]: value,
        })
        .then(() => {
          queryClient.invalidateQueries([queryKeys.cases]); // this is so that the case list updates
        });
    } else if (key === 'phoneNumbers' && lead.contactNumbers?.[0]?.contactNumberId) {
      // Update the phone number
      updateContactPhoneNumber.mutateAsync({
        resourceId: lead.contactNumbers?.[0]?.contactNumberId,
        number: value,
      });
    } else if (key === 'email' && lead.contactEmails?.[0]?.contactEmailId) {
      // Update the email
      updateContactEmail.mutateAsync({
        resourceId: lead.contactEmails?.[0]?.contactEmailId,
        emailAddress: value,
      });
    }
  };

  const caseTypes = caseTypesData?.data;
  const caseStatuses = caseStatusesRes?.data;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      ...lead,
      firstName: lead?.firstName ?? '',
      lastName: lead?.lastName ?? '',
      number: lead?.contactNumbers?.[0]?.number ?? '',
      email: lead?.contactEmails?.[0]?.emailAddress ?? '',
      caseStatusId: lead?.caseStatus?.caseStatusId,
      caseTypeId: lead?.caseTypeId,
    },
  });

  const renderTextFormInput = ({
    resourceType,
    key,
    title,
    fieldType,
    maxLength,
  }: {
    resourceType: QuickCaseCreateQueryKeyValues;
    key: keyof z.infer<typeof formSchema>;
    title: string;
    fieldType?: 'text' | 'textarea';
    maxLength?: number;
  }) => {
    return (
      <TextFormInput
        {...form.register(key)}
        handleOnBlur={(e) => {
          if (!lead) {
            enqueueSnackbar('No lead found', { variant: 'error' });
            return;
          }
          if (resourceType === queryKeys.cases) {
            handleUpdateCase(key, e.target.value);
          } else if (resourceType === queryKeys.contacts) {
            handleUpdateContactValue(key, e.target.value);
          }
        }}
        title={title}
        type={fieldType}
        disabled={disabled}
        maxLength={maxLength}
      />
    );
  };

  const caseTypeItems: { [key: string]: string } = caseTypes
    ? caseTypes.reduce<{ [key: string]: string }>((acc, ct) => {
        if (ct.title) {
          acc[ct.title] = ct.caseTypeId;
        }
        return acc;
      }, {})
    : {};

  const caseStatusItems: { [key: string]: string } = caseStatuses
    ? caseStatuses
        .filter((cs) => {
          return cs.category === 'lead';
        })
        .reduce<{ [key: string]: string }>((acc, cs) => {
          if (cs.title) {
            acc[cs.title] = cs.caseStatusId;
          }
          return acc;
        }, {})
    : {};

  return (
    <div>
      {caseTypes && caseStatuses && lead?.caseId && !isLeadLoading && !isCaseTypesLoading ? (
        <Form {...form}>
          <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
            <div className="col-span-2 font-semibold">Lead</div>
            <SelectFormInput
              listItems={caseTypeItems}
              title={'Lead Type'}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateCase('caseTypeId', target.value);
              }}
              listItemsIsObject
              defaultValue={lead.caseTypeId}
              {...form.register('caseTypeId')}
              disabled={disabled}
            />
            <SelectFormInput
              listItems={caseStatusItems}
              title={'Lead Status'}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateCase('caseStatusId', target.value);
              }}
              listItemsIsObject
              defaultValue={lead.caseStatus?.caseStatusId}
              {...form.register('caseStatusId')}
              disabled={disabled}
            />
          </div>
          <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
            <div className="col-span-2 font-semibold">Contact</div>
            {renderTextFormInput({
              resourceType: queryKeys.contacts,
              key: 'firstName',
              title: 'First Name',
            })}
            {renderTextFormInput({
              resourceType: queryKeys.contacts,
              key: 'lastName',
              title: 'Last Name',
            })}
            <PhoneInput
              {...form.register(`number`)}
              formHandleBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateContactValue('phoneNumbers', target.value);
              }}
              title="Phone Number"
              disabled={disabled}
            />
            {renderTextFormInput({
              resourceType: queryKeys.contacts,
              key: 'email',
              title: 'Email',
            })}
          </div>
          <SectionCollapsible textVariant="default" textSize="sm" title="Additional Details">
            <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
              {renderTextFormInput({
                resourceType: queryKeys.contacts,
                key: 'nickname',
                title: 'Nickname',
                maxLength: 60,
              })}
              {renderTextFormInput({
                resourceType: queryKeys.contacts,
                key: 'maritalStatus',
                title: 'Marital Status',
                maxLength: 60,
              })}
              <CalendarFormInput
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateContactValue('dateOfBirth', target.value);
                }}
                title="Date of Birth"
                disabled={(date) => date > dayjs().toDate()}
                {...form.register(`dateOfBirth`)}
              />
            </div>
          </SectionCollapsible>
          <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
            <div className="col-span-2 font-semibold">Incident</div>
            <CalendarFormInput
              {...form.register(`dateOfIncident`)}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateCase('dateOfIncident', target.value);
              }}
              title="Date of Incident"
              disabled={disabled}
            />
            {renderTextFormInput({
              resourceType: queryKeys.cases,
              key: 'incidentStreet',
              title: 'Location (Street)',
            })}
            {renderTextFormInput({
              resourceType: queryKeys.cases,
              key: 'incidentCity',
              title: 'Location (City)',
            })}
            <SelectFormInput
              title="Location (State)"
              listItems={US_STATES}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateCase('incidentState', target.value);
              }}
              defaultValue={lead.incidentState}
              {...form.register('incidentState')}
              disabled={disabled}
            />
            <CalendarFormInput
              {...form.register(`statuteOfLimitations`)}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateCase('statuteOfLimitations', target.value);
              }}
              title="Statute of Limitations"
              disabled={disabled}
            />
            <span className="col-span-2">
              {renderTextFormInput({
                resourceType: queryKeys.cases,
                key: 'clientDescriptionOfIncident',
                title: 'Description of incident',
                fieldType: 'textarea',
              })}
            </span>
          </div>
          <SectionCollapsible textVariant="default" textSize="sm" title="Additional Details">
            <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
              {renderTextFormInput({
                resourceType: queryKeys.cases,
                key: 'incidentTimeOfDay',
                title: 'Time of Day',
                maxLength: 60,
              })}
              {renderTextFormInput({
                resourceType: queryKeys.cases,
                key: 'dayOfWeekIncidentOccurred',
                title: 'Day of the week',
                maxLength: 60,
              })}
              <Switch
                fullRow
                title="Working at time of Incident?"
                value={bitBToB(form.getValues('clientInScopeOfWorkAtTimeOfIncident') as BitBoolean)}
                handleChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  const value = bToBitB(!!target.value);
                  form.setValue('clientInScopeOfWorkAtTimeOfIncident', value, {
                    shouldDirty: true,
                  });
                  handleUpdateCase('clientInScopeOfWorkAtTimeOfIncident', value);
                }}
                {...form.register('clientInScopeOfWorkAtTimeOfIncident')}
              />
              <Switch
                fullRow
                title="Police Report?"
                value={bitBToB(form.getValues('incidentReports') as BitBoolean)}
                handleChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  const value = bToBitB(!!target.value);
                  form.setValue('incidentReports', value, {
                    shouldDirty: true,
                  });
                  handleUpdateCase('incidentReports', value);
                }}
                {...form.register('incidentReports')}
              />
              <Switch
                fullRow
                title="Authorities called to scene?"
                value={bitBToB(form.getValues('firstRespondersPresent') as BitBoolean)}
                handleChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  const value = bToBitB(!!target.value);
                  form.setValue('firstRespondersPresent', value, {
                    shouldDirty: true,
                  });
                  handleUpdateCase('firstRespondersPresent', value);
                }}
                {...form.register('firstRespondersPresent')}
              />
              {bitBToB(form.getValues('firstRespondersPresent') as BitBoolean) && (
                <div className="flex flex-col ml-2 gap-y-8">
                  <span className="ml-2">
                    <CaseContactConnectionLinker
                      caseId={lead.caseId}
                      title="First Responders"
                      showMultiple
                      roleOnCase={caseContactConnectionOptions.firstResponder}
                    />
                  </span>
                  <CheckboxFormInput
                    {...form.register('citationsIssued')}
                    handleOnChange={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleUpdateCase('citationsIssued', booleanToString(target.value));
                    }}
                    title="Citations Issued?"
                  />
                </div>
              )}
              <Switch
                fullRow
                title="Transported in an ambulance?"
                value={bitBToB(form.getValues('transportedByAmbulance') as BitBoolean)}
                handleChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  const value = bToBitB(!!target.value);
                  form.setValue('transportedByAmbulance', value, {
                    shouldDirty: true,
                  });
                  handleUpdateCase('transportedByAmbulance', value);
                }}
                {...form.register('transportedByAmbulance')}
              />
              {bitBToB(form.getValues('transportedByAmbulance') as BitBoolean) && (
                <span className="ml-4">
                  <CaseContactConnectionLinker
                    caseId={lead.caseId}
                    title="Ambulance"
                    roleOnCase={caseContactConnectionOptions.ambulance}
                  />
                </span>
              )}
              <Switch
                fullRow
                title="Transported to Hospital?"
                value={bitBToB(form.getValues('transportedToHospital') as BitBoolean)}
                handleChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  const value = bToBitB(!!target.value);
                  form.setValue('transportedToHospital', value, {
                    shouldDirty: true,
                  });
                  handleUpdateCase('transportedToHospital', value);
                }}
                {...form.register('transportedToHospital')}
              />
              {bitBToB(form.getValues('transportedToHospital') as BitBoolean) && (
                <span className="ml-4">
                  <CaseContactConnectionLinker
                    caseId={lead.caseId}
                    title="Hospital"
                    roleOnCase={caseContactConnectionOptions.hospital}
                  />
                </span>
              )}
              <span className="flex flex-col col-span-2 gap-y-8">
                <CaseContactConnectionLinker
                  caseId={lead.caseId}
                  title="Witness"
                  roleOnCase={caseContactConnectionOptions.witness}
                />
              </span>
            </div>
          </SectionCollapsible>
          <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
            <div className="col-span-2 font-semibold">Injuries</div>
            <span className="col-span-2">
              {renderTextFormInput({
                resourceType: queryKeys.cases,
                key: 'descriptionOfClientInjuries',
                title: 'Description of injuries',
                fieldType: 'textarea',
              })}
            </span>
          </div>
          <div className="col-span-2 font-semibold">Staff</div>
          <CaseStaff caseId={lead?.caseId} />
          <div className="col-span-2 font-semibold">Marketing Referral Source</div>
          <ReferralSourceSection labelClassName="text-md" caseData={lead} />
          <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
            <div className="flex items-center col-span-2 pb-64 mt-5">
              <h3 className="mr-6 font-semibold text-md">Tags</h3>
              <ResourceTags resourceId={lead?.caseId ?? ''} resourceType={'case'} />
            </div>
          </div>
        </Form>
      ) : (
        <div>
          <GladiateLoader />
        </div>
      )}
    </div>
  );
}

export default LeadForm;
