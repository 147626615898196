import { cn, getFullFileId } from '@colosseum/data';
import { FileResourceType } from '@gladiate/types';
import { ArrowTopRightOnSquareIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { enqueueSnackbar } from 'notistack';
import React, { useContext } from 'react';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../shadcn/HoverCard/HoverCard';
import { FileExplorerContext } from './FileExplorerProvider';
import { FileGridProps } from './FileGrid';
import FileGridItemIcon from './FileGridItemIcon';

export interface FileGridItemProps {
  item: FileResourceType;
  isListView: FileGridProps['isListView'];
  currentDirectoryId: FileGridProps['currentDirectoryId'];
  onFolderClick: FileGridProps['onFolderClick'];
  moveFileMutation: FileGridProps['moveFileMutation'];
  urlCopied: boolean;
  setUrlCopied: React.Dispatch<React.SetStateAction<boolean>>;
}

export function FileGridItem(props: FileGridItemProps) {
  const {
    item,
    isListView,
    onFolderClick,
    moveFileMutation: moveFile,
    currentDirectoryId,
    urlCopied,
    setUrlCopied,
  } = props;
  const { setIsFilePreviewModalOpen, setFilePreviewItem, setSelectedFiles, setMovingFiles } =
    useContext(FileExplorerContext);

  const onDragStartHandler = (event: React.DragEvent, item: FileResourceType) => {
    event.dataTransfer.setData('parentResourceId', item.parentResourceId ?? '');
    event.dataTransfer.setData('resourceId', item.resourceId ?? '');
  };

  const onDragOverHandler = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const onClickHandler = (e: any, item: FileResourceType) => {
    e.stopPropagation();
    e.preventDefault();

    if (item.resourceType === 'DIR') {
      onFolderClick && onFolderClick(item);
    }

    if (item.resourceType === 'FILE') {
      setFilePreviewItem(item);
      setIsFilePreviewModalOpen(true);
    }

    if (item.resourceType === 'LINK') {
      // nothing yet
    }
  };

  const onDropHandler = (event: React.DragEvent, targetFolder: FileResourceType) => {
    event.preventDefault();

    const parentId = event.dataTransfer.getData('parentResourceId') as string;
    const resourceId = event.dataTransfer.getData('resourceId') as string;

    const draggedFileItemId = `${parentId}-${resourceId}`;

    if (draggedFileItemId === getFullFileId(targetFolder)) {
      return;
    }

    setMovingFiles((prevState) => {
      return [...(prevState ?? []), { parentId, resourceId }];
    });

    moveFile
      .mutateAsync({
        fileItemToMove: draggedFileItemId,
        moveToDirectory: `${targetFolder.parentResourceId}-${targetFolder.resourceId}`,
        parentDirectory: currentDirectoryId,
      })
      .then(() => {
        enqueueSnackbar('File moved successfully', { variant: 'success' });
        setSelectedFiles([]);
      })
      .catch(() => {
        setMovingFiles((prevState) => {
          return (prevState ?? []).filter((item) => getFullFileId(item) !== draggedFileItemId);
        });

        enqueueSnackbar('Error moving file', { variant: 'error' });
      });
  };

  const renderItem = (item: FileResourceType) => {
    const sharedHandlers = {
      onClick: (e: React.SyntheticEvent) => onClickHandler(e, item),
      draggable: true,
      onDragStart: (event: React.DragEvent) => onDragStartHandler(event, item),
      onDragOver: onDragOverHandler,
      onDrop: (event: React.DragEvent) => onDropHandler(event, item),
    };

    const defaultContent = (
      <>
        <FileGridItemIcon item={item} isListView={isListView} />
        <div className={cn('truncate ml-4', !isListView && 'mt-2')}>{item.name}</div>
      </>
    );

    if (item.resourceType === 'LINK') {
      return (
        <HoverCard openDelay={0} closeDelay={100}>
          <HoverCardTrigger className={cn(isListView && 'flex items-center w-full')}>
            {isListView ? (
              <li
                {...sharedHandlers}
                className="flex items-center w-full py-2 pl-2 pr-4 rounded cursor-pointer hover:bg-gray-100"
              >
                {defaultContent}
              </li>
            ) : (
              <div {...sharedHandlers}>{defaultContent}</div>
            )}
          </HoverCardTrigger>
          <HoverCardContent className="w-72">
            <div className="flex items-center justify-between w-full">
              <div className="text-sm text-gray-500 truncate">{item.urlRefLink}</div>
              <div className="flex items-center">
                <button
                  onClick={() => {
                    if (
                      item.urlRefLink?.startsWith('http://') ||
                      item.urlRefLink?.startsWith('https://')
                    ) {
                      window.open(item.urlRefLink, '_blank');
                      return;
                    } else {
                      window.open(`http://${item.urlRefLink}`, '_blank');
                      return;
                    }
                  }}
                  className="mr-3 text-sky-blue"
                >
                  <ArrowTopRightOnSquareIcon className="w-5 h-5 hover:text-atlantic-blue" />
                </button>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(item.urlRefLink ?? '');
                    setUrlCopied(true);
                    setTimeout(() => setUrlCopied(false), 1500);
                  }}
                  className={cn(
                    ' text-sky-blue hover:text-atlantic-blue',
                    urlCopied && 'animate__animated animate__rubberBand',
                  )}
                >
                  <ClipboardDocumentIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          </HoverCardContent>
        </HoverCard>
      );
    }

    if (isListView) {
      return (
        <li
          {...sharedHandlers}
          className={cn(
            'flex items-center pr-4 pl-2 py-2 rounded cursor-pointer w-full hover:bg-gray-100',
            item.resourceType === 'DIR' && 'font-semibold',
          )}
        >
          {defaultContent}
        </li>
      );
    } else {
      return <div {...sharedHandlers}>{defaultContent}</div>;
    }
  };

  return renderItem(item);
}

export default FileGridItem;
