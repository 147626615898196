import { CaseSubType } from '@gladiate/types';

export const useCaseItemLink = (resourceId: string, resourceType: CaseSubType) => {
  let path = '';

  switch (resourceType) {
    case 'contact':
      path = `?contact-id=${resourceId}`;
      break;
    case 'caseSurvey':
    case 'agreement':
    case 'lien':
    case 'deadlines':
    case 'caseContactConnection':
    case 'medicalAppointment':
    case 'communicationLog':
      path = `?case-item-type=${resourceType}&case-item-id=${resourceId}`;
      break;
    case 'discovery':
    case 'expense':
    case 'medicalTreatment':
    case 'priorMedicalTreatment':
    case 'settlementProposal':
    case 'medicalRecordRequest':
    case 'policy':
    case 'vehicle':
    case 'trustAccountTransaction':
      path = `?case-item-type=${resourceType}&case-item-id=${
        resourceId?.split('-')[0] + '-' + resourceId?.split('-')[1]
      }`;
      break;
    default: {
      path = ``;
      break;
    }
  }

  return { path };
};
