import {
  casesSectionMappings,
  cn,
  enqueueAPISnackbar,
  scrollToCaseSection,
  tanstackTableNames,
  useCreateRecordRequest,
  useDeleteRecordRequest,
  useGetAuditsWithNotifications,
  useGetContactsInfinite,
  useGetFirmUserWithDisplayNameFromUsername,
  useGetRecordRequestsForCase,
  useGetRoleIdForItem,
} from '@colosseum/data';
import {
  DataTable,
  EntityAddPopover,
  GladiateLoader,
  ResourceSlideover,
  Slideover,
  SlideoverContext,
  TabsContent,
} from '@colosseum/shared-ui';
import {
  RecordRequest,
  caseContactConnectionOptions,
  manualRecordRequestLogMechanismOptions,
} from '@gladiate/types';
import { Row } from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import { ManualRecordRequestForm } from '../ManualRecordRequestForm.tsx/ManualRecordRequestForm';
import RecordRequestForm from '../RecordRequestForm/RecordRequestForm';
import ViewOnlyRecordRequestsForm from '../ViewOnlyRecordRequestsForm/ViewOnlyRecordRequestsForm';
import { requestColumns } from './record-requests-table-columns';

export interface FileObject {
  lastModified: number;
  name: string;
  size: number;
  type: string;
  uploadUrl: string;
  objKey: string;
}

export interface MedicalRecordRequestsProps {
  caseId: string;
}

export function MedicalRecordRequests(props: MedicalRecordRequestsProps) {
  const { caseId } = props;
  // #region Hooks
  // #endregion

  // #region Data Fetching
  const contactsQuery = useGetContactsInfinite();
  const recordRequestsQuery = useGetRecordRequestsForCase(caseId);
  const [viewOnlyRecordRequest, setViewOnlyRecordRequest] = useState<RecordRequest>();
  const audits = useGetAuditsWithNotifications({
    itemId: viewOnlyRecordRequest?.requestId,
  });
  const createManualRecordRequest = useCreateRecordRequest();
  // #endregion

  // #region State
  const [arctrievalSlideoverOpen, setArctrievalSlideoverOpen] = useState(false);
  const [manualRecordSlideoverOpen, setManualRecordSlideoverOpen] = useState(false);

  const [sendRecordRequest, setSendRecordRequest] = useState(false);
  const [updateRecordRequest, setUpdateRecordRequest] = useState(false);
  const [filter] = useState('all');
  const [viewOnlyOpen, setViewOnlyOpen] = useState(false);

  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);
  const [readyToSendRecordRequest, setReadyToSendRecordRequest] = useState(true);

  const deleteManualRecordRequest = useDeleteRecordRequest();
  // #endregion

  // #region Derived State

  const allContacts = contactsQuery?.data?.data;
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();
  const getRoleIdForItem = useGetRoleIdForItem<RecordRequest>(caseId);
  const recordRequests = recordRequestsQuery?.data?.data;
  const recordRequestsFiltered = recordRequests
    ?.map((record) => {
      const recordsProviderContactId = getRoleIdForItem(
        record,
        caseContactConnectionOptions.recordsProvider,
        'requestId',
      );
      const gladProvider = allContacts?.find(
        (contact) => contact.contactId === recordsProviderContactId,
      );

      const gladClient = allContacts?.find(
        (contact) => contact.contactId === record.recordsClientContactId,
      );
      const gladFirmUser = getFirmUserWithDisplayNameFromUsername(
        record.recordsRequestorUsername ?? '',
      ).displayName;
      return {
        ...record,
        recordsProvider: gladProvider,
        client: gladClient,
        requestingUser: gladFirmUser,
      };
    })
    ?.filter((request) => {
      if (filter === 'all') {
        return true;
      }

      if (request.recordsProviderContactId === filter) {
        return true;
      } else {
        return false;
      }
    });
  const [selectedManualRecordId, setSelectedManualRecordId] = useState<string>();
  const selectedManualRecord = recordRequests?.find(
    (request) => request.requestId === selectedManualRecordId,
  );

  // #endregion

  // #region useEffects
  useEffect(() => {
    if (
      pendingSlideoverToOpen?.type === 'medicalRecordRequest' &&
      !recordRequestsQuery?.isLoading
    ) {
      if (recordRequests?.find((t) => t.requestId === pendingSlideoverToOpen?.id)) {
        const ref = document.querySelectorAll(
          `[data-case-submenu-item='${casesSectionMappings.medicals}']`,
        )[0];
        scrollToCaseSection(ref, true);

        const recordRequest = recordRequests?.find(
          (t) => t.requestId === pendingSlideoverToOpen?.id,
        );
        if (recordRequest?.logMechanism === 'manual') {
          setSelectedManualRecordId(recordRequest.requestId);
          setManualRecordSlideoverOpen(true);
        } else {
          setViewOnlyRecordRequest(recordRequest);
          setViewOnlyOpen(true);
        }
      } else {
        enqueueAPISnackbar({
          message: 'Medical Record Request not found (it may have been deleted).',
          variant: 'error',
        });
      }
      setPendingSlideoverToOpen(undefined);
    }
  }, [pendingSlideoverToOpen, recordRequestsQuery?.isLoading]);
  // #endregion

  // #region Event Handlers

  // #endregion

  return (
    <div>
      <Slideover
        title={'Medical Record Requests'}
        description={'Create and review your records requests.'}
        open={arctrievalSlideoverOpen}
        setOpen={setArctrievalSlideoverOpen}
        typing={false}
        bottomButtons={
          <div className="flex items-center justify-between px-5 py-5 border-t border-t-gray-200">
            <button
              type="button"
              className="inline-flex justify-center px-6 py-2 text-sm font-medium border rounded-md shadow-sm text-sky-blue border-sky-blue hover:text-atlantic-blue hover:border-atlantic-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-atlantic-blue"
              onClick={() => {
                setArctrievalSlideoverOpen(false);
              }}
            >
              {sendRecordRequest ? <GladiateLoader height={20} /> : 'Cancel'}
            </button>
            <button
              type="submit"
              onClick={() => {
                setSendRecordRequest(true);
              }}
              disabled={!readyToSendRecordRequest}
              className={cn(
                'inline-flex justify-center px-6 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-blue',
                readyToSendRecordRequest
                  ? ' bg-sky-blue hover:bg-atlantic-blue '
                  : ' bg-gray-300 pointer-events-none',
              )}
            >
              {sendRecordRequest ? <GladiateLoader height={20} white={true} /> : 'Send Request'}
            </button>
          </div>
        }
      >
        <RecordRequestForm
          caseId={caseId}
          sendRecordRequest={sendRecordRequest}
          setSendRecordRequest={setSendRecordRequest}
          setReadyToSend={setReadyToSendRecordRequest}
          setSlideOverOpen={setArctrievalSlideoverOpen}
          slideOverOpen={arctrievalSlideoverOpen}
        />
      </Slideover>
      <ResourceSlideover
        title={'Medical Record Requests'}
        description={'Edit and review your records requests.'}
        open={viewOnlyOpen}
        setOpen={setViewOnlyOpen}
        displayDeleteButton={false}
        bottomButtons={
          <div className="flex items-center justify-between px-5 py-5">
            <button
              type="button"
              className="inline-flex justify-center px-6 py-2 text-sm font-medium border rounded-md shadow-sm text-sky-blue border-sky-blue hover:text-atlantic-blue hover:border-atlantic-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-atlantic-blue"
              onClick={() => {
                setViewOnlyOpen(false);
              }}
            >
              Close
            </button>
            <button
              type="submit"
              onClick={() => {
                setUpdateRecordRequest(true);
              }}
              className={cn(
                'inline-flex  justify-center px-6 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm',
                ' bg-sky-blue hover:bg-atlantic-blue  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-blue ',
              )}
            >
              Edit Request
            </button>
          </div>
        }
        createType="medicalRecordRequest"
        resourceId={viewOnlyRecordRequest?.requestId ?? ''}
        caseId={caseId}
        typing={false}
      >
        <TabsContent value="details">
          <ViewOnlyRecordRequestsForm
            viewOnlyRecordRequest={viewOnlyRecordRequest}
            setViewOnlyOpen={setViewOnlyOpen}
            updateRecordRequest={updateRecordRequest}
            setUpdateRecordRequest={setUpdateRecordRequest}
          />
        </TabsContent>
      </ResourceSlideover>

      <ResourceSlideover
        caseId={caseId}
        title="Manual Medical Record Requests"
        description="Edit and review your manual records requests."
        open={manualRecordSlideoverOpen}
        setOpen={setManualRecordSlideoverOpen}
        typing={false}
        displayDeleteButton={true}
        deleteFunction={() => {
          if (selectedManualRecordId) {
            deleteManualRecordRequest.mutateAsync(selectedManualRecordId);
          }
        }}
        onClose={() => setSelectedManualRecordId(undefined)}
        createType="medicalRecordRequest"
        resourceId={selectedManualRecordId ?? ''}
      >
        <TabsContent value="details">
          <ManualRecordRequestForm caseId={caseId} data={selectedManualRecord} />
        </TabsContent>
      </ResourceSlideover>
      <div className="flex items-center justify-end w-full pb-3 align-middle">
        <EntityAddPopover
          primary
          onAdd={() => {}}
          hideCreateNew
          hideSearch
          isLoading={createManualRecordRequest.isLoading || deleteManualRecordRequest.isLoading}
          title={'Add Request'}
          listItems={[
            {
              label: 'Manual Request',
              onClick: () => {
                createManualRecordRequest
                  .mutateAsync({
                    caseId: caseId,
                    logMechanism: manualRecordRequestLogMechanismOptions.manual,
                  })
                  .then((res) => {
                    setManualRecordSlideoverOpen(true);
                    setSelectedManualRecordId(res.data.requestId);
                  });
              },
            },
            {
              label: 'Arctrieval Request',
              onClick: () => {
                setArctrievalSlideoverOpen(true);
              },
            },
          ]}
          listHeight={80}
        />
      </div>
      <DataTable
        data={recordRequestsFiltered ?? []}
        showSearchBar
        initialSort={{
          id: 'Date Sent',
          desc: true,
        }}
        filters={[]}
        columns={requestColumns}
        handleRowClick={(item: Row<RecordRequest>) => {
          if (item.original.arctrievalRequestId) {
            setViewOnlyRecordRequest(item.original);
            setViewOnlyOpen(true);
            setSelectedManualRecordId(undefined);
            setManualRecordSlideoverOpen(false);
          } else {
            setSelectedManualRecordId(item.original.requestId);
            setManualRecordSlideoverOpen(true);
            setViewOnlyRecordRequest(undefined);
            setViewOnlyOpen(false);
          }
        }}
        isLoading={recordRequestsQuery.isLoading}
        isError={recordRequestsQuery.isError}
        tableName={tanstackTableNames.recordRequests}
      />
    </div>
  );
}

export default MedicalRecordRequests;
