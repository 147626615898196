import { useUpdateMedicalTreatmentAppointment } from '@colosseum/data';
import { MedicalTreatmentAppointment, caseContactConnectionOptions } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { TabsContent } from '@radix-ui/react-tabs';
import { SetStateAction, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import CaseContactConnectionLinker from '../CaseContactConnectionLinker/CaseContactConnectionLinker';
import ClientSelectDropdown from '../ClientSelectDropdown/ClientSelectDropdown';
import ResourceSlideover from '../ResourceSlideover/ResourceSlideover';
import { SlideoverProps } from '../Slideover/Slideover';
import { SlideoverContext } from '../SlideoverProvider';
import CalendarFormInput from '../forms/CalendarFormInput/CalendarFormInput';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';

const formSchema = z.object({
  description: z.string().optional(),
  date: z.string().optional(),
  medicalTreatmentTypeId: z.string().optional(),
});

interface MedicalTreatmentAppointmentsSlideoverProps {
  caseId: string;
  medicalTreatmentAppointment: MedicalTreatmentAppointment | undefined;
  open: boolean;
  setOpen: (value: SetStateAction<boolean>) => void;
  treatmentTypeOptions?: Record<string, string>;
  data: MedicalTreatmentAppointment[];
  isDataLoading: boolean;
}

export const MedicalTreatmentAppointmentsSlideover = (
  props: MedicalTreatmentAppointmentsSlideoverProps & Partial<SlideoverProps>,
) => {
  const {
    caseId,
    medicalTreatmentAppointment,
    open,
    setOpen,
    treatmentTypeOptions,
    data,
    isDataLoading,
    ...rest
  } = props;
  const [appointment, setAppoinment] = useState<MedicalTreatmentAppointment | undefined>(
    medicalTreatmentAppointment,
  );
  // #region Hooks
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: medicalTreatmentAppointment || appointment,
  });
  const updateAppointment = useUpdateMedicalTreatmentAppointment();
  const { setPendingSlideoverToOpen, pendingSlideoverToOpen } = useContext(SlideoverContext);
  // #endregion

  // #region Data Fetching

  // #endregion

  // #region State

  // #endregion

  // #region Derived State
  // #endregion

  // #region useEffects
  // #endregion

  // #region Event Handlers
  function handleSubmit(data: z.infer<typeof formSchema>) {
    updateAppointment.mutateAsync({
      medicalTreatmentAppointmentId:
        medicalTreatmentAppointment?.medicalTreatmentAppointmentId ?? '',
      ...data,
    });
  }
  // #endregion

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'medicalAppointment') {
      const appointment = data?.find(
        (appointment) => appointment.medicalTreatmentAppointmentId === pendingSlideoverToOpen.id,
      );
      setAppoinment(appointment);
      setOpen(true);
      !isDataLoading && setPendingSlideoverToOpen(undefined);
    }
  }, [pendingSlideoverToOpen, data]);

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'medicalAppointment') {
      const appointment = data?.find(
        (appointment) => appointment.medicalTreatmentAppointmentId === pendingSlideoverToOpen.id,
      );
      setAppoinment(appointment);
      setOpen(true);
      !isDataLoading && setPendingSlideoverToOpen(undefined);
    }
  }, [pendingSlideoverToOpen, data]);

  return (
    <ResourceSlideover
      caseId={caseId}
      open={open}
      setOpen={setOpen}
      title="Medical Treatment Appointment Details"
      description={'Add a new medical treatment appointment or update an existing one.'}
      {...rest}
      resourceId={
        medicalTreatmentAppointment?.medicalTreatmentAppointmentId ??
        appointment?.medicalTreatmentAppointmentId ??
        ''
      }
      createType="medicalAppointment"
    >
      <TabsContent value="details">
        <Form {...form}>
          <form onBlur={form.handleSubmit(handleSubmit)}>
            <div className="flex flex-col gap-4">
              {medicalTreatmentAppointment?.medicalTreatmentAppointmentId && (
                <ClientSelectDropdown
                  caseId={props.caseId}
                  form={form}
                  caseItemType="medicalAppointment"
                  caseItemId={medicalTreatmentAppointment.medicalTreatmentAppointmentId}
                />
              )}

              <SelectFormInput
                {...form.register('medicalTreatmentTypeId')}
                title="Type of Treatment"
                listItems={treatmentTypeOptions ?? {}}
                listItemsIsObject
                defaultValue={medicalTreatmentAppointment?.medicalTreatmentTypeId}
                triggerClassName="h-[50px]"
                placeholderText="Select a type"
                handleOnChange={(e: React.SyntheticEvent) => {}}
              />

              <TextFormInput
                type="textarea"
                {...form.register('description')}
                title="Appointment Description"
              />
              <CaseContactConnectionLinker
                caseId={caseId}
                title="Medical Provider"
                roleOnCase={caseContactConnectionOptions.medicalProvider}
                itemType="medicalAppointment"
                itemId={medicalTreatmentAppointment?.medicalTreatmentAppointmentId}
              />
              <CalendarFormInput
                {...form.register('date')}
                title="Appointment Date"
                resourceTypeObj={{
                  type: 'medical-treatment-appointment',
                  id: medicalTreatmentAppointment?.medicalTreatmentAppointmentId,
                }}
              />
            </div>
          </form>
        </Form>
      </TabsContent>
    </ResourceSlideover>
  );
};
