import {
  DefaultV3Response,
  FileResourceTemplateDescriptionType,
  FileResourceTemplateType,
  FileResourceType,
  ResourceAttachmentType,
  ResourceFileAttachmentType,
  S3FileLinkType,
} from '@gladiate/types';
import { VITE_API_STAGE } from '../../static/importMetaEnv';
import { axiosInstance } from '../https';

const API_STAGE = VITE_API_STAGE;

const baseTabulariumRoute = `/tabularium/${API_STAGE}/v3`;

export const getFileResourceV3 = (resourceObjIds: string) =>
  axiosInstance
    .get<DefaultV3Response<FileResourceType>>(
      `${baseTabulariumRoute}/cases/resources/${resourceObjIds}`,
    )
    .then((res) => res.data);

export const getExhaustiveFileResourceItemsV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<FileResourceType>>(
      `${baseTabulariumRoute}/cases/${caseId}/resources/exhaustive`,
    )
    .then((res) => res.data);

export const getFirmFileRepoTemplatesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<FileResourceTemplateDescriptionType[]>>(
      `${baseTabulariumRoute}/templates/descriptions`,
    )
    .then((res) => res.data);

export const getFileRepoTemplateExhaustiveV3 = (templateId: string) =>
  axiosInstance
    .get<
      DefaultV3Response<{
        templateResourceItem: FileResourceTemplateType;
        templateDescriptionItem: FileResourceTemplateDescriptionType;
      }>
    >(`${baseTabulariumRoute}/templates/exhaustive/${templateId}`)
    .then((res) => res.data);

export const createFileRepoTemplateItemV3 = (data: FileResourceTemplateType) =>
  axiosInstance.post<DefaultV3Response<FileResourceTemplateType>>(
    `${baseTabulariumRoute}/templates`,
    data,
  );

export const createFileRepoTemplateDescriptionV3 = (
  templateResourceId: string,
  data: FileResourceTemplateDescriptionType,
) =>
  axiosInstance.post<DefaultV3Response<FileResourceTemplateDescriptionType>>(
    `${baseTabulariumRoute}/templates/descriptions/${templateResourceId}`,
    data,
  );

export const deleteFileRepoTemplateItemV3 = (templateObjIds: string) =>
  axiosInstance.delete<DefaultV3Response<FileResourceTemplateType>>(
    `${baseTabulariumRoute}/templates/${templateObjIds}`,
  );

export const renameFileRepoTemplateItemV3 = (templateObjIds: string, data: { name: string }) =>
  axiosInstance.patch<DefaultV3Response<FileResourceTemplateType>>(
    `${baseTabulariumRoute}/templates/${templateObjIds}/rename`,
    data,
  );

export const updateFileRepoTemplateDescriptionV3 = (data: FileResourceTemplateDescriptionType) => {
  const { templateResourceId, ...rest } = data;
  return axiosInstance.patch<DefaultV3Response<FileResourceTemplateDescriptionType>>(
    `${baseTabulariumRoute}/templates/descriptions/${templateResourceId}`,
    rest,
  );
};

export const createFileResourceItemV3 = (caseId: string, data: FileResourceType) =>
  axiosInstance.post<DefaultV3Response<FileResourceType>>(
    `${baseTabulariumRoute}/cases/${caseId}/resources`,
    data,
  );

export const renameFileResourceItemV3 = (
  caseId: string,
  resourceObjIds: string,
  data: { name: string },
) =>
  axiosInstance.patch<DefaultV3Response<FileResourceType>>(
    `${baseTabulariumRoute}/cases/${caseId}/resources/${resourceObjIds}/rename`,
    data,
  );

export const moveFileResourceItemV3 = (
  caseId: string,
  resourceObjIds: string,
  data: FileResourceType,
) =>
  axiosInstance.patch<DefaultV3Response<FileResourceType>>(
    `${baseTabulariumRoute}/cases/${caseId}/resources/${resourceObjIds}/move`,
    data,
  );

export const deleteFileResourceItemV3 = (caseId: string, resourceObjIds: string) =>
  axiosInstance.delete<DefaultV3Response<FileResourceType>>(
    `${baseTabulariumRoute}/cases/${caseId}/resources/${resourceObjIds}`,
  );

export const getResourceAttachmentsV3 = (resourceId: string) =>
  axiosInstance.get<DefaultV3Response<ResourceFileAttachmentType[]>>(
    `${baseTabulariumRoute}/${resourceId}/attachments`,
  );

export const createResourceAttachmentV3 = ({
  id,
  resourceId, // apparently we are using resourceId for the fileResourceId
  type,
}: {
  id: string;
  resourceId: string;
  type: ResourceAttachmentType;
}) =>
  axiosInstance.post<DefaultV3Response<ResourceFileAttachmentType>>(
    `${baseTabulariumRoute}/${id}/attachments`,
    { type, resourceId },
  );

export const deleteResourceAttachmentV3 = (attachmentId: string) =>
  axiosInstance.delete<DefaultV3Response<ResourceFileAttachmentType>>(
    `${baseTabulariumRoute}/attachments/${attachmentId}`,
  );

export const getDownloadFileLinkV3 = (objKey?: string) =>
  axiosInstance
    .get<DefaultV3Response<S3FileLinkType>>(`${baseTabulariumRoute}/download-url/${objKey}`)
    .then((res) => res.data);

export const getUploadFileLinkV3 = (extension: string) =>
  axiosInstance
    .get<DefaultV3Response<S3FileLinkType>>(`${baseTabulariumRoute}/upload-url/.${extension}`)
    .then((res) => res.data);
