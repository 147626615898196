import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import { getPoliciesForFirmV3 } from '../requests/petorritum';

export const useGetFirmPolicies = () => {
  return useQuery({
    queryKey: [queryKeys.policies],
    queryFn: async () => getPoliciesForFirmV3(),
  });
};
