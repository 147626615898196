import { ItemTagConnectionType, TagType } from '@gladiate/types';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Typography from '../Typography/Typography';
import { Button } from '../shadcn/Button/Button';

/* eslint-disable-next-line */
export interface TagProps {
  color: TagType['color'];
  title: TagType['title'];
  itemTagConnectionId: ItemTagConnectionType['itemTagConnectionId'];
  isViewOnly?: boolean;
  handleDeleteItemTagConnection: (arg0: string) => void;
}

export function Tag({
  color,
  title,
  itemTagConnectionId,
  handleDeleteItemTagConnection,
  isViewOnly,
}: TagProps) {
  return (
    <div
      style={{ backgroundColor: color }}
      className="flex items-center px-2 py-1 mr-2 text-sm text-white rounded-full first:ml-0"
    >
      <Typography className="mr-2 truncate">{title}</Typography>
      {!isViewOnly && (
        <Button
          variant="ghost"
          size="unset"
          onClick={() => {
            handleDeleteItemTagConnection(itemTagConnectionId);
          }}
        >
          <XMarkIcon className="w-3 h-3" />
        </Button>
      )}
    </div>
  );
}

export default Tag;
