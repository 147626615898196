import {
  useCreateMedicalTreatmentAppointment,
  useDeleteMedicalTreatmentAppointment,
  useGetCase,
  useGetContactsInfinite,
  useGetMedicalTreatmentAppointments,
  useGetMedicalTreatmentTypes,
  useGetRoleIdForItem,
} from '@colosseum/data';
import { caseContactConnectionOptions, type MedicalTreatmentAppointment } from '@gladiate/types';
import { Row } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { invert } from 'lodash';
import { useState } from 'react';
import CreateButton from '../CreateButton/CreateButton';
import { DataTable } from '../shadcn/data-table/DataTable';
import { MedicalTreatmentAppointmentsSlideover } from './MedicalTreatmentAppointmentsSlideover';
import { columns } from './medical-treatment-appointments-table-columns';

export interface MedicalTreatmentAppointmentsTableProps {
  caseId: string;
}

export function MedicalTreatmentAppointmentsTable(props: MedicalTreatmentAppointmentsTableProps) {
  // #region Hooks
  const { caseId } = props;
  const createAppointment = useCreateMedicalTreatmentAppointment();
  const deleteAppointment = useDeleteMedicalTreatmentAppointment();
  const caseData = useGetCase(caseId ?? '');
  // #endregion

  // #region Data Fetching
  const { data: medicalTreatmentAppointmentsData, isLoading: isMedicalTreatmentsLoading } =
    useGetMedicalTreatmentAppointments(caseId ?? '');

  const getRoleIdForItem = useGetRoleIdForItem<MedicalTreatmentAppointment>(caseId);
  const { data: allContacts, isLoading: isContactsLoading } = useGetContactsInfinite();

  const { data: medicalTreatmentTypesData } = useGetMedicalTreatmentTypes();

  const medicalTreatmentAppointments = medicalTreatmentAppointmentsData?.data.map((appointment) => {
    const medicalProviderId = getRoleIdForItem(
      appointment,
      caseContactConnectionOptions.medicalProvider,
      'medicalTreatmentAppointmentId',
    );
    const medicalProvider = allContacts?.data?.find(
      (contact) => contact.contactId === medicalProviderId,
    );
    const clientIdForAppointment = getRoleIdForItem(
      appointment,
      caseContactConnectionOptions.client,
      'medicalTreatmentAppointmentId',
    );
    const client = allContacts?.data?.find(
      (contact) => contact.contactId === clientIdForAppointment,
    );
    const medicalTreatmentType = medicalTreatmentTypesData?.data.find(
      (type) => type.medicalTreatmentTypeId === appointment.medicalTreatmentTypeId,
    )?.title;
    return {
      ...appointment,
      medicalProvider,
      client,
      medicalTreatmentType,
    };
  });
  // #endregion

  // #region State
  const [slideoverOpen, setSlideoverOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<MedicalTreatmentAppointment>();
  // #endregion

  // #region Derived State
  const medicalTreatmentTypeOptions = medicalTreatmentTypesData?.data
    .filter((treatmentType) => treatmentType.title)
    .reduce((acc, treatmentType) => {
      return {
        ...acc,
        [treatmentType.title ?? '']: treatmentType.medicalTreatmentTypeId,
      };
    }, {});

  const sortedPastAppointments = medicalTreatmentAppointmentsData?.data
    .filter((appointment) => appointment.date < new Date().toISOString())
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  const caseOpenDate = dayjs(caseData.data?.data.caseOpenDate);
  const firstAppointment = sortedPastAppointments?.[0]?.date
    ? dayjs(sortedPastAppointments?.[0]?.date)
    : null;
  const mostRecentAppointment = dayjs(
    sortedPastAppointments?.[sortedPastAppointments.length - 1]?.date,
  );
  const daysToFirstAppointment = !firstAppointment
    ? 0
    : firstAppointment.diff(caseOpenDate, 'days');
  const daysFromMostRecentAppointment = dayjs().diff(mostRecentAppointment, 'days');
  // #endregion

  // #region useEffects
  // #endregion

  // #region Event Handlers
  function handleRowClick(e: Row<MedicalTreatmentAppointment>) {
    setSlideoverOpen(true);
    setSelectedAppointment(e.original);
  }

  function handleCreateAppointment() {
    createAppointment
      .mutateAsync({
        caseId: caseId ?? '',
      })
      .then((res) => {
        setSelectedAppointment(res.data);
        setSlideoverOpen(true);
      });
  }

  // #endregion
  return (
    <>
      <div className="pl-4 font-semibold underline">Treatment Summary</div>
      <table className="border-separate border-spacing-x-4">
        <tbody>
          <tr>
            <td className="text-gray-500">{'Days to first treatment: '}</td>
            <td className="text-right">{daysToFirstAppointment}</td>
          </tr>
          <tr>
            <td className="text-gray-500">{'Days since last treatment: '}</td>
            <td className="text-right">{daysFromMostRecentAppointment}</td>
          </tr>
        </tbody>
      </table>
      <MedicalTreatmentAppointmentsSlideover
        caseId={caseId}
        open={slideoverOpen}
        setOpen={setSlideoverOpen}
        displayDeleteButton={true}
        deleteFunction={() =>
          deleteAppointment.mutateAsync(selectedAppointment?.medicalTreatmentAppointmentId ?? '')
        }
        medicalTreatmentAppointment={selectedAppointment}
        treatmentTypeOptions={medicalTreatmentTypeOptions}
        data={medicalTreatmentAppointments ?? []}
        isDataLoading={isMedicalTreatmentsLoading || isContactsLoading}
      />
      <DataTable
        data={medicalTreatmentAppointments ?? []}
        columns={columns}
        showSearchBar
        handleRowClick={handleRowClick}
        isLoading={isMedicalTreatmentsLoading}
        tableName="MedicalTreatmentAppointments"
        customRightButton={
          <CreateButton
            title={'Add Appointment'}
            loading={createAppointment.isLoading}
            onClick={handleCreateAppointment}
            dataCy="create-appointment-button"
            className="ml-2"
          />
        }
        meta={{
          treatmentTypeOptions: medicalTreatmentTypeOptions && invert(medicalTreatmentTypeOptions),
        }}
        initialSort={{
          id: 'Appointment Date',
          desc: true,
        }}
      />
    </>
  );
}
