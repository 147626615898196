export const casesSectionMappings = {
  accounting: 'Accounting',
  calendar: 'Calendar',
  closing: 'Closing',
  collaborationHub: 'Collaboration Hub',
  customAttributes: 'Custom Attributes',
  files: 'Files',
  incident: 'Incident',
  litigation: 'Litigation',
  medicals: 'Medicals',
  negotiations: 'Negotiations',
  summary: 'Summary',
} as const;
