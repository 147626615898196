import { getFullFileId, useCreateResourceAttachment } from '@colosseum/data';
import { FileResourceType, ResourceAttachmentType } from '@gladiate/types';
import { PaperClipIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import FileAttachmentModal from '../FileAttachmentModal/FileAttachmentModal';
import { Button } from '../shadcn/Button/Button';

/* eslint-disable-next-line */
export interface ResourceFileAttacherProps {
  caseId: string;
  type: ResourceAttachmentType;
  resourceId: string;
  onLoadingStateChange?: (loading: boolean) => void;
}

export function ResourceFileAttacher(props: ResourceFileAttacherProps) {
  const { caseId, type, resourceId, onLoadingStateChange } = props;

  const [showFileModal, setShowFileModal] = useState(false);
  const [filesToAttach, setFilesToAttach] = useState<FileResourceType[]>([]);

  const createResourceAttachment = useCreateResourceAttachment(resourceId);

  useEffect(() => {
    if (onLoadingStateChange) {
      onLoadingStateChange(createResourceAttachment.isLoading);
    }
  }, [createResourceAttachment.isLoading]);

  return (
    <>
      <FileAttachmentModal
        caseId={caseId}
        showFileExplorer={showFileModal}
        setShowFileExplorer={setShowFileModal}
        onAttachFiles={() => {
          filesToAttach.forEach((fileToAttach) => {
            createResourceAttachment.mutateAsync({
              resourceId: getFullFileId(fileToAttach), // this is somewhat confusing, but the fileId is the resourceId because of the backend implementation
              type,
            });
          });
        }}
        onSelectedFilesChange={(files) => {
          setFilesToAttach(files);
        }}
      />
      <Button
        variant="outline"
        className="h-[26px] w-[26px] p-1"
        onClick={() => setShowFileModal(true)}
      >
        <PaperClipIcon className="w-4 h-4" />
      </Button>
    </>
  );
}

export default ResourceFileAttacher;
