import {
  cn,
  tanstackTableNames,
  useCreateStaffRole,
  useDeleteStaffRole,
  useGetStaffRoles,
  useUpdateStaffRole,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  Form,
  GladiateLoader,
  Slideover,
  TextFormInput,
  getInitialVisibilityForTable,
} from '@colosseum/shared-ui';
import { StaffRoleType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Row } from '@tanstack/react-table';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { columns } from './StaffRolesTableColumns';

/* eslint-disable-next-line */
export interface StaffRolesProps {}

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
});

export function StaffRoles(props: StaffRolesProps) {
  const {
    data: staffRolesData,
    isLoading: isStaffRolesLoading,
    isError: isStaffRolesError,
  } = useGetStaffRoles();
  const createStaffRole = useCreateStaffRole();
  const updateStaffRole = useUpdateStaffRole();
  const deleteStaffRole = useDeleteStaffRole();
  const [openStaffRole, setOpenStaffRole] = useState<StaffRoleType | undefined>();
  const [open, setOpen] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: {
      title: openStaffRole?.title || '',
      description: openStaffRole?.description || '',
    },
  });
  const typing = createStaffRole.isLoading || updateStaffRole.isLoading;
  const staffRoles = staffRolesData?.data;

  const handleCreate = () => {
    createStaffRole.mutateAsync({}).then((res) => {
      setOpenStaffRole(res.data);
      setOpen(true);
    });
  };

  function handleUpdate(key: string, value: string) {
    updateStaffRole.mutate({
      roleId: openStaffRole?.roleId ?? '',
      [key]: value,
    });
  }

  useEffect(() => {
    if (!open) {
      form.reset();
      setOpenStaffRole(undefined);
    }
  }, [open, form]);

  return (
    <>
      <Slideover
        displayDeleteButton={true}
        open={open}
        setOpen={setOpen}
        deleteFunction={() => {
          if (openStaffRole?.roleId)
            deleteStaffRole.mutateAsync(openStaffRole?.roleId).then(() => {
              setOpenStaffRole(undefined);
              setOpen(false);
            });
        }}
        title="Staff Role"
        description="Update your Staff Role information here."
        typing={typing}
      >
        <Form {...form}>
          <form>
            <div className="flex flex-col mb-2 gap-x-3 gap-y-5">
              <TextFormInput
                {...form.register(`title`)}
                title="Title"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('title', target.value);
                }}
              />
              <TextFormInput
                {...form.register(`description`)}
                title="Description"
                type="textarea"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('description', target.value);
                }}
              />
            </div>
          </form>
        </Form>
      </Slideover>
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 id="staff-roles" className="text-xl font-semibold text-gray-900">
              Staff Role
            </h1>
            <p className="mt-2 text-sm text-gray-700">{`Your firm's staff roles.`}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              title={'Create new Role'}
              loading={createStaffRole.isLoading}
              onClick={handleCreate}
            />
          </div>
        </div>
        <DataTable
          data={staffRolesData?.data || []}
          initialSort={{
            id: 'Title',
            desc: false,
          }}
          columns={columns}
          isLoading={isStaffRolesLoading}
          isError={isStaffRolesError}
          tableName={tanstackTableNames.staffRoles}
          handleRowClick={(item: Row<StaffRoleType>) => {
            setOpenStaffRole(item.original);
            setOpen(true);
          }}
          initialVisibility={getInitialVisibilityForTable(tanstackTableNames.staffRoles, {})}
          hideViewButton
        />
      </div>
    </>
  );
}

export default StaffRoles;
