import {
  displayContactName,
  getCaseContactConnectionRoleIdForParty,
  getPolicyValueLines,
  useCreateCaseContactConnection,
  useCreateItemConnectionForScenario,
  useCreateVehicleForScenario,
  useDeletePolicy,
  useGetContactsInfinite,
  useGetRoleIdForItem,
} from '@colosseum/data';
import { ActionConfirmModal, Button, EntityAddPopover, Typography } from '@colosseum/shared-ui';
import {
  CaseContactConnectionRoleType,
  ContactType,
  PolicyType,
  ScenarioType,
  VehicleType,
  partiesAndPoliciesConnectionOptions,
} from '@gladiate/types';
import { ChevronDownIcon, ChevronRightIcon, PencilIcon } from '@heroicons/react/24/outline';
import { Row } from '@tanstack/react-table';
import { startCase } from 'lodash';
import { TrashIcon } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useState } from 'react';
import { handleCreateConnectionProps } from './PartiesAndPolicies';
import LinkedPartiesTable from './linked-entity-tables/LinkedPartiesTable';
import LinkedVehiclesTable from './linked-entity-tables/LinkedVehiclesTable';

export const PolicyTitle = (props: {
  insurer?: ContactType;
  policy: PolicyType;
  size: 'sm' | 'md' | 'lg';
  placeholderText?: string;
}) => {
  const { insurer, policy, size, placeholderText } = props;
  return (
    <div className="flex">
      <Typography variant="semiBold" size={size} color={insurer ? 'default' : 'red'}>
        {insurer ? displayContactName(insurer) : placeholderText ?? '<No Insurer>'}
      </Typography>
      <Typography variant="semiBold" size={size} className="mx-1">
        {'/'}
      </Typography>

      <Typography variant="semiBold" size={size} color={policy.policyType ? 'default' : 'red'}>
        {policy.policyType ? startCase(policy.policyType) : placeholderText ?? '<No Policy Type>'}
      </Typography>
      <Typography variant="semiBold" size={size} className="mx-1">
        {'/'}
      </Typography>
      <Typography variant="semiBold" size={size} color={policy.type ? 'default' : 'red'}>
        {policy.type ?? placeholderText ?? '<No Insurance Type>'}
      </Typography>
    </div>
  );
};

export interface PolicyCardProps {
  caseId: string;
  policy: PolicyType;
  parties: (ContactType & { roles: CaseContactConnectionRoleType[] })[];
  scenario?: ScenarioType;
  setNewContactId: Dispatch<SetStateAction<string | undefined>>;
  setSelectedPolicyId: Dispatch<SetStateAction<string | undefined>>;
  setSelectedVehicleId: Dispatch<SetStateAction<string | undefined>>;
  setContactSlideoverOpen: Dispatch<SetStateAction<boolean>>;
  setPolicySlideoverOpen: Dispatch<SetStateAction<boolean>>;
  setVehicleSlideoverOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedConnectionId: Dispatch<SetStateAction<string | undefined>>;
  handleCreateConnection: (options: handleCreateConnectionProps) => void;
  handleLinkedPartiesRowClick: (
    row: Row<ContactType>,
    parentEntity: {
      type: 'policy';
      id: string;
    },
  ) => void;
  handleLinkedVehiclesRowClick: (
    row: Row<VehicleType>,
    parentEntity: {
      type: 'policy';
      id: string;
    },
  ) => void;
}

export const PolicyCard = (props: PolicyCardProps) => {
  const {
    caseId,
    policy,
    parties,
    scenario,
    setSelectedPolicyId,
    setSelectedVehicleId,
    setContactSlideoverOpen,
    setVehicleSlideoverOpen,
    setPolicySlideoverOpen,
    setNewContactId,
    handleCreateConnection,
    handleLinkedPartiesRowClick,
    handleLinkedVehiclesRowClick,
  } = props;

  const [partiesTableOpen, setPartiesTableOpen] = useState(false);
  const [vehicleTableOpen, setVehicleTableOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [policyToDelete, setPolicyToDelete] = useState<PolicyType>();
  const [policiesLoading, setPoliciesLoading] = useState('');
  const [vehiclesLoading, setVehiclesLoading] = useState('');

  // Data fetching
  const { data: allContactsData } = useGetContactsInfinite();
  const allContacts = allContactsData?.data;
  const createCaseContactConnection = useCreateCaseContactConnection();
  const deletePolicy = useDeletePolicy();
  const createVehicle = useCreateVehicleForScenario();
  const createItemConnection = useCreateItemConnectionForScenario();
  const vehiclesForPolicy =
    scenario?.itemConnections
      ?.filter(
        (itemConnection) => itemConnection.policyId === policy.policyId && itemConnection.vehicleId,
      )
      ?.map((itemConnection) =>
        scenario?.vehicles?.find((vehicle) => vehicle.vehicleId === itemConnection.vehicleId),
      ) ?? [];

  const partiesForPolicy = scenario?.itemConnections
    ?.filter(
      (itemConnection) => itemConnection.policyId === policy.policyId && itemConnection.partyId,
    )
    ?.map((itemConnection) =>
      parties?.find(
        (contact) => getCaseContactConnectionRoleIdForParty(contact) === itemConnection.partyId,
      ),
    )
    .filter((party) => !!party);
  const getRoleIdForItem = useGetRoleIdForItem<PolicyType>(caseId);
  const insurerForPolicy = getRoleIdForItem(policy, 'insurer', 'policyId');
  const insurer = allContacts?.find((contact) => contact.contactId === insurerForPolicy);

  const valueLines = getPolicyValueLines(policy);
  const valueLinesWithClaimAndType = [
    {
      title: 'Coverage Status',
      value: policy.coverageStatus ? startCase(policy.coverageStatus) : '-',
    },
    ...valueLines,
    { title: 'Claim', value: policy.claimNumber ?? '-' },
    { title: 'Policy Number', value: policy.policyNumber },
  ].filter((item) => item.value);

  return (
    <div className="relative py-7">
      <div className="flex items-center justify-between">
        <div className="w-full">
          <div className="flex">
            <PolicyTitle insurer={insurer} policy={policy} size="lg" />
          </div>
          <div className="text-sm text-gray-500">
            <table className="table flex-col max-w-[80%] mx-2 mt-2 overflow-y-auto gap-x-1 -scroll-mx-2">
              {valueLinesWithClaimAndType.map((line) => (
                <tr className="table-row" key={line.title}>
                  <td className="pb-2 pr-4 text-xs typography-gray-upper">{line.title}</td>
                  <td className="pb-2 text-xs font-bold text-left">{line.value}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div className="absolute flex items-center gap-2 top-3 right-3">
          <Button
            onClick={() => {
              setSelectedPolicyId(policy?.policyId);
              setPolicySlideoverOpen(true);
            }}
            variant="outline"
            className="p-1 h-[26px]"
          >
            <PencilIcon className="w-4 h-4" />
          </Button>
          <Button
            onClick={() => {
              setPolicyToDelete(policy);
              setDeleteModalOpen(true);
            }}
            variant="destructive"
            className="p-1 h-[26px]"
          >
            <TrashIcon className="w-4 h-4" />
          </Button>
        </div>
      </div>
      <div className="mt-4 space-y-2">
        <div>
          <div className="flex items-center justify-between gap-x-2">
            <Button
              variant="ghost"
              size="unset"
              textColor="primary"
              className="flex items-center py-2 pr-2 gap-x-1 text-md"
              onClick={() => setPartiesTableOpen(!partiesTableOpen)}
            >
              {partiesTableOpen ? (
                <ChevronDownIcon strokeWidth={1.8} className="w-5 h-5" />
              ) : (
                <ChevronRightIcon strokeWidth={1.8} className="w-5 h-5" />
              )}
              Parties
              <div className="px-2 tabular-nums py-0.5 ml-2 text-xs text-white bg-atlantic-blue rounded-full text-bold">
                {partiesForPolicy?.length}
              </div>
            </Button>
            {partiesTableOpen && (
              <EntityAddPopover
                placeholder={`Search ${allContacts?.length ?? 0} parties...`}
                title="Link Party"
                onAdd={() => {
                  setPoliciesLoading(policy.policyId);
                  createCaseContactConnection
                    .mutateAsync({
                      caseId: caseId,
                      roleOnCase: partiesAndPoliciesConnectionOptions.party,
                    })
                    .then((res) => {
                      createItemConnection.mutate({
                        caseId: caseId,
                        partyId: res?.data?.caseContactConnectionRole?.caseContactConnectionRoleId,
                        policyId: policy.policyId,
                      });
                      setNewContactId(res?.data.contactId);
                      setContactSlideoverOpen(true);
                      setPoliciesLoading('');
                    });
                }}
                isLoading={policiesLoading === policy.policyId}
                listItems={
                  allContacts
                    ?.filter((contact) => {
                      //if they have either a first or last name, show them
                      return contact.firstName || contact.lastName || contact.name;
                    })
                    ?.map((contact) => {
                      const matchingContact = parties?.find(
                        (party) => party.contactId === contact.contactId,
                      );
                      return {
                        label: displayContactName(contact),
                        onClick: () => {
                          if (
                            scenario?.itemConnections
                              ?.filter(
                                (itemConnection) =>
                                  itemConnection.policyId === policy.policyId &&
                                  itemConnection.partyId,
                              )
                              // if policy is already connected to this contact
                              .some((itemConnection) => {
                                return (
                                  itemConnection.partyId ===
                                  getCaseContactConnectionRoleIdForParty(matchingContact)
                                );
                              })
                          ) {
                            enqueueSnackbar('Party is already connected to the policy', {
                              variant: 'warning',
                            });
                            return;
                          }
                          // if contact is not a party, create the party
                          if (!matchingContact) {
                            handleCreateConnection({
                              roleOnCase: partiesAndPoliciesConnectionOptions.party,
                              contactId: contact?.contactId,
                              policyId: policy.policyId,
                            });
                          } else {
                            // if matching contact
                            setPoliciesLoading(policy?.policyId ?? '');
                            createItemConnection
                              .mutateAsync({
                                caseId: caseId,
                                policyId: policy.policyId,
                                partyId: getCaseContactConnectionRoleIdForParty(matchingContact),
                              })
                              .then(() => {
                                setPoliciesLoading('');
                              });
                          }
                        },
                      };
                    }) ?? []
                }
              />
            )}
          </div>
          {partiesTableOpen && partiesForPolicy && (
            <div className="mt-5">
              <LinkedPartiesTable
                parties={partiesForPolicy}
                handleRowClick={(row) =>
                  handleLinkedPartiesRowClick(row, {
                    type: 'policy',
                    id: policy.policyId,
                  })
                }
                isLoading={false}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 space-y-3">
        <div>
          <div className="flex items-center justify-between gap-x-2">
            <Button
              variant="ghost"
              size="unset"
              textColor="primary"
              className="flex items-center py-2 pr-2 gap-x-1 text-md"
              onClick={() => setVehicleTableOpen(!vehicleTableOpen)}
            >
              {vehicleTableOpen ? (
                <ChevronDownIcon strokeWidth={1.8} className="w-5 h-5" />
              ) : (
                <ChevronRightIcon strokeWidth={1.8} className="w-5 h-5" />
              )}
              Vehicles
              <div className="px-2 tabular-nums py-0.5 ml-2 text-xs text-white bg-atlantic-blue rounded-full text-bold">
                {vehiclesForPolicy?.length}
              </div>
            </Button>
            {vehicleTableOpen && (
              <EntityAddPopover
                placeholder={`Search ${scenario?.vehicles?.length ?? 0} vehicles...`}
                title="Link Vehicle"
                onAdd={() => {
                  setVehiclesLoading(policy.policyId);
                  createVehicle
                    .mutateAsync({
                      caseId: caseId,
                    })
                    .then((res) => {
                      const id = res?.data?.vehicleId;
                      if (!id) {
                        return;
                      }
                      createItemConnection
                        .mutateAsync({
                          caseId: caseId,
                          policyId: policy.policyId,
                          vehicleId: id,
                        })
                        .then(() => {
                          setSelectedVehicleId(res?.data?.vehicleId);
                          setVehiclesLoading('');
                          setVehicleSlideoverOpen(true);
                        });
                    });
                }}
                isLoading={vehiclesLoading === policy.policyId}
                listItems={
                  scenario?.vehicles?.map((vehicle) => {
                    return {
                      label: `${vehicle.year ?? '-'} ${vehicle.make ?? '-'} ${
                        vehicle.model ?? '-'
                      }`,
                      onClick: () => {
                        //if vehicle is already connected to the policy, don't connect it again
                        if (
                          vehiclesForPolicy?.some((itemConnection) => {
                            if (!itemConnection) {
                              return false;
                            }

                            return itemConnection.vehicleId === vehicle.vehicleId;
                          })
                        ) {
                          enqueueSnackbar('Vehicle is already connected to the policy', {
                            variant: 'warning',
                          });
                          return;
                        }

                        setVehiclesLoading(policy.policyId);
                        createItemConnection
                          .mutateAsync({
                            caseId: caseId,
                            policyId: policy.policyId,
                            vehicleId: vehicle.vehicleId,
                          })
                          .then(() => {
                            setVehiclesLoading('');
                          });
                      },
                    };
                  }) ?? []
                }
              />
            )}
          </div>
          {vehicleTableOpen && (
            <div className="mt-5">
              <LinkedVehiclesTable
                vehicles={vehiclesForPolicy ?? []}
                handleRowClick={(row) =>
                  handleLinkedVehiclesRowClick(row, {
                    type: 'policy',
                    id: policy.policyId,
                  })
                }
                isLoading={false} //TODO: Add loading state here
              />
            </div>
          )}
        </div>
      </div>
      <ActionConfirmModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        actionFunction={() => {
          if (policyToDelete) {
            deletePolicy.mutate(policyToDelete.policyId);
            setDeleteModalOpen(false);
          }
        }}
      />
    </div>
  );
};

export default PolicyCard;
