import { downloadFile, findFolderPathFromRoot, getS3FileDownloadLink } from '@colosseum/data';
import { FileResourceType } from '@gladiate/types';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import PostalMime, { Email } from 'postal-mime';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { EmailPreview } from '../EmailPreview/EmailPreview';
import { FileExplorer } from '../FileExplorer/FileExplorer';
import { FileExplorerContext } from '../FileExplorer/FileExplorerProvider';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import Modal from '../shadcn/Modal/Modal';

/* eslint-disable-next-line */
export interface FilePreviewProps {
  caseId?: string;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  filePreviewItem: FileResourceType | undefined;
  showItemsNav?: boolean;
}

export function FilePreview(props: FilePreviewProps) {
  const { caseId, isOpen, setOpen, filePreviewItem, showItemsNav } = props;

  const [filePreviewUrl, setFilePreviewUrl] = useState<string | undefined>(undefined);
  const [s3FileUrl, setS3FileUrl] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailDataLoading, setIsEmailDataLoading] = useState(false);
  const [emailData, setEmailData] = useState<Email | undefined>(undefined);

  const { rootFolder, currentFolder, setFilePreviewItem } = useContext(FileExplorerContext);

  useEffect(() => {
    const fetchFilePreviewLink = async () => {
      setIsLoading(true);
      if (filePreviewItem?.s3ObjKey) {
        try {
          const res = await getS3FileDownloadLink(filePreviewItem.s3ObjKey);
          setFilePreviewUrl(res.data?.previewUrl);
          setS3FileUrl(res.data?.url);
        } catch (error) {
          // Handle error
          enqueueSnackbar('Error fetching file preview link', {
            variant: 'error',
          });
        }
      }
      setIsLoading(false);
    };

    fetchFilePreviewLink();

    if (!isOpen) {
      setFilePreviewUrl(undefined);
      setS3FileUrl(undefined);
      setEmailData(undefined);
      setIsEmailDataLoading(false);
      setIsLoading(false);
    }
  }, [isOpen, filePreviewItem]);

  useEffect(() => {
    const fetchEMLfile = async () => {
      setIsEmailDataLoading(true);
      const eml = await fetch(s3FileUrl || '');
      const emlText = await eml.text();
      const email = await PostalMime.parse(emlText).catch(() => {
        enqueueSnackbar('Error fetching email preview', {
          variant: 'error',
        });
      });
      if (email) {
        setEmailData(email);
      }
      setIsEmailDataLoading(false);
    };

    if (s3FileUrl?.includes('.eml')) {
      fetchEMLfile();
    }
  }, [s3FileUrl]);

  const isMicrosoftFile =
    filePreviewItem?.s3ObjKey?.includes('.docx') ||
    filePreviewItem?.s3ObjKey?.includes('.xls') ||
    filePreviewItem?.s3ObjKey?.includes('.ppt');

  const isFolder = filePreviewItem?.resourceType === 'DIR';
  const isEmail = filePreviewItem?.s3ObjKey?.includes('.eml');

  const nodesFromRoot = findFolderPathFromRoot(rootFolder, filePreviewItem?.resourceId);

  const foldersOnly = nodesFromRoot?.filter((item) => item?.resourceType === 'DIR') || [];
  const siblings = (
    foldersOnly?.length ? foldersOnly.pop()?.children : rootFolder?.children
  )?.filter((item) => item.resourceType === 'FILE');

  const getCurrentFileIndex = () =>
    (siblings || []).findIndex(
      (item: FileResourceType) => item.s3ObjKey === filePreviewItem?.s3ObjKey,
    );

  const handlePreviewNextItem = () => {
    if (!siblings?.length) {
      return;
    }
    const currentIndex = getCurrentFileIndex();
    if (currentIndex !== -1 && currentIndex < siblings?.length - 1) {
      setFilePreviewItem(siblings[currentIndex + 1]);
    }
  };

  const handlePreviewPrevItem = () => {
    if (!siblings?.length) {
      return;
    }
    const currentIndex = getCurrentFileIndex();
    if (currentIndex !== -1 && currentIndex > 0) {
      setFilePreviewItem(siblings[currentIndex - 1]);
    }
  };

  return (
    <Modal
      width="w-[80vw]"
      height={isFolder ? '' : 'h-[80vh]'}
      open={isOpen}
      setOpen={setOpen}
      title={`${filePreviewItem?.name} Preview`}
      primaryButton={{
        label: 'Download',
        className:
          'text-white bg-sky-blue hover:bg-light-blue hover:text-atlantic-blue focus:shadow-atlantic-blue inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]',
        onClick: () => {
          if (filePreviewItem) {
            downloadFile(filePreviewItem);
          }
        },
      }}
      hideAllInteraction={isFolder}
    >
      {showItemsNav && (
        <div className="flex justify-center w-full">
          <button
            className="items-center justify-center h-8 px-3 text-sm font-medium transition-colors border rounded-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border-input hover:bg-accent hover:text-accent-foreground lg:flex"
            disabled={getCurrentFileIndex() === 0}
            onClick={() => handlePreviewPrevItem()}
          >
            <ArrowLeft height={'16px'} /> Previous Item
          </button>
          <button
            className="items-center justify-center h-8 px-3 text-sm font-medium transition-colors border rounded-md ms-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border-input hover:bg-accent hover:text-accent-foreground lg:flex"
            disabled={getCurrentFileIndex() === (siblings && siblings?.length - 1)}
            onClick={() => handlePreviewNextItem()}
          >
            Next Item <ArrowRight height={'16px'} />
          </button>
        </div>
      )}

      {isFolder ? (
        <div className="h-full mt-5 ">
          <FileExplorer
            caseId={caseId ?? ''}
            collapsibleHidden
            listViewOnly
            buttonsState={{
              addContent: true,
              createFolder: true,
              rename: true,
              move: false,
              delete: true,
              generateDocument: false,
              sendForSignature: false,
              download: false,
            }}
            targetFolderId={`${filePreviewItem?.parentResourceId}-${filePreviewItem?.resourceId}`}
          />
        </div>
      ) : (
        <div className="flex flex-grow w-full h-full mt-3 -mb-96 pb-[400px]">
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <GladiateLoader height={100} />
            </div>
          ) : isMicrosoftFile ? (
            filePreviewUrl && (
              <iframe
                src={`https://view.officeapps.live.com/op/view.aspx?src=${filePreviewUrl}`}
                width="100%"
                height="100%"
                title="file-preview"
              />
            )
          ) : isEmail ? (
            <EmailPreview data={emailData} dataIsLoading={isEmailDataLoading} />
          ) : (
            <embed className="object-scale-down w-full h-full rounded-sm" src={s3FileUrl} />
          )}
        </div>
      )}
    </Modal>
  );
}

export default FilePreview;
