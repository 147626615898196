import { DefaultV3Response, TaskForTaskPlanType, TaskPlanType } from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import {
  createTaskAssigneeV3,
  createTaskAssignerV3,
  createTaskCommentV3,
  createTaskPlanTaskV3,
  createTaskPlanV3,
  createTaskV3,
  deleteTaskAssigneeV3,
  deleteTaskAssignerV3,
  deleteTaskCommentV3,
  deleteTaskPlanTaskV3,
  deleteTaskPlanV3,
  deleteTaskV3,
  getAllTasksV3,
  getTaskPlanTasksV3,
  getTaskPlansV3,
  getTasksV3,
  updateTaskCommentV3,
  updateTaskPlanTaskV3,
  updateTaskPlanV3,
  updateTaskV3,
} from '../requests/strategikon';

export const useGetTasks = (itemId: Parameters<typeof getTasksV3>[0]) => {
  return useQuery({
    queryKey: [queryKeys.tasks, itemId],
    queryFn: async () => {
      if (!itemId) return getAllTasksV3();
      return getTasksV3(itemId);
    },
  });
};

export const useDeleteTask = (taskId: Parameters<typeof deleteTaskV3>[0]) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteTaskV3(taskId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tasks],
      });
    },
  });
};

export const useCreateTask = (itemId: Parameters<typeof createTaskV3>[1]) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createTaskV3>[0]) => createTaskV3(data, itemId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tasks],
      });
    },
  });
};

export const useUpdateTask = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      taskId,
      data,
    }: {
      taskId: Parameters<typeof updateTaskV3>[0];
      data: Parameters<typeof updateTaskV3>[1];
    }) => {
      return updateTaskV3(taskId, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tasks],
      });
    },
  });
};

// --------------------- Task Assignee --------------------- //

export const useCreateTaskAssignee = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      taskId,
      data,
    }: {
      taskId: Parameters<typeof createTaskAssigneeV3>[0];
      data: Parameters<typeof createTaskAssigneeV3>[1];
    }) => createTaskAssigneeV3(taskId, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tasks],
      });
    },
  });
};

export const useDeleteTaskAssignee = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { taskAssigneeId: string }) => deleteTaskAssigneeV3(data.taskAssigneeId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tasks],
      });
    },
  });
};

// --------------------- Task Assigners --------------------- //

export const useCreateTaskAssigner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      taskId,
      data,
    }: {
      taskId: Parameters<typeof createTaskAssignerV3>[0];
      data: Parameters<typeof createTaskAssignerV3>[1];
    }) => createTaskAssignerV3(taskId, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tasks],
      });
    },
  });
};

export const useDeleteTaskAssigner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { taskAssignerId: string }) => deleteTaskAssignerV3(data.taskAssignerId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tasks],
      });
    },
  });
};

export const useGetTaskPlans = () => {
  return useQuery({
    queryKey: [queryKeys.taskPlans],
    queryFn: async () => getTaskPlansV3(),
  });
};

export const useCreateTaskPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createTaskPlanV3>[0]) => createTaskPlanV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.taskPlans],
      });
    },
  });
};

export const useDeleteTaskPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (planId: Parameters<typeof deleteTaskPlanV3>[0]) => deleteTaskPlanV3(planId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.taskPlans],
      });
    },
  });
};

export const useUpdateTaskPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      planId,
      data,
    }: {
      planId: Parameters<typeof updateTaskPlanV3>[0];
      data: Parameters<typeof updateTaskPlanV3>[1];
    }) => updateTaskPlanV3(planId, data),
    onMutate: (data) => {
      queryClient.setQueryData(
        [queryKeys.taskPlans],
        (oldData: DefaultV3Response<TaskPlanType[]> | undefined) => {
          if (!oldData || !oldData.data) return;

          const newData = oldData.data.map((taskPlan) => {
            if (taskPlan.planId === data.planId) {
              return {
                ...taskPlan,
                ...data,
              };
            }
            return taskPlan;
          });

          return {
            ...oldData,
            data: newData,
          };
        },
      );

      return {
        previousData: queryClient.getQueryData([queryKeys.taskPlans]),
      };
    },
    onError: (err, variables, context) => {
      if (context && context?.previousData) {
        queryClient.setQueryData([queryKeys.taskPlans], context.previousData);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.taskPlans],
      });
    },
  });
};

export const useCreateTaskForTaskPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      planId,
      data,
    }: {
      planId: Parameters<typeof createTaskPlanTaskV3>[0];
      data: Parameters<typeof createTaskPlanTaskV3>[1];
    }) => createTaskPlanTaskV3(planId, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tasksForPlans],
      });
    },
  });
};

export const useGetTasksForTaskPlan = (planId: string) => {
  return useQuery({
    queryKey: [queryKeys.tasksForPlans, planId],
    queryFn: async () => getTaskPlanTasksV3(planId),
    enabled: !!planId,
  });
};

export const useDeleteTasksForTaskPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (taskForPlanId: string) => deleteTaskPlanTaskV3(taskForPlanId),
    onMutate: (taskForPlanId: string) => {
      queryClient.setQueryData(
        [queryKeys.tasksForPlans, taskForPlanId.split('-')[0]],
        (oldData: DefaultV3Response<TaskForTaskPlanType[]> | undefined) => {
          if (!oldData || !oldData.data) return;

          const newData = oldData.data.filter(
            (taskForPlan) => taskForPlan.planTaskId !== taskForPlanId,
          );

          return {
            ...oldData,
            data: newData,
          };
        },
      );

      return {
        previousData: queryClient.getQueryData([queryKeys.tasksForPlans]),
      };
    },
    onError: (err, variables, context) => {
      if (context && context?.previousData) {
        queryClient.setQueryData([queryKeys.tasksForPlans], context.previousData);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tasksForPlans],
      });
    },
  });
};

export const useUpdateTaskForTaskPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      planTaskId,
      data,
    }: {
      planTaskId: Parameters<typeof updateTaskPlanTaskV3>[0];
      data: Parameters<typeof updateTaskPlanTaskV3>[1];
    }) => updateTaskPlanTaskV3(planTaskId, data),
    onMutate: (data: TaskForTaskPlanType) => {
      if (!data.planTaskId) return;

      queryClient.setQueryData(
        [queryKeys.tasksForPlans, data.planTaskId.split('-')[0]],
        (oldData: DefaultV3Response<TaskForTaskPlanType[]> | undefined) => {
          if (!oldData || !oldData.data) return;

          const newData = oldData.data.map((taskForPlan) => {
            if (taskForPlan.planTaskId === data.planTaskId) {
              return {
                ...taskForPlan,
                ...data,
              };
            }
            return taskForPlan;
          });

          return {
            ...oldData,
            data: newData,
          };
        },
      );

      return {
        previousData: queryClient.getQueryData([queryKeys.tasksForPlans]),
      };
    },
    onError: (err, variables, context) => {
      if (context && context?.previousData) {
        queryClient.setQueryData([queryKeys.tasksForPlans], context.previousData);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tasksForPlans],
      });
    },
  });
};

// --------------------- Task Comments --------------------- //

export const useCreateTaskComment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      taskId,
      data,
    }: {
      taskId: Parameters<typeof createTaskCommentV3>[0];
      data: Parameters<typeof createTaskCommentV3>[1];
    }) => createTaskCommentV3(taskId, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tasks],
      });
    },
  });
};

export const useDeleteTaskComment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (taskCommentId: string) => deleteTaskCommentV3(taskCommentId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tasks],
      });
    },
  });
};

export const useUpdateTaskComment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      taskCommentId,
      data,
    }: {
      taskCommentId: Parameters<typeof updateTaskCommentV3>[0];
      data: Parameters<typeof updateTaskCommentV3>[1];
    }) => updateTaskCommentV3(taskCommentId, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tasks],
      });
    },
  });
};
