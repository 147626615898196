import { createContext, useState } from 'react';
import { BreadcrumbsProps } from './Breadcrumbs/Breadcrumbs';
import TimeLogProvider from './TimeLogProvider/TimeLogProvider';

export type SlideoverContextType = {
  slideoverCount: number;
  updateSlideoverCount: (action: 'add' | 'remove') => void;
  pendingSlideoverToOpen?: {
    id?: string;
    type: BreadcrumbsProps['resourceType'];
    dontScroll?: boolean;
  };
  setPendingSlideoverToOpen: React.Dispatch<
    React.SetStateAction<
      | {
          id?: string;
          type: BreadcrumbsProps['resourceType'];
          dontScroll?: boolean;
        }
      | undefined
    >
  >;
};

export const SlideoverContext = createContext<SlideoverContextType>({
  slideoverCount: 0,
  updateSlideoverCount: () => {
    //noop
  },
  pendingSlideoverToOpen: undefined,
  setPendingSlideoverToOpen: () => {
    //noop
  },
});

const SlideoverProvider = ({ children }: { children?: React.ReactNode }) => {
  const [slideoverCount, setSlideoverCount] = useState(1);
  const [pendingSlideoverToOpen, setPendingSlideoverToOpen] = useState<
    | {
        id?: string;
        type: BreadcrumbsProps['resourceType'];
        dontScroll?: boolean;
      }
    | undefined
  >();

  const updateSlideoverCount = (action: 'add' | 'remove') => {
    if (action === 'add') {
      setSlideoverCount((slideoverCount) => slideoverCount + 1);
    } else {
      if (slideoverCount >= 1) {
        // don't let count go into the negatives
        setSlideoverCount((slideoverCount) => slideoverCount - 1);
      }
    }
  };

  return (
    <SlideoverContext.Provider
      value={{
        slideoverCount,
        updateSlideoverCount,
        pendingSlideoverToOpen,
        setPendingSlideoverToOpen,
      }}
    >
      <TimeLogProvider />
      {children}
    </SlideoverContext.Provider>
  );
};

export { SlideoverProvider };
