import {
  AuditType,
  AvailableSubscriptions,
  CaseCampaignSubscription,
  CaseContactConnectionViewModelType,
  ContactCampaignSubscription,
  ContactViewModelType,
  DefaultV3Response,
  MedicalTreatmentSummaryType,
} from '@gladiate/types';
import applyCaseMiddleware from 'axios-case-converter';
import { InsightsMedicalRecordsViewModel } from '../../hooks/useInsightsMedicalRecordsViewModel';
import { VITE_API_STAGE } from '../../static/importMetaEnv';
import { axiosInstanceCamelCase } from '../https';
import { LeadListViewType } from '../react-query/lead';

const API_STAGE = VITE_API_STAGE;
const baseSibylsRoute = `/sibyls/${API_STAGE}/v3`;

export const getContactListViewV3 = (contactId?: string, firmId?: string) => {
  let contactsRoute = `${baseSibylsRoute}/contacts`;
  if (contactId && firmId) {
    contactsRoute += `?contactId=${contactId}&firmId=${firmId}`;
  }
  const client = applyCaseMiddleware(axiosInstanceCamelCase);
  return client
    .get<DefaultV3Response<ContactViewModelType[]>>(contactsRoute)
    .then((res) => res.data);
};

export const getCaseContactsV3 = (caseId: string) => {
  const client = applyCaseMiddleware(axiosInstanceCamelCase);
  return client
    .get<DefaultV3Response<CaseContactConnectionViewModelType[]>>(
      `${baseSibylsRoute}/cases/${caseId}/contacts`,
    )
    .then((res) => res.data);
};

export const getContactCampaignSubscriptionsV3 = (contactCampaignId: string) => {
  const client = applyCaseMiddleware(axiosInstanceCamelCase);
  return client
    .get<DefaultV3Response<ContactCampaignSubscription[]>>(
      `${baseSibylsRoute}/contacts/campaigns/${contactCampaignId}/subscriptions`,
    )
    .then((res) => res.data);
};

export const getCaseCampaignSubscriptionsV3 = (caseCampaignId: string) => {
  const client = applyCaseMiddleware(axiosInstanceCamelCase);
  return client
    .get<DefaultV3Response<CaseCampaignSubscription[]>>(
      `${baseSibylsRoute}/cases/campaigns/${caseCampaignId}/subscriptions`,
    )
    .then((res) => res.data);
};

export const getAvailableSubscriptionsV3 = () => {
  const client = applyCaseMiddleware(axiosInstanceCamelCase);
  return client
    .get<DefaultV3Response<AvailableSubscriptions[]>>(`${baseSibylsRoute}/subscriptions/available`)
    .then((res) => res.data);
};

export const getLeadListViewV3 = () => {
  const client = applyCaseMiddleware(axiosInstanceCamelCase);
  return client
    .get<DefaultV3Response<LeadListViewType[]>>(`${baseSibylsRoute}/leads`)
    .then((res) => res.data);
};

export const getMedicalRecordRequestV3 = () => {
  const client = applyCaseMiddleware(axiosInstanceCamelCase);
  return client
    .get<DefaultV3Response<InsightsMedicalRecordsViewModel[]>>(
      `${baseSibylsRoute}/records-requests`,
    )
    .then((res) => res.data);
};

export const getCaseAuditsV3 = (caseId?: string) => {
  const client = applyCaseMiddleware(axiosInstanceCamelCase);
  return client
    .get<DefaultV3Response<AuditType[]>>(`${baseSibylsRoute}/cases/${caseId}/audits`)
    .then((res) => res.data);
};

export const getCaseMedicalSummaryV3 = (caseId?: string) => {
  const client = applyCaseMiddleware(axiosInstanceCamelCase);
  return client
    .get<DefaultV3Response<MedicalTreatmentSummaryType[]>>(
      `${baseSibylsRoute}/cases/${caseId}/medicals`,
    )
    .then((res) => res.data);
};
