import {
  settingsSectionMappings,
  tanstackTableNames,
  useCreateTimeLogCategory,
  useDeleteTimeLogCategory,
  useGetTimeLogCategories,
  useUpdateTimeLogCategory,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  Form,
  Slideover,
  TextFormInput,
  getInitialVisibilityForTable,
} from '@colosseum/shared-ui';
import { TimeLogCategoryType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Row } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { columns } from './TimeTrackingCategoriesTableColumns';

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
});

export function TimeTrackingCategories() {
  const {
    data: timeLogCategoriesData,
    isLoading: isTimeLogCategoriesLoading,
    isError: isTimeLogCategoriesError,
  } = useGetTimeLogCategories();
  const createTimeLogCategory = useCreateTimeLogCategory();
  const updateTimeLogCategory = useUpdateTimeLogCategory();
  const deleteTimeLogCategory = useDeleteTimeLogCategory();
  const timeLogCategories = timeLogCategoriesData?.data;
  const typing = createTimeLogCategory.isLoading || updateTimeLogCategory.isLoading;
  const [openTimeLogCategory, setOpenTimeLogCategory] = useState<TimeLogCategoryType | undefined>();
  const [open, setOpen] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: {
      title: openTimeLogCategory?.title || '',
      description: openTimeLogCategory?.description || '',
    },
  });

  const handleCreate = () => {
    createTimeLogCategory.mutateAsync({}).then((res) => {
      setOpenTimeLogCategory(res.data);
      setOpen(true);
    });
  };

  const handleUpdate = (key: string, value: string) => {
    updateTimeLogCategory.mutate({
      timeLogCategoryId: openTimeLogCategory?.timeLogCategoryId ?? '',
      [key]: value,
    });
  };

  useEffect(() => {
    if (!open) {
      form.reset();
      setOpenTimeLogCategory(undefined);
    }
  }, [open, form]);

  return (
    <>
      <Slideover
        displayDeleteButton={true}
        open={open}
        setOpen={setOpen}
        deleteFunction={() => {
          if (openTimeLogCategory?.timeLogCategoryId)
            deleteTimeLogCategory.mutateAsync(openTimeLogCategory?.timeLogCategoryId).then(() => {
              setOpenTimeLogCategory(undefined);
              setOpen(false);
            });
        }}
        title="Time Log Category"
        description="Update your Time Log category information here."
        typing={typing}
      >
        <Form {...form}>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="flex flex-col mb-2 gap-x-3 gap-y-5">
              <TextFormInput
                {...form.register(`title`)}
                title="Title"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('title', target.value);
                }}
              />
              <TextFormInput
                {...form.register(`description`)}
                title="Description"
                type="textarea"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('description', target.value);
                }}
              />
            </div>
          </form>
        </Form>
      </Slideover>
      <div data-settings-submenu-item={settingsSectionMappings.timeTrackingCategories}>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 id="time-log-categories" className="text-xl font-semibold text-gray-900">
              Time Tracking Categories
            </h1>
            <p className="mt-2 text-sm text-gray-700">{`Your firm's time log categories.`}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              title={'Create new category'}
              loading={createTimeLogCategory.isLoading}
              onClick={handleCreate}
            />
          </div>
        </div>
        <DataTable
          data={timeLogCategories || []}
          initialSort={{
            id: 'Title',
            desc: false,
          }}
          columns={columns}
          isLoading={isTimeLogCategoriesLoading}
          isError={isTimeLogCategoriesError}
          tableName={tanstackTableNames.timeTrackingCategories}
          handleRowClick={(item: Row<TimeLogCategoryType>) => {
            setOpenTimeLogCategory(item.original);
            setOpen(true);
          }}
          initialVisibility={getInitialVisibilityForTable(
            tanstackTableNames.timeTrackingCategories,
            {},
          )}
          hideViewButton
        />
      </div>
    </>
  );
}

export default TimeTrackingCategories;
