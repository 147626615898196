/* eslint-disable @tanstack/query/exhaustive-deps */
import {
  DefaultV3Error,
  NylasCalendar,
  NylasCreateCalendarPayload,
  NylasEventPayload,
} from '@gladiate/types';
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import {
  connectNylasAccountV3,
  createCalendarEventV3,
  createCalendarV3,
  deleteCalendarEventV3,
  getAccountAuthUrlV3,
  getCaseEventsForCalendarV3,
  getEventAndRemindersV3,
  getPrimaryCalendarV3,
  getUserCalendarsV3,
  getUserNylasAccountV3,
  updateCalendarEventV3,
} from '../requests/pheidippides';
import { useEffect, useState } from 'react';

export const useGetUsersCalendars = () => {
  return useQuery({
    queryKey: ['usersCalendars'],
    queryFn: () => getUserCalendarsV3(),
  });
};

export const useGetCalendarEventAndReminders = (eventId: string) => {
  return useQuery({
    queryKey: ['calendarEventAndReminders', eventId],
    queryFn: () => getEventAndRemindersV3(eventId),
    enabled: !!eventId,
  });
};

export const useGetCalendarEvents = ({
  calendars,
  dateRange,
  caseId,
}: {
  calendars: NylasCalendar[];
  dateRange: {
    startDate: string;
    endDate: string;
  };
  caseId?: string;
}) => {
  const [currentRequestIndex, setCurrentRequestIndex] = useState(0);
  const [allFetched, setAllFetched] = useState(false);

  // To prevent 429 errors from sending n parallel requests, we create a queue of requests
  // and only send one request at a time
  const results = useQueries({
    queries: calendars.map((calendar, index) => {
      return {
        queryFn: () =>
          getCaseEventsForCalendarV3({
            calendarId: calendar.calendarId,
            dateRange,
            caseId: caseId ?? '',
            primaryCalendar: calendar?.primaryCalendar ?? false,
          }),
        queryKey: ['calendarEvents', calendar.calendarId, dateRange],
        // the enabled parameter is what controls when the request is fired off
        enabled:
          (index <= currentRequestIndex || allFetched) && !!dateRange && !!calendar.calendarId,
        // We add a small delay to the refetchInterval to stagger the refetch requests
        refetchInterval: 60000 + index * 500,
        retry: (count: number, error: AxiosError) => {
          // in the event there is a 429 returned, we will retry three times total
          if (error.response?.status === 401) {
            return false;
          }
          return count < 4;
        },
        cacheTime: 0,
      };
    }),
  });

  // Find the next request index that needs to be sent
  const nextLoadingRequest = results.findIndex((query) => query.isLoading);

  useEffect(() => {
    setCurrentRequestIndex(nextLoadingRequest);
    // if we have reached the end of the requests, we set allFetched to true to allow for refetching
    if (nextLoadingRequest === -1 && currentRequestIndex === calendars.length - 1) {
      setAllFetched(true);
    }
  }, [nextLoadingRequest]);

  return results;
};

export const useGetEventsForCalendar = ({
  calendarId,
  dateRange,
  caseId,
  primaryCalendar,
}: {
  calendarId: string;
  dateRange: {
    startDate: string;
    endDate: string;
  };
  caseId?: string;
  primaryCalendar?: boolean;
}) => {
  return useQuery({
    queryKey: ['calendarEvents', calendarId],
    queryFn: () =>
      getCaseEventsForCalendarV3({
        calendarId,
        dateRange,
        caseId: caseId,
        primaryCalendar: primaryCalendar ?? false,
      }),
    enabled: !!dateRange.endDate && !!dateRange.startDate && !!calendarId,
    refetchInterval: 60000,
    retry: false,
  });
};

export const useGetPrimaryFirmCalendar = () => {
  return useQuery({
    queryKey: ['primaryFirmCalendar'],
    queryFn: () => getPrimaryCalendarV3(),
    retry: false,
  });
};

export const useCreateCalendar = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: NylasCreateCalendarPayload) => createCalendarV3(data),
    onSuccess: () => {},
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['usersCalendars'],
      });

      queryClient.invalidateQueries({
        queryKey: ['primaryFirmCalendar'],
      });

      queryClient.invalidateQueries({
        queryKey: ['nylasUserAccount'],
      });
    },
  });
};

export const useCreateCalendarEvent = (calendarId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Partial<NylasEventPayload>) => createCalendarEventV3(calendarId, data),
    onSuccess: () => {},
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['calendarEvents', calendarId],
      });

      queryClient.invalidateQueries({
        queryKey: ['usersCalendars'],
      });

      queryClient.invalidateQueries({
        queryKey: ['primaryFirmCalendar'],
      });

      queryClient.invalidateQueries({
        queryKey: ['calendarEventAndReminders'],
      });
    },
  });
};

export const useUpdateCalendarEvent = (calendarId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Partial<NylasEventPayload>) => {
      const { eventId, ...rest } = data;

      if (!eventId) {
        throw new Error('Missing eventId');
      }

      return updateCalendarEventV3(calendarId, eventId, rest);
    },
    onSuccess: () => {},
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['calendarEvents', calendarId],
      });

      queryClient.invalidateQueries({
        queryKey: ['usersCalendars'],
      });

      queryClient.invalidateQueries({
        queryKey: ['primaryFirmCalendar'],
      });

      queryClient.invalidateQueries({
        queryKey: ['calendarEventAndReminders'],
      });
    },
  });
};

export const useDeleteCalendarEvent = (calendarId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (eventId: string) => deleteCalendarEventV3(calendarId, eventId),
    onSuccess: () => {},
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
    onSettled: (eventId) => {
      queryClient.invalidateQueries({
        queryKey: ['calendarEvents', calendarId],
      });

      queryClient.invalidateQueries({
        queryKey: ['usersCalendars'],
      });

      queryClient.invalidateQueries({
        queryKey: ['primaryFirmCalendar'],
      });

      queryClient.invalidateQueries({
        queryKey: ['calendarEventAndReminders'],
      });

      queryClient.invalidateQueries({
        queryKey: ['calendarEventAndReminders', eventId],
      });
    },
  });
};

export const useGetNylasAuthUrl = (disabled: boolean) => {
  return useQuery({
    queryKey: ['nylasAuthUrl'],
    queryFn: () => getAccountAuthUrlV3(),
    enabled: !disabled,
    refetchOnWindowFocus: false,
  });
};

export const useGetUserNylasAccount = () => {
  return useQuery({
    queryKey: ['userNylasAccount'],
    queryFn: () => getUserNylasAccountV3(),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useCreateNylasAccountWithAuthCode = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { authCode: string }) => connectNylasAccountV3(data.authCode),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['firmUserDetails'],
      });
      queryClient.invalidateQueries({
        queryKey: ['userNylasAccount'],
      });
      queryClient.invalidateQueries({
        queryKey: ['nylasUserAccount'],
      });
    },
  });
};
