import {
  US_STATES,
  cn,
  createSigningEvent,
  getFirmId,
  queryKeys,
  useCaseCreationAndUpdateQueue,
  useCreateFileItemForCase,
  useGenerateDocumentData,
  useGenerateDocumentWithData,
  useGetAdobeSignerListV3,
  useGetAdobeUserListV3,
  useGetCase,
  useGetCaseStatuses,
  useGetCaseTypes,
  useGetContact,
  useGetOneClickSignSettingList,
  useGetProvisionedPhoneNumbers,
  useGetTemplate,
  useSendDocumentForSigning,
  validateEmail,
} from '@colosseum/data';
import { OneClickSignSettingType } from '@gladiate/types';
import {
  ArrowRightCircleIcon,
  ChevronRightIcon,
  PaperAirplaneIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { z } from 'zod';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import Slideover from '../Slideover/Slideover';
import SpinLoader from '../SpinLoader/SpinLoader';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';
import CalendarFormInput from '../forms/CalendarFormInput/CalendarFormInput';
import PhoneInput from '../forms/PhoneInput/PhoneInput';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';

export interface QuickCreateCaseSlideoverProps {
  open: boolean;
  setOpen: (value: SetStateAction<boolean>) => void;
  linkIds?: {
    caseId: string;
    linkId: string;
  };
}

type QueryKeyValues = (typeof queryKeys)[keyof typeof queryKeys] | 'emails' | 'phoneNumbers';

type UpdateType = {
  resourceType: QueryKeyValues;
  key: string;
  value: string;
};

const initialCaseStatuses = ['Sign', 'Refer', 'Decline'];

const formSchema = z.object({
  clientDescriptionOfIncident: z.string().optional(),
  dateOfIncident: z.string().optional(),
  descriptionOfClientInjuries: z.string().optional(),
  email: z.string().refine((value) => validateEmail(value), {
    message: 'Invalid email address',
  }),
  firstName: z.string().optional(),
  incidentCity: z.string().optional(),
  incidentState: z.string().optional(),
  incidentStreet: z.string().optional(),
  lastName: z.string().optional(),
  number: z.string().optional(),
  statuteOfLimitations: z.string().optional(),
});

const getUpdateQueueValue = (updatesQueue: UpdateType[], key: string): string | undefined => {
  const update = updatesQueue.find((item) => item.key === key);
  return update?.value;
};

const firmId = getFirmId();

export function QuickCreateCaseSlideover(props: QuickCreateCaseSlideoverProps) {
  const { open, setOpen, linkIds } = props;

  const navigate = useNavigate();
  const { updatesQueue, handleUpdates, createCaseAndLinks, createdIds, allResourcesCreated } =
    useCaseCreationAndUpdateQueue();

  const [moreExpanded, setMoreExpanded] = useState(false);
  const [generateDocumentLoading, setGenerateDocumentLoading] = useState(false);
  const [sendForSignLoading, setSendForSignLoading] = useState(false);
  const [oneClickSignDone, setOneClickSignDone] = useState(false);
  const [updatingCaseTypes, setUpdatingCaseTypes] = useState(false);
  const [updatingCaseStatuses, setUpdatingCaseStatuses] = useState(false);
  const [selectedOneClickSignSetting, setSelectedOneClickSignSetting] =
    useState<OneClickSignSettingType>();

  const hasRun = useRef(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const rootFolderId = `${firmId}-${createdIds.caseId}`;

  const generateDocument = useGenerateDocumentWithData();
  const createFileForCase = useCreateFileItemForCase(createdIds.caseId);
  const sendDocumentForSigning = useSendDocumentForSigning();

  const { data: caseData, isError: caseError } = useGetCase(createdIds.caseId);
  const { data: contactData } = useGetContact(createdIds.contactId);
  const { data: oneClickSignData } = useGetOneClickSignSettingList();
  const { data: adobeUserListData } = useGetAdobeUserListV3();
  const { data: provisionedNumbersData } = useGetProvisionedPhoneNumbers();
  const { data: signersData } = useGetAdobeSignerListV3();

  const { data: caseTypesRes } = useGetCaseTypes();
  const caseTypes = caseTypesRes?.data;

  const { data: caseStatusesRes } = useGetCaseStatuses();
  const caseStatuses = caseStatusesRes?.data;

  const caseInfo = caseData?.data;
  const contactInfo = contactData?.data;

  const phoneData = provisionedNumbersData?.data.find(
    (item) =>
      item.provisionedPhoneNumberId === selectedOneClickSignSetting?.provisionedPhoneNumberId,
  );
  const firmPhoneNumber = phoneData?.phoneNumber || '';

  const signersMap: { [key: string]: string } = (adobeUserListData?.data || []).reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr.adobeEmail]: curr.signerPrivelage,
      };
    },
    {},
  );

  const oneClickSignOptions: { [key: string]: string } = (oneClickSignData?.data || [])
    ?.filter(
      (item) =>
        item.title &&
        item.provisionedPhoneNumberId &&
        item.senderSigningAttorneyEmail &&
        signersMap[item.senderSigningAttorneyEmail] &&
        item.senderSigningAttorneyName &&
        item.templateId,
    )
    ?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.title || '']: curr.oneClickSignSettingId,
      };
    }, {});

  const { data: templateData, isLoading: isTemplateDataLoading } = useGetTemplate(
    selectedOneClickSignSetting?.templateId ?? '',
  );
  const documentId = templateData?.data?.documentId;

  const { generateDocumentData } = useGenerateDocumentData(
    createdIds.caseId,
    documentId ?? '',
    'pdf',
  );
  const signStatus = caseStatuses?.find((status) => status.category === 'open');

  const referStatus = caseStatuses?.find((status) => status.category === 'refer');
  const declineStatus = caseStatuses?.find((status) => status.category === 'decline');

  const statusMapping: {
    [key: string]: string;
  } = {
    Refer: referStatus?.caseStatusId ?? '',
    Sign: signStatus?.caseStatusId ?? '',
    Decline: declineStatus?.caseStatusId ?? '',
  };

  const activeCaseType =
    caseInfo?.caseType?.caseTypeId ?? getUpdateQueueValue(updatesQueue, 'caseTypeId') ?? undefined;

  const activeCaseStatus =
    caseInfo?.caseStatus?.caseStatusId ??
    getUpdateQueueValue(updatesQueue, 'caseStatusId') ??
    undefined;

  const oneClickSignButtonDisabled =
    !selectedOneClickSignSetting ||
    !allResourcesCreated ||
    sendForSignLoading ||
    generateDocumentLoading ||
    isTemplateDataLoading ||
    oneClickSignDone ||
    activeCaseStatus === referStatus?.caseStatusId ||
    activeCaseStatus === declineStatus?.caseStatusId;

  const getOneClickButtonLabel = () => {
    if (!allResourcesCreated) {
      return (
        <div className="flex items-center ">
          <GladiateLoader white={true} height={25} />
          <div className="ml-2">Loading...</div>
        </div>
      );
    }

    if (oneClickSignDone) {
      return (
        <div className="flex items-center space-x-2">
          <PaperAirplaneIcon className="w-5 h-5 mr-2" />
          Sent for Signature!
        </div>
      );
    } else if (sendForSignLoading) {
      return (
        <div className="flex items-center space-x-2">
          <GladiateLoader white={true} height={25} />
          Sending for Signature...
        </div>
      );
    } else if (generateDocumentLoading) {
      return (
        <div className="flex items-center space-x-2">
          <GladiateLoader white={true} height={25} />
          Generating Document...
        </div>
      );
    } else {
      return (
        <div className="flex items-center">
          <PlusCircleIcon className="w-5 h-5 mr-2" />
          One Click Signup
        </div>
      );
    }
  };

  const handleOneClickSign = async () => {
    const isValid = await form.trigger(); // This triggers validation and returns a boolean indicating if the form is valid
    const documentData = generateDocumentData();
    if (!isValid) {
      enqueueSnackbar('There was an error in the form. Please check the values entered.', {
        variant: 'error',
      });
      return;
    }

    setGenerateDocumentLoading(true);
    generateDocument.mutateAsync(documentData ?? {}).then((res) => {
      createFileForCase
        .mutateAsync({
          name: 'One Click Sign Document.pdf',
          resourceType: 'FILE',
          currentDirectory: rootFolderId,
          s3ObjKey: res.data?.genDocKey,
        })
        .then(() => {
          setGenerateDocumentLoading(false);

          enqueueSnackbar('Document generated', {
            variant: 'success',
          });
        })
        .catch(() => {
          setGenerateDocumentLoading(false);

          enqueueSnackbar('Error generating document', {
            variant: 'error',
          });
        });

      const signer = signersData?.data.find(
        (item) => item.adobeEmail === selectedOneClickSignSetting?.senderSigningAttorneyEmail,
      );
      const signEvent = createSigningEvent({
        username: signer?.username || '',
        oneClickSignFirstSetting: selectedOneClickSignSetting,
        objKey: res.data?.genDocKey,
        contactInfo,
        caseId: createdIds.caseId,
        oneClickSignTemplate: templateData?.data,
        signer: {
          email: form.getValues('email'),
          phoneNumber: form.getValues('number'),
          firstName: form.getValues('firstName'),
          lastName: form.getValues('lastName'),
        },
        firmPhoneNumber,
      });

      // Send the document for signing
      setSendForSignLoading(true);

      sendDocumentForSigning
        .mutateAsync(signEvent)
        .then((res) => {
          setSendForSignLoading(false);
          setOneClickSignDone(true);

          enqueueSnackbar('Document sent for signature', {
            variant: 'success',
          });
        })
        .catch((err) => {
          setSendForSignLoading(false);

          enqueueSnackbar('Error sending document for signature', {
            variant: 'error',
          });
        });
    });
  };

  useEffect(() => {
    if (open && !hasRun.current) {
      createCaseAndLinks({
        linkIds: linkIds,
        caseError: caseError,
      });
      hasRun.current = true;
    } else if (!open) {
      hasRun.current = false; // Reset if the `open` prop becomes false

      setOneClickSignDone(false);

      // Reset the form
      form.reset();
    }
  }, [open]);

  const getLabel = (text: string | undefined) => {
    if (!text) {
      return '';
    }
    return text.length > 40 ? (
      <TooltipWrapper message={text}>
        <span>{text.substring(0, 40) + '...'}</span>
      </TooltipWrapper>
    ) : (
      text
    );
  };

  return (
    <Slideover
      open={open}
      setOpen={setOpen}
      title="Quick Intake"
      description="Capture key details quickly."
      bottomButtons={
        <div className="flex items-center justify-between w-full py-4 border-t border-t-gray-200">
          <div className="px-3">
            <button
              className={cn(
                oneClickSignButtonDisabled
                  ? 'flex items-center px-3 py-2 text-white bg-gray-400 rounded-md cursor-not-allowed opacity-70'
                  : 'flex items-center px-3 py-2 text-white bg-yellow-400 rounded-md hover:animate-pulse hover:bg-orange-500 ',
              )}
              disabled={oneClickSignButtonDisabled}
              onClick={() => {
                handleOneClickSign();
              }}
            >
              {getOneClickButtonLabel()}
            </button>
          </div>

          <div className="px-3">
            <button
              disabled={!allResourcesCreated}
              onClick={() => {
                setOpen(false);
                if (allResourcesCreated) {
                  navigate(`/cases/${createdIds.caseId}`);
                }
              }}
              className={cn(
                'inline-flex items-center justify-center py-2 mx-auto text-sm font-medium',
              )}
            >
              {allResourcesCreated ? (
                <>
                  View Case Details
                  <ArrowRightCircleIcon className="w-5 h-5 ml-2" />
                </>
              ) : (
                <>
                  <SpinLoader color="black" />
                  Loading...
                </>
              )}
            </button>
          </div>
        </div>
      }
    >
      <div>
        <div className="mb-5">
          <div className="mb-3 ml-3 font-semibold">Case Type</div>
          {updatingCaseTypes ? (
            <GladiateLoader />
          ) : (
            caseTypes?.map((type, index) => {
              if (index < 8) {
                return (
                  <button
                    onClick={() => {
                      setUpdatingCaseTypes(true);
                      handleUpdates({
                        resourceType: queryKeys.cases,
                        key: 'caseTypeId',
                        value: type.caseTypeId,
                      }).then(() => {
                        setUpdatingCaseTypes(false);
                      });
                    }}
                    key={`${type.caseTypeId}${index}caseType`}
                    className={cn(
                      'inline-flex px-2 mb-3 py-0.5 ml-2 mr-2 text-sm font-semibold rounded-full cursor-pointer',
                      activeCaseType === type.caseTypeId
                        ? ' bg-sky-blue text-white'
                        : ' text-atlantic-blue bg-light-blue',
                    )}
                  >
                    {getLabel(type.title)}
                  </button>
                );
              } else if (moreExpanded) {
                return (
                  <button
                    onClick={() => {
                      handleUpdates({
                        resourceType: queryKeys.cases,
                        key: 'caseTypeId',
                        value: type.caseTypeId,
                      });
                    }}
                    key={`${type.caseTypeId}${index}caseType`}
                    className={cn(
                      'inline-flex px-2 mb-3 py-0.5 ml-2 mr-2 text-sm font-semibold rounded-full cursor-pointer',
                      activeCaseType === type.caseTypeId
                        ? ' bg-sky-blue text-white'
                        : ' text-atlantic-blue bg-light-blue',
                    )}
                  >
                    {getLabel(type.title)}
                  </button>
                );
              } else {
                return null;
              }
            })
          )}
          {moreExpanded ? null : (
            <button
              onClick={() => {
                setMoreExpanded(!moreExpanded);
              }}
              className="inline-flex relative px-2 mb-3 py-0.5 ml-2 mr-2 text-sm font-semibold text-atlantic-blue cursor-pointer"
            >
              <div className="flex items-center ">
                <p className="mr-4">More</p>
                <ChevronRightIcon className="absolute w-4 h-4 right-1" />
              </div>
            </button>
          )}
        </div>
        <div className="mb-5">
          <div className="mb-3 ml-3 font-semibold">Next Step</div>
          {updatingCaseStatuses ? (
            <GladiateLoader />
          ) : (
            initialCaseStatuses.map((status, index) => {
              const mappedStatus = statusMapping[status] || status; // Use the mapped status if available
              return (
                <div
                  key={`${status}${index}caseStatusId`}
                  onClick={() => {
                    setUpdatingCaseStatuses(true);
                    handleUpdates({
                      resourceType: queryKeys.cases,
                      key: 'caseStatusId',
                      value: mappedStatus,
                    }).then(() => {
                      setUpdatingCaseStatuses(false);
                    });
                  }}
                  className={cn(
                    'inline-flex px-2 mb-3 py-0.5 ml-2 mr-2 text-sm font-semibold rounded-full cursor-pointer',
                    activeCaseStatus === mappedStatus // Compare with the mapped status
                      ? ' bg-sky-blue text-white'
                      : ' text-atlantic-blue bg-light-blue',
                  )}
                >
                  {status}
                </div>
              );
            })
          )}
        </div>
        <Form {...form}>
          <div className="mb-3">
            <SelectFormInput
              name="oneClickSignSetting"
              title="One Click Sign Setting"
              listItems={oneClickSignOptions || {}}
              listItemsIsObject
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                if (target.value) {
                  const setting = oneClickSignData?.data.find(
                    (item) => item.oneClickSignSettingId === target.value,
                  );
                  setSelectedOneClickSignSetting(setting);
                }
              }}
            />
          </div>
          <form>
            Client
            <div className="w-full">
              <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
                <TextFormInput
                  {...form.register('firstName')}
                  handleOnBlur={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdates({
                      resourceType: queryKeys.contacts,
                      key: 'firstName',
                      value: target.value,
                    });
                  }}
                  title={'First Name'}
                />
                <TextFormInput
                  {...form.register('lastName')}
                  handleOnBlur={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdates({
                      resourceType: queryKeys.contacts,
                      key: 'lastName',
                      value: target.value,
                    });
                  }}
                  title={'Last Name'}
                />
                <PhoneInput
                  {...form.register(`number`)}
                  formHandleBlur={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdates({
                      resourceType: 'phoneNumbers',
                      key: 'number',
                      value: target.value,
                    });
                  }}
                  title="Phone Number"
                />
                <TextFormInput
                  {...form.register('email')}
                  handleOnBlur={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdates({
                      resourceType: 'emails',
                      key: 'emailAddress',
                      value: target.value,
                    });
                  }}
                  title={'Email'}
                />
              </div>
            </div>
            Incident
            <div className="w-full">
              <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
                <CalendarFormInput
                  {...form.register(`dateOfIncident`)}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdates({
                      resourceType: queryKeys.cases,
                      key: 'dateOfIncident',
                      value: target.value,
                    });
                  }}
                  title="Date of Incident"
                />
                <TextFormInput
                  {...form.register('incidentStreet')}
                  handleOnBlur={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdates({
                      resourceType: queryKeys.cases,
                      key: 'incidentStreet',
                      value: target.value,
                    });
                  }}
                  title={'Location (Street)'}
                />
                <TextFormInput
                  {...form.register('incidentCity')}
                  handleOnBlur={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdates({
                      resourceType: queryKeys.cases,
                      key: 'incidentCity',
                      value: target.value,
                    });
                  }}
                  title={'Location (City)'}
                />
                <SelectFormInput
                  title="Location (State)"
                  name="incidentState"
                  listItems={US_STATES}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdates({
                      resourceType: queryKeys.cases,
                      key: 'incidentState',
                      value: target.value,
                    });
                  }}
                />
                <CalendarFormInput
                  {...form.register(`statuteOfLimitations`)}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdates({
                      resourceType: queryKeys.cases,
                      key: 'statuteOfLimitations',
                      value: target.value,
                    });
                  }}
                  title="Statute of Limitations"
                  disabled={(date) => dayjs(date) > dayjs().add(20, 'years')}
                />
                <span className="col-span-2">
                  <TextFormInput
                    {...form.register('clientDescriptionOfIncident')}
                    handleOnBlur={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleUpdates({
                        resourceType: queryKeys.cases,
                        key: 'clientDescriptionOfIncident',
                        value: target.value,
                      });
                    }}
                    title={'Description of incident'}
                    type="textarea"
                  />
                </span>
              </div>
            </div>
            Injuries
            <div className="w-full">
              <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
                <span className="col-span-2">
                  <TextFormInput
                    {...form.register('descriptionOfClientInjuries')}
                    handleOnBlur={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleUpdates({
                        resourceType: queryKeys.cases,
                        key: 'descriptionOfClientInjuries',
                        value: target.value,
                      });
                    }}
                    title={'Short description of injuries'}
                    type="textarea"
                  />
                </span>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </Slideover>
  );
}

export default QuickCreateCaseSlideover;
