// Dependencies
import {
  VITE_API_STAGE,
  casesSectionMappings,
  cn,
  scrollToCaseSection,
  useGetAllTimeLogs,
  useGetAttorneyFeesForCase,
  useGetCase,
  useGetCurrentUser,
  usePermissions,
  useUpdateCaseData,
} from '@colosseum/data';
import {
  BreadcrumbsProps,
  Button,
  Calendar,
  CustomAtributeValuesForm,
  CustomErrorBoundary,
  SectionContainer,
  Slideover,
  SlideoverContext,
  Timer,
  Typography,
} from '@colosseum/shared-ui';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { withErrorBoundary } from '@sentry/react';
import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import CaseClose from '../../cases/CaseClose/CaseClose';
import DeclineCase from '../../cases/DeclineCase/DeclineCase';
import ErrorPage from '../../error/ErrorPage';
import AccountingSection from '../AccountingSection/AccountingSection';
import CaseFileSection from '../CaseFileSection/CaseFileSection';
import CaseSettings from '../CaseSettings/CaseSettings';
import CollaborationHub from '../CollaborationHub/CollaborationHub';
import IncidentSection from '../IncidentSection/IncidentSection';
import LitigationSection from '../LitigationSection/LitigationSection';
import MedicalsSection from '../MedicalsSection/MedicalsSection';
import NegotiationSection from '../NegotiationSection/NegotiationSection';
import SearchPopover from '../SearchPopover/SearchPopover';
import SummarySection from '../SummarySection/SummarySection';

const API_STAGE = VITE_API_STAGE;

const SLIDEOVERS_TYPES_TO_OPEN = ['communication', 'settlementProposal', 'trustAccountTransaction'];

export interface CaseDetailPageProps {
  caseId: string;
}

export function CaseDetailPage(props: CaseDetailPageProps) {
  const { caseId } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [settingsSliderOpen, setSettingsSliderOpen] = useState(false);
  const { setPendingSlideoverToOpen } = useContext(SlideoverContext);

  const { hasServiceAccess } = usePermissions();

  const animationVariants = {
    sticky: {
      y: 0,
      transition: { duration: 0.3, ease: 'easeInOut' },
    },
    notSticky: {
      y: 0,
      transition: { duration: 0.3 },
    },
  };

  const [isSticky, setSticky] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const editingTitleInputRef = useRef<HTMLInputElement>(null);
  const updateCase = useUpdateCaseData();

  const {
    data: caseData,
    isLoading: isCaseLoading,
    isError: caseError,
    error: caseErrorData,
  } = useGetCase(caseId);

  const isLead = caseData?.data?.caseStatus?.category === 'lead';
  const currentUser = useGetCurrentUser();
  const { data: timeLogData } = useGetAllTimeLogs(currentUser?.data?.data?.Username);
  const unfinishedTimeLog = timeLogData?.data?.find((timeLog) => !timeLog.endTime);

  const unfinishedTimeLogCaseId = unfinishedTimeLog?.timeLogId.split('-')[0];

  const { data: feeAgreementsData } = useGetAttorneyFeesForCase(caseId);
  const feeAgreements = feeAgreementsData?.data ?? [];

  const caseTitle = caseData?.data?.caseTitle || 'No Title';

  useEffect(() => {
    if (isEditingTitle) {
      editingTitleInputRef.current?.focus();
      editingTitleInputRef.current?.setSelectionRange(
        editingTitleInputRef.current?.value.length,
        editingTitleInputRef.current?.value.length,
      );
    }
  }, [isEditingTitle]);

  const checkScroll = () => {
    if (window.pageYOffset > 40) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    const searchParamsItemId = searchParams.get('item');
    const searchParamsType = searchParams.get('type');
    if (searchParamsItemId && searchParamsType) {
      if (['staff'].includes(searchParamsType)) {
        setSettingsSliderOpen(true);
      } else if (['note', 'task'].includes(searchParamsType)) {
        // scroll to the notes/tasks section
        const ref = document.querySelectorAll(
          `[data-case-submenu-item='${casesSectionMappings.collaborationHub}']`,
        )[0];
        scrollToCaseSection(ref, true);
      } else if (['event'].includes(searchParamsType)) {
        const ref = document.querySelectorAll(
          `[data-case-submenu-item='${casesSectionMappings.calendar}']`,
        )[0];
        scrollToCaseSection(ref, true);
      } else if (SLIDEOVERS_TYPES_TO_OPEN.includes(searchParamsType)) {
        setPendingSlideoverToOpen({
          type: searchParamsType as BreadcrumbsProps['resourceType'],
          id: searchParamsItemId,
        });
      }
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('item');
      newSearchParams.delete('type');
      setSearchParams(newSearchParams);
    }
    // Clean up on component unmount
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  if (caseError || isLead) {
    // page cannot be shown if case is not found
    if (caseErrorData?.response?.status === 404 || isLead) {
      return (
        <ErrorPage
          code={404}
          errorTitle={'Case not found'}
          errorMessage={'Please check the URL in the address bar and try again.'}
          backRoute="/cases"
          backMessage="Back to Cases"
        />
      );
    } else {
      return (
        <ErrorPage
          code={caseErrorData?.response?.status ?? 500}
          errorTitle={'Something went wrong'}
          errorMessage={'Please try again later.'}
          backRoute="/cases"
          backMessage="Back to Cases"
        />
      );
    }
  }

  return (
    <>
      <Helmet defer={false}>
        <title>
          {isCaseLoading ? 'Case' : `Case | ${caseData?.data?.caseTitle ?? 'No Title'}`}
        </title>
      </Helmet>
      <Slideover
        open={settingsSliderOpen}
        setOpen={setSettingsSliderOpen}
        title={'Settings'}
        description={''}
        displayDeleteButton={false}
        typing={false}
      >
        <CaseSettings caseId={caseId} feeAgreements={feeAgreements} />
      </Slideover>
      <div
        data-case-submenu-item={
          isCaseLoading ? 'Loading...' : caseData?.data?.caseTitle ?? 'No Title'
        }
      >
        <motion.div
          id="case-header"
          initial={{ y: 0 }}
          animate={isSticky ? 'sticky' : 'notSticky'}
          variants={animationVariants}
          className={cn(
            'flex justify-between px-2 pb-5 sticky top-0 z-20 items-center',
            isSticky && 'bg-gray-100 px-4 pt-5',
          )}
        >
          <div className="flex items-center w-full pr-5 text-xl font-semibold md:text-3xl">
            <Button
              variant="ghost"
              hoverVariant="lightBlue"
              onClick={() => navigate('/cases')}
              className="inline-block p-2 mr-4 fadeAnimation"
            >
              <ArrowLeftIcon className="w-5 h-5" />
            </Button>

            {isEditingTitle ? (
              <input
                type="text"
                className="w-full px-2 py-1 font-semibold border rounded-lg"
                defaultValue={caseTitle}
                ref={editingTitleInputRef}
                onBlur={(e) => {
                  updateCase.mutate({
                    caseId,
                    caseTitle: e.target.value,
                  });
                  setIsEditingTitle(false);
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
                  const target = e.target as HTMLInputElement;
                  if (e.key === 'Enter') {
                    updateCase.mutate({
                      caseId,
                      caseTitle: target.value,
                    });
                    setIsEditingTitle(false);
                  }
                }}
              />
            ) : (
              <span
                className="inline-block px-2 py-1 font-semibold border border-transparent rounded-lg hover:border hover:border-gray-300"
                onClick={() => {
                  setIsEditingTitle(true);
                }}
                data-cy="caseTitle"
              >
                {caseTitle}
              </span>
            )}
          </div>
          <div className="flex gap-x-2">
            <Button
              onClick={() => {
                setPendingSlideoverToOpen({ type: 'timeLog', id: caseId });
              }}
              variant="outlinePrimary"
              className="w-36"
              disabled={!!unfinishedTimeLogCaseId && unfinishedTimeLogCaseId !== caseId}
            >
              {unfinishedTimeLog && unfinishedTimeLogCaseId === caseId ? (
                <Timer startTime={unfinishedTimeLog?.startTime} runTimer={!!unfinishedTimeLog} />
              ) : (
                'Time Log'
              )}
            </Button>
            <SearchPopover />
          </div>
        </motion.div>
        {hasServiceAccess('caseSummaryAccess') && (
          <SummarySection caseId={caseId} setSettingsSliderOpen={setSettingsSliderOpen} />
        )}
        {caseData?.data && <DeclineCase caseData={caseData?.data} updateCase={updateCase} />}
        {hasServiceAccess('caseCollaborationHubAccess') && (
          <SectionContainer data-name="hub">
            <h2
              data-case-submenu-item={casesSectionMappings.collaborationHub}
              className="ml-2 text-xl font-semibold"
            >
              {casesSectionMappings.collaborationHub}
            </h2>
            <CollaborationHub caseId={caseId} />
          </SectionContainer>
        )}
        {hasServiceAccess('caseCalendarAccess') && (
          <SectionContainer data-name="calendar">
            <div
              data-case-submenu-item={casesSectionMappings.calendar}
              className="pb-4 space-y-4 text-xl font-semibold"
            >
              Calendar
            </div>
            <Calendar caseId={caseId} />
          </SectionContainer>
        )}
        {hasServiceAccess('caseIncidentAccess') && (
          <SectionContainer data-name="incident">
            <Typography
              data-case-submenu-item={casesSectionMappings.incident}
              tag="h2"
              variant="heading"
              className="ml-2"
            >
              Incident
            </Typography>
            <IncidentSection caseData={caseData?.data} isCaseLoading={isCaseLoading} />
          </SectionContainer>
        )}
        {hasServiceAccess('caseMedicalsAccess') && (
          <SectionContainer data-name="medicals">
            <Typography
              data-case-submenu-item={casesSectionMappings.medicals}
              tag="h2"
              variant="heading"
              className="ml-2"
            >
              Medicals
            </Typography>
            {caseData?.data && <MedicalsSection caseData={caseData?.data} />}
          </SectionContainer>
        )}
        {hasServiceAccess('caseAccountingAccess') && (
          <SectionContainer data-name="accounting">
            <h2
              data-case-submenu-item={casesSectionMappings.accounting}
              className="ml-2 text-xl font-semibold"
            >
              Accounting
            </h2>
            <AccountingSection caseId={caseId} />
          </SectionContainer>
        )}
        {hasServiceAccess('caseNegotiationsAccess') && (
          <SectionContainer data-name="negotiations">
            <h2
              data-case-submenu-item={casesSectionMappings.negotiations}
              className="ml-2 text-xl font-semibold"
            >
              Negotiations
            </h2>
            <NegotiationSection caseId={caseId} />
          </SectionContainer>
        )}
        {hasServiceAccess('caseLitigationAccess') && (
          <SectionContainer data-name="litigation">
            <h2
              data-case-submenu-item={casesSectionMappings.litigation}
              className="ml-2 text-xl font-semibold"
            >
              Litigation
            </h2>
            <LitigationSection caseData={caseData?.data} />
          </SectionContainer>
        )}
        {hasServiceAccess('caseClosingAccess') && (
          <SectionContainer>
            <CaseClose caseId={caseId} activeCaseQuery={caseData?.data} />
          </SectionContainer>
        )}
        {hasServiceAccess('caseFilesAccess') && (
          <SectionContainer>
            <div
              data-case-submenu-item={casesSectionMappings.files}
              className="pb-4 space-y-4 text-xl font-semibold"
            >
              {casesSectionMappings.files}
            </div>
            <CaseFileSection caseId={caseId} />
          </SectionContainer>
        )}
        {hasServiceAccess('caseCustomAttributesAccess') && (
          <SectionContainer>
            <h1
              data-case-submenu-item={casesSectionMappings.customAttributes}
              className="text-xl font-semibold text-gray-900"
            >
              {casesSectionMappings.customAttributes}
            </h1>
            <CustomAtributeValuesForm objectId={caseId} attributeType="case" />
          </SectionContainer>
        )}
      </div>
    </>
  );
}
export default withErrorBoundary(CaseDetailPage, {
  fallback: ({ error }) => <CustomErrorBoundary error={error} />,
});
