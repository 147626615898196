import {
  camelCaseToWords,
  cn,
  useCreateCaseContactConnection,
  useCreateCaseContactConnectionRole,
  useDeleteCaseContactConnectionRole,
  useUpdateCaseContactConnectionRole,
} from '@colosseum/data';
import {
  CaseContactConnectionRoleType,
  CaseContactConnectionType,
  ContactType,
} from '@gladiate/types';

import { motion } from 'framer-motion';
import { PencilIcon } from 'lucide-react';
import Typography from '../Typography/Typography';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';

/* eslint-disable-next-line */
export interface EditRolesPopoverProps {
  availableRoleOptions: CaseContactConnectionRoleType['roleOnCase'][];
  roleChangeHandler?: (contact: ContactType, role: string) => void;
  contact: (ContactType & CaseContactConnectionType) | undefined;
  caseContactConnectionId: string | undefined;
  disabledItems?: CaseContactConnectionRoleType['roleOnCase'][];
  caseId?: string;
}

export function EditRolesPopover(props: EditRolesPopoverProps) {
  const handleRoleChange = (
    party: ContactType & CaseContactConnectionType,
    item: CaseContactConnectionRoleType['roleOnCase'],
    caseId?: string,
  ) => {
    // if already has role, remove it
    if (party?.roles?.some((role) => role.roleOnCase === item)) {
      const rolesToDelete = party?.roles.filter((role) => role.roleOnCase === item);

      if (party?.caseContactConnectionId && rolesToDelete.length > 0) {
        rolesToDelete.forEach((role) => {
          removeRole.mutate(role.caseContactConnectionRoleId);
        });
      }
    } else {
      createRole?.mutate({
        caseContactConnectionId: props.caseContactConnectionId ?? '',
        roleOnCase: item,
      });
    }
  };
  const {
    availableRoleOptions,
    roleChangeHandler = handleRoleChange,
    contact,
    caseId,
    disabledItems: disabledRoles,
  } = props;
  const removeRole = useDeleteCaseContactConnectionRole();
  const createRole = useCreateCaseContactConnectionRole();
  const createCaseContactConnection = useCreateCaseContactConnection();
  const updateCaseContactConnectionRole = useUpdateCaseContactConnectionRole();

  const rolesForCase =
    props.contact?.roles?.filter(
      (role) => role?.caseContactConnectionRoleId?.split('-')[0] === caseId,
    ) ?? [];

  const animationVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.3, ease: 'easeInOut' },
    },
  };

  const buttonText = contact?.roles?.length === 0 ? 'Add Roles' : 'Edit Roles';

  return (
    <Popover modal>
      <PopoverTrigger>
        <div className="flex items-center px-2 py-1 text-xs border rounded-md gap-x-1 hover:bg-light-blue border-atlantic-blue text-atlantic-blue">
          <PencilIcon className="w-3 h-3" />
          {buttonText}
        </div>
      </PopoverTrigger>
      <PopoverContent className="z-50 bg-white border border-gray-300 rounded-md shadow-md over ">
        <motion.div initial={'hidden'} animate={'show'} variants={animationVariants}>
          <div className="top-0 p-1 space-y-2 overflow-y-auto max-h-72">
            {contact &&
              availableRoleOptions?.map((item) => (
                <div key={item} className="flex items-center px-3">
                  <input
                    type="checkbox"
                    className={cn(
                      'w-4 h-4 mr-2 text-blue-500 border-gray-300  rounded focus:ring-blue-500 form-checkbox',
                      {
                        'cursor-not-allowed text-gray-300 bg-gray-300':
                          disabledRoles?.includes(item),
                      },
                    )}
                    checked={rolesForCase?.some(
                      (role: CaseContactConnectionRoleType) => role.roleOnCase === item,
                    )}
                    disabled={disabledRoles?.includes(item)}
                    onChange={() => roleChangeHandler(contact, item, caseId)}
                  />
                  <div className="flex items-baseline">
                    <Typography className="overflow-hidden capitalize text-ellipsis">
                      {camelCaseToWords(item)}
                    </Typography>
                  </div>
                </div>
              ))}
          </div>
        </motion.div>
      </PopoverContent>
    </Popover>
  );
}

export default EditRolesPopover;
